// overrides mdl
address {
    line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
    line-height: $headings-line-height;
}

.mat-dialog-content{
  max-height: 100% !important;
}
