@font-face {
  font-family: 'Helvetica-Neue';
  font-weight: 200;
  src: url(HelveticaNeue.eot); /* For IE6-8 */
  src:  local('Helvetica Neue'),
        url(HelveticaNeue.eot?#iefix) format("embedded-opentype"),
        url(HelveticaNeue.woff) format("woff"),
        url(HelveticaNeue.ttf) format("truetype"),
        url(Helvetica-Neue.svg#NeueHelveticaeText) format("svg");
}
@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url(HelveticaNeue-Bold.eot); /* For IE6-8 */
  src:  local('Helvetica Neue Bold'),
        url(HelveticaNeue-Bold.eot?#iefix) format("embedded-opentype"),
        url(HelveticaNeue-Bold.woff) format("woff"),
        url(HelveticaNeue-Bold.ttf) format("truetype");
}
@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url(HelveticaNeue-Medium.eot); /* For IE6-8 */
  src:  local('Helvetica Neue Medium'),
        url(HelveticaNeue-Medium.eot?#iefix) format("embedded-opentype"),
        url(HelveticaNeue-Medium.woff) format("woff");
        /* url(HelveticaNeue-Bold.ttf) format("truetype"); */
}
/* @font-face {
  font-family: 'H-Neue';
  src:  local('H-Neue'),
        url(HelveticaNeue-Roman.otf) format("opentype");
        /* url(HelveticaNeue-Bold.ttf) format("truetype");
} */
/* @font-face {
  font-family: 'H-Neue-Light';
  font-weight: normal;
  src:  local('H-Neue-Light'),
        url(HelveticaNeueLight.ttf) format("truetype");
        /* url(HelveticaNeue-Bold.ttf) format("truetype");
} */