.left-sidebar>.sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar>.sidebar,
.right-sidebar>.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  position: relative !important;
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  box-shadow: none;
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1000;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.left-sidebar>.sidebar .header,
.right-sidebar>.sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.left-sidebar>.sidebar .content,
.right-sidebar>.sidebar .content {
  background: #ffffff;
  flex: 1 1 auto;
}

/* listing display section */
.left-sidebar>.sidebar.locked-open.left-positioned+.center,
.right-sidebar>.sidebar.locked-open.left-positioned+.center {
  margin-left: 0;
}

.left-sidebar>.sidebar.locked-open+.center,
.right-sidebar>.sidebar.locked-open+.center {
  z-index: 1001;
}

.left-sidebar.inner-scroll>.center,
.left-sidebar.inner-scroll>.center>.content-card,
.right-sidebar.inner-scroll>.center,
.right-sidebar.inner-scroll>.center>.content-card {
  flex: 1 1 auto;
}

.left-sidebar>.center,
.right-sidebar>.center {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
}

.left-sidebar>.center>.header,
.right-sidebar>.center>.header {
  display: -webkit-box;
  display: flex;
  height: 136px;
  min-height: 136px;
  max-height: 136px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, .6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  // -webkit-flex-direction: row-reverse;
  // -ms-flex-direction: row-reverse;
  // flex-direction: row-reverse;
}
