$themeColorCode: #009688 !default;
$focused-label-color: $themeColorCode;

$selectionBackgroundColor: rgba(0, 150, 136, 1);
$selectionTextColor: #ffffff;

$cancel-btn-bg-color:rgba(158,158,158,.4) 0 0;
$cancel-btn-color: #3d4051;

$input_height: 38px;

