.mat-select-search-inside-mat-option {
  .mat-select-search-input {
    padding-left: 0;
  }
}
.fc-trips-event-row {
  &.drag-able-row {
    cursor: move;
    font-size: 0.9rem;
    font-weight: 400;

    &.drag-able-row {
      display: table;
    }
  }

  td {
    text-align: center;
    padding: 0 10px !important;
    padding-left: 0;
    vertical-align: middle !important;
  }

  .mat-column-image {
    width: 80px !important;
    max-width: 80px !important;
    padding: 20px !important;

    .trip-icon {
      width: 25px;
      height: 25px;
      display: inline-block;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .mat-column-name {
    min-width: 250px;
    width: 250px;

    .title {
      font-size: 14px;
      display: block;
      text-align: left;
      color: #009688;
      font-weight: 600;
      text-transform: uppercase;
    }

    .region {
      display: block;
      text-align: left;
      color: #787878;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .mat-column-period {
    width: 350px;
    text-align: left;
    min-width: 350px;
    max-width: 350px;

    .trip-date {
      font-size: 14px;
      color: #2c2c2c;
      font-weight: 500;
    }
  }

  .mat-column-assigned {
    width: 200px;
    min-width: 200px;
    max-width: 200px;

    .team-images {
      gap: 10px;

      span {
        width: 27px;
        height: 27px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 1;
        border-radius: 50%;

        &.team-image {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .unassigned-icon{
      width: 27px;
      height: 27px;
      display: flex;
      app-svg-icon{
        width: inherit;
        height: inherit;
        svg{
          width: inherit;
          height: inherit;
        }
      }
    }

    span.user-count {
      color: #2c2c2c;
      padding: 4px;
      font-weight: 500;
    }
  }

  .mat-column-status {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    text-align: left;

    .status-drop-btn {
      max-width: 120px;
      width: 80px;
      margin-right: 10px;

      .mat-form-field-wrapper {
        padding-bottom: 0px !important;
      }

      .mat-form-field-flex {
        background: transparent;
        padding: 0 !important;

        .mat-form-field-infix {
          width: 100% !important;
          height: 2.5rem;
          color: #fff;
          min-width: 5rem;
          line-height: 1.6rem;
          border: 0px solid #9c9c9c;
          border-radius: 0.5rem;
          background: #f6f6f6;
          margin-top: 0.3rem;
          font-size: 1rem;

          .mat-select {
            position: absolute;
            top: 0.4rem;
            left: 0.4rem;

            .mat-select-value {
              color: #007b70 !important;
              padding: 0 0.5rem !important;
            }
          }
        }
      }

      .mat-form-field-underline {
        display: none !important;
      }

      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: #007b70 !important;
        }
      }
    }

    .trip-progress {
      max-width: 220px;

      .progress {
        font-size: 0.9rem !important;
        width: 65px;
        padding: 4px 10px;
        height: 25px;
        position: relative;
        z-index: 1;
        justify-content: center;
        align-items: center;
        color: #000000;
        line-height: 1;
        background-color: rgba(0, 150, 135, 0.19);
        border-radius: 5px;

        .progress-bar {
          position: absolute;
          height: 100%;
          z-index: -1;
          left: 0;
          top: 0;
          color: inherit;
          border-radius: 5px;
        }

        .progress-value {
          mix-blend-mode: multiply;
        }
      }
    }
  }

  .mat-column-action {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    text-align: center;

    .edit-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      color: var(--blue);

      svg {
        fill: currentColor;
      }
    }
  }
}

.custom-multi-select {
	.mat-option-disabled {
		.mat-pseudo-checkbox {
			display: none;
		}
	}
	.search-option {
		padding: 0;
	}
	.search-filter {
		padding: 0 16px;
		border-bottom: 1px solid #dddddd;
		height: 3rem;
		input {
			border: 0;
			outline: 0;
			height: 100%;
		}
	}
}
