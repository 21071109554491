@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

* {
  font-family: Roboto;
}

:root {
  --black: #000;
}

html {
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  height: 100%;
}
html.cdk-global-scrollblock {
  top: 0 !important;
  position: relative;
  overflow: hidden;
}

.mat-checkbox {
  display: initial !important;
}

/**
 * Firefox specific rule
 */
body {
  background-color: #f5f5f5 !important;
  color: var(--gray-darker) !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
}
body .bodyOverlay,
body .ox-tooltip-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
  top: 0;
  left: 0;
  display: none;
}
body.sidebarIsOpen, body.productSidebarOpen, body.sidebar-overlay {
  overflow: hidden;
}
body.sidebar-overlay .bodyOverlay, body.productSidebarOpen .bodyOverlay {
  display: block;
}
body.ox-tooltip-open {
  overflow: hidden;
}
body.ox-tooltip-open .ox-tooltip-overlay {
  z-index: 9999;
  display: block;
}

.form-model h1,
.form-model h2,
.form-model h3 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400 !important;
}

::-moz-selection {
  /* Code for Firefox */
  background: rgb(0, 150, 136);
  color: var(--white);
}

::selection {
  background: rgb(0, 150, 136);
  color: var(--white);
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a {
  font-weight: 400;
}

#loader-container,
#load-overlay {
  background: rgba(237, 240, 241, 0.6) url("../assets/images/audio.svg") no-repeat center center !important;
}

.show-loader {
  display: block;
}

.hide-loader {
  display: none;
}

.hide {
  display: none;
}

.mat-menu-item:hover:not([disabled]) {
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: #f7f8fa;
  color: #000;
}

.brand-menu-dropdown {
  width: 500px;
  max-width: 100% !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.fixed-header .app-page-container .app-content-wrapper .app-content {
  padding-top: 0px !important;
}

.p-24 {
  padding: 24px !important;
}

.btn.btn-min-width {
  min-width: auto;
}

.form-control {
  padding: 0.75rem 1.5rem 0.75rem 0.8rem !important;
}

.form-control:focus {
  border-color: #009688 !important;
  box-shadow: none !important;
}

.form-error {
  color: var(--red);
  top: -15px;
  font-size: 12px;
  position: relative;
  float: right;
  height: 0px !important;
}

.form-error.error2 {
  top: 0;
}

.from-error-message {
  top: -61px !important;
}

.form-control:focus {
  border-color: #009688;
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.11);
}

.mat-select,
.mat-button,
.mat-toolbar {
  font-family: inherit !important;
}

.btn-cancel {
  background-color: rgba(158, 158, 158, 0.4) !important;
}

.mat-button-wrapper {
  font-family: roboto, bold;
}

a.btn-w-sm,
button.btn-w-sm {
  min-width: 88px !important;
}

.overlay.transparent {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.large-model-size.nsm-dialog {
  max-width: 900px !important;
  z-index: 1400 !important;
}

.medium-model-size.nsm-dialog {
  max-width: 55vw !important;
}

.small-model-size.nsm-dialog {
  max-width: 330px !important;
  z-index: 9999;
}

.mat-dialog-container {
  min-width: 350px !important;
}

.mat-snack-bar-container {
  background-color: transparent !important;
  padding: 0px;
}

.success {
  padding: 14px 24px;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.error {
  padding: 14px 24px;
  background-color: var(--red) !important;
  border-color: var(--red) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.warning {
  padding: 14px 24px;
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification {
  padding: 14px 24px;
  background-color: #548cf1 !important;
  border-color: #548cf1 !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/**/
.error-input-class {
  background-color: var(--red);
}

.table thead th {
  vertical-align: middle;
}

.table {
  margin-bottom: 0;
}

tr.mat-row {
  height: 65px !important;
}

.ng-value-container {
  height: 44px;
}

/*material form css*/
.mat-form-field,
.mat-raised-button {
  line-height: inherit;
}

.mat-raised-button {
  letter-spacing: 0.14px !important;
}

button[mat-raised-button]:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {
  .mat-drawer-opened.mat-drawer-container {
    height: var(--windowInnerHeight, 100vh) !important;
  }
  .mat-dialog-container {
    min-width: 100% !important;
  }
  .cropper-dialog,
.search-dialog,
.retailer-dialog,
.add-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: auto !important;
  }
  .addOrg-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: 100% !important;
    min-height: auto !important;
  }
}
.mat-drawer-container .mat-drawer {
  background: #262b33;
}

.mat-drawer-container .mat-nav-list .mat-list-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

.mat-drawer-container .mat-nav-list .mat-list-item .mat-icon {
  margin-right: 10px;
}

.mat-nav-list .mat-list-item:hover {
  color: var(--white);
  background-color: rgba(158, 158, 158, 0.2);
}

/*Global css responsive*/
label {
  text-align: left !important;
}

/* Cursor setting */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.search-dialog,
.add-dialog,
.retailer-dialog,
.addOrg-dialog {
  width: 600px;
  height: auto;
  margin: 30px auto !important;
}

.cropper-dialog {
  min-width: 600px;
  width: auto;
  height: auto;
  margin: 30px auto !important;
}

.cate-actions {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.cate-actions li {
  width: 18px;
  margin-right: 10px;
  color: #009688;
  height: 18px;
}
.cate-actions li svg {
  fill: currentColor;
}

.brand-details-modal.mat-bottom-sheet-container {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  max-height: 90vh;
  animation: none !important;
}
.brand-details-modal .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  animation: none !important;
}

.search-dialog .mat-dialog-container,
.add-dialog .mat-dialog-container,
.retailer-dialog .mat-dialog-container,
.cropper-dialog .mat-dialog-container,
.addOrg-dialog .mat-dialog-container,
.edit-organisation .mat-dialog-container,
.markDown-modal .mat-dialog-container,
.unsaved-dialog .mat-dialog-container,
.padding-0 .mat-dialog-container,
.image-cropper-modal .mat-dialog-container,
.brand-details-modal .mat-dialog-container {
  padding: 0 !important;
}

.padding-y-0 .mat-dialog-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.share-catalogue-modal .mat-dialog-container {
  background-color: transparent;
  overflow: visible;
}

.search-dialog .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 240px;
}

.search-dialog {
  height: auto;
  min-height: 204px;
}

.retailer-dialog {
  height: auto;
  min-height: 119px;
}

.add-dialog {
  height: auto;
  min-height: 347px;
}

.addOrg-dialog {
  height: auto;
}

.vertical-center-modal {
  margin: auto !important;
}

.section-header {
  font-weight: 400 !important;
  font-size: 34px;
  line-height: 40px;
  color: var(--blue);
}

.filter-action-btn .mat-icon-button {
  width: auto;
}

.filter-div {
  border: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.download-btn {
  min-width: 35px !important;
  line-height: 31px !important;
}

.download-btn.mat-raised-button {
  padding: 0 9px;
}

.download-btn i {
  font-size: 18px;
}

.table-responsive {
  max-height: 430px !important;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    overflow-y: initial !important;
  }
}

.table th {
  border: 0;
}

.mat-elevation-z8 {
  padding: 15px;
  background: var(--white);
}

.mat-dialog-actions {
  padding: 0 10px !important;
  margin-bottom: 0 !important;
}

.card-action {
  padding: 15px !important;
}

#distributor {
  font-weight: 400;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.icon-box.ibox-lg .ibox-icon {
  height: 60px !important;
  width: 60px !important;
}

.mat-dialog-container {
  color: var(--gray-darker);
}

.outerTemplate {
  padding: 0 35px;
}

#retailer .mat-form-field-label {
  color: rgba(0, 0, 0, 0.26);
  font-weight: 400 !important;
}

#retailer .mat-form-field-hide-placeholder {
  color: red;
  font-weight: 400 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: rgb(0, 150, 136) !important;
}

#retailer .mat-sort-header-container {
  padding: 5px 0;
}

.mat-column-logo .mat-sort-header-container {
  justify-content: center;
}

.avatar-img,
.retailer-avatar-img {
  height: 55px;
  width: 55px;
  font-weight: 600;
}

.avatar-img.notification-drop {
  height: 40px;
  width: 40px;
  font-weight: 600;
  margin: auto;
  border: solid 1px #4ca79c;
}

.avatar-img,
.retailer-avatar-img {
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  border: solid 1px #e4e4e4;
}

.avatar-img.color,
.retailer-avatar-img.color {
  background-color: var(--blue);
  margin: auto;
}

#retailer .mat-cell {
  font-weight: 500;
}

.retailer-add-dialog {
  margin: auto !important;
}

.mat-dialog-content {
  padding: 15px !important;
  margin: 0 !important;
}

.retailer-add-dialog .mat-dialog-content {
  padding: 25px 40px !important;
}

.mat-menu-panel.edit-menu {
  margin-top: 35px;
  min-width: 63px;
  margin-left: -31px;
}

.edit-menu .mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.edit-menu .mat-menu-item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.mat-toolbar-row {
  justify-content: center;
}

.mat-drawer-container {
  background-color: inherit !important;
}

.nsm-content {
  padding: 0 !important;
}

#frmAddOrganization .form-group {
  padding: 0 15px;
}

.add-org-btn {
  text-align: center;
}

.e-tooltip-wrap {
  padding: 5px;
  background-color: #262b33 !important;
  font-size: 16px;
}

/* Image cropper css */
.image-cropper-modal {
  min-width: 790px !important;
}

.edit-organisation {
  margin: 30px auto !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
}

.cdk-global-overlay-wrapper::before {
  content: "";
  height: auto;
  max-height: 100%;
  white-space: nowrap;
}

.cdk-overlay-container .cdk-global-overlay-wrapper,
.cdk-overlay-container .cdk-overlay-container {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ----- */
.mat-menu-panel .mat-menu-content {
  padding: 0px !important;
}

.mat-list[dense] .mat-list-item .mat-line {
  display: inline;
  margin-left: 14px;
}

.closeTop {
  min-width: 20px !important;
  width: 20px;
  height: 20px;
}

.header-profile-dropdown {
  margin-top: -1px;
}

.page-notification .mat-list-item-content {
  padding: 0 !important;
}

.reject-dialog .mat-button-wrapper {
  color: #ffffff !important;
}
.reject-dialog .reject-title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #e40306;
  text-align: center;
}
.reject-dialog .rejected-text {
  text-align: center;
  padding-bottom: 1rem;
}
.reject-dialog .rject-btn.failure {
  background-color: #e40306 !important;
}
.reject-dialog .rject-btn.failure .mat-button-wrapper {
  color: #ffffff !important;
}

.browse-btn:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.otpText span {
  font-weight: 500 !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-size: 14px;
  color: var(--gray-darker);
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mat-tree-node {
  color: var(--gray-darker);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  min-height: 30px !important;
}

.mat-tree-node .mat-checkbox-layout {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-darker);
  text-transform: capitalize;
}

.tree-outer-div,
.tree-inner-div {
  padding: 0;
}

.filter-field {
  border-top: 1px solid #eee;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background: transparent;
  border-bottom: 1px #eeeeee solid;
}

.mat-expansion-panel-body {
  border-bottom: 1px solid #eee;
}

.mat-checkbox-layout {
  width: 100%;
  display: flex !important;
  align-items: center !important;
}
.mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 0;
  margin-right: 8px;
}

.search-options .mat-menu-item {
  height: 30px;
  line-height: 30px;
}

.product-header .mat-fab,
.org-drop .mat-fab {
  height: 26px !important;
  width: 26px !important;
}

.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}

.brandList .mat-select-value {
  color: #fff;
  overflow: visible;
}
.brandList .mat-select-trigger {
  height: auto;
}
.brandList mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

textarea {
  resize: none !important;
}

.page-layout table {
  width: 100%;
}
.page-layout tr.isDeleting {
  pointer-events: none;
  background-color: rgba(223, 33, 33, 0.3) !important;
}
.page-layout tr.isDeleting * {
  pointer-events: none;
}
.page-layout tr.isDeleting:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(223, 33, 33, 0.3);
  z-index: 2;
  font-size: 18px;
  color: #df2121;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
}
.page-layout .mat-table {
  overflow: auto;
  max-height: 500px;
  border-radius: 12px;
  border: 0px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 0;
  border-collapse: separate;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background: transparent;
}
.page-layout .table thead th,
.page-layout table thead th .mat-sort-header-button {
  background: #fff;
  font-family: roboto, medium;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  color: #505050;
}
.page-layout .table:not(.tab-group-table) thead th:first-child {
  border-top-left-radius: 12px;
}
.page-layout .table:not(.tab-group-table) thead th:last-child {
  border-top-right-radius: 12px;
}
.page-layout .mat-table thead {
  background: transparent;
}
.page-layout .mat-elevation-z8 {
  padding: 0px;
}
.page-layout td.mat-cell {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #1e1e1e;
  text-decoration: none;
  vertical-align: middle;
  border: 0;
}
.page-layout tr.mat-header-row {
  height: 64px !important;
  min-height: 64px;
  max-height: 64px;
}
.page-layout th.mat-header-cell {
  padding: 10px;
}
.page-layout .mat-cell {
  font-weight: 500;
}
.page-layout .mat-table > thead > tr {
  border-bottom: none;
}
.page-layout .mat-column-image .mat-sort-header-container {
  justify-content: center;
}
.page-layout .mat-column-noData {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
.page-layout .table thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout .mat-form-field-infix {
  border-bottom: 0.1em solid #bfbbb8;
}
.page-layout .mat-select-arrow {
  color: #bfbbb8;
}
.page-layout .mat-paginator {
  border-radius: 12px;
  border: none;
}
.page-layout tr.mat-row {
  cursor: pointer;
}

.view-status {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  background-color: #e5f4f1;
  color: var(--blue) !important;
  border-radius: 3px;
  width: 75px;
  justify-content: center;
}
.view-status.view-status-inactive {
  filter: grayscale(1);
}
.view-status .check-icon {
  min-width: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;
  line-height: 10px;
  margin-right: 5px;
  font-size: 12px !important;
  color: inherit !important;
}
.view-status .check-icon svg {
  fill: currentColor;
}

.product-listing .color-pallete-table,
.product-catlogue-listing .color-pallete-table {
  width: auto;
}
.product-listing .color-pallete-table td,
.product-catlogue-listing .color-pallete-table td {
  padding: 4px 1px 0px 0px;
  border-top: 0;
}
.product-listing .color-palette,
.product-catlogue-listing .color-palette {
  float: left;
  width: 20px !important;
  height: 20px !important;
  margin: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 0 !important;
  margin-left: 0px;
  position: relative;
}
.product-listing .color-palette span,
.product-catlogue-listing .color-palette span {
  font-size: 10px;
  top: -0.7rem;
  position: relative;
}
.product-listing .plt-image-container,
.product-catlogue-listing .plt-image-container {
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}
.product-listing th.mat-column-image,
.product-listing th.mat-column-status,
.product-catlogue-listing th.mat-column-image,
.product-catlogue-listing th.mat-column-status {
  border-radius: 0;
}
.product-listing .mat-column-detail,
.product-catlogue-listing .mat-column-detail {
  padding: 10px 20px !important;
  text-align: left !important;
  width: auto;
  min-width: 180px;
}
.product-listing .mat-column-type,
.product-catlogue-listing .mat-column-type {
  width: 140px;
  padding: 0 20px !important;
}
.product-listing .mat-column-check,
.product-catlogue-listing .mat-column-check {
  width: 60px;
  padding: 0 20px !important;
}
.product-listing .mat-column-image,
.product-catlogue-listing .mat-column-image {
  width: 80px;
  padding: 10px 0 !important;
}
.product-listing .mat-column-image:first-of-type,
.product-catlogue-listing .mat-column-image:first-of-type {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.product-listing .mat-column-category,
.product-catlogue-listing .mat-column-category {
  padding: 20px !important;
  width: 140px;
}
.product-listing td.mat-column-category,
.product-catlogue-listing td.mat-column-category {
  font-size: 16px !important;
}
.product-listing .mat-column-feature,
.product-catlogue-listing .mat-column-feature {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-label,
.product-catlogue-listing .mat-column-label {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-action,
.product-catlogue-listing .mat-column-action {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-status,
.product-catlogue-listing .mat-column-status {
  width: 140px;
  padding: 20px !important;
}

.fixed-header .app-header {
  z-index: 1003;
}
.fixed-header .app-header .mat-sidenav-container {
  position: absolute;
  min-height: auto !important;
  height: auto !important;
}
.fixed-header .app-header .mat-sidenav-container.mat-drawer-opened, .fixed-header .app-header .mat-sidenav-container.mat-drawer-container-has-open {
  height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px)) !important;
}

body .cdk-overlay-container {
  z-index: 1003;
}

.product-detail-dialog .mat-dialog-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}

.p-detail-dialog .mat-dialog-container {
  padding: 0px;
}

.p-detail-dialog .mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 0 10px !important;
  margin: 0 !important;
}

.p-detail-dialog .mat-dialog-content {
  padding: 15px;
  margin: 0;
}

.p-detail-dialog .table th {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.p-detail-dialog tr.mat-row {
  height: 48px !important;
}
.p-detail-dialog tr.mat-header-row {
  height: 45px;
  background: #504b51 !important;
}
.p-detail-dialog .mat-header-cell {
  color: #fff !important;
}
.p-detail-dialog .mat-cell,
.p-detail-dialog .mat-header-cell {
  padding: 10px !important;
  text-align: center;
  vertical-align: middle;
}
.p-detail-dialog .mat-column-size {
  min-width: 100px;
}
.p-detail-dialog .mat-column-color {
  min-width: 167px;
}
.p-detail-dialog .mat-column-stock {
  min-width: 50px;
}
.p-detail-dialog .mat-column-status {
  min-width: 150px;
}
.p-detail-dialog .mat-column-mrp {
  min-width: 50px;
}

.mat-paginator-container {
  border-left: none;
  border-right: none;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mat-expansion-panel-body {
  position: relative !important;
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .search-section .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  .search-section .mat-form-field-infix {
    border-top: 0 !important;
  }
  .search-section .mat-form-field-underline {
    bottom: 0 !important;
  }
}
/* new page design */
.top-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
}

.accent {
  background-color: #504b51 !important;
  color: #fff !important;
}

.left-sidebar > .sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  top: var(--headerHeight, 0px);
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar > .sidebar,
.right-sidebar > .sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1003;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  border-right: solid 5px #35a093;
}

.left-sidebar > .sidebar .header,
.right-sidebar > .sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.p-24 {
  padding: 24px !important;
}

.header .brandList {
  width: 100%;
}

.left-sidebar > .sidebar .content,
.right-sidebar > .sidebar .content {
  background: #f5f5f5;
  flex: 1 1 auto;
  position: fixed !important;
  top: 0 !important;
  width: calc(100% - 5px) !important;
}

.ps {
  position: relative;
}

/* nav header */
.navs .nav-subheader {
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 600;
  padding-left: 24px;
  margin-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}

/* listing display section */
.left-sidebar > .sidebar.locked-open.left-positioned + .center,
.right-sidebar > .sidebar.locked-open.left-positioned + .center {
  margin-left: 0;
  padding-left: 15px !important;
  margin-left: 267px !important;
}

.left-sidebar > .sidebar.locked-open + .center,
.right-sidebar > .sidebar.locked-open + .center {
  z-index: 1001;
}

.left-sidebar.inner-scroll > .center {
  flex: 1 1 auto;
}

.left-sidebar > .center > .header,
.right-sidebar > .center > .header {
  display: -webkit-box;
  display: flex;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  margin: 0px;
  margin-bottom: 10px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.page-layout {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}

.content-card {
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 10px;
  box-shadow: none !important;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.resolution-hint {
  font-size: 14px;
  padding: 15px;
  background: #f3f3f3;
  color: #949393;
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;
}
.resolution-hint i.fa-info-circle {
  color: currentColor;
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

.hiddenMatField {
  visibility: hidden;
  padding-bottom: 1.6em;
}

.clear-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.clear-section-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  padding-right: 10px;
}

.material-icons.icon-setting {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000;
  line-height: 22px;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.filter-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.filter-title svg {
  width: 14px;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.mat-checkbox-background {
  background-color: #f4f5f9;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  margin: 0 !important;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.orderStatus-menu .mat-menu-item {
  height: 30px;
  line-height: 30px;
}
.orderStatus-menu .mat-menu-item .status-icon {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ddd;
  vertical-align: middle;
  margin-right: 5px;
  opacity: 0;
}
.orderStatus-menu .mat-menu-item.status-selected .status-icon {
  opacity: 1;
}
.orderStatus-menu .mat-menu-item.status-pending .status-icon {
  background-color: #e67a57;
}
.orderStatus-menu .mat-menu-item.status-completed .status-icon {
  background-color: #15224c;
}
.orderStatus-menu .mat-menu-item.status-partially-delivered .status-icon {
  background-color: #a5a5a5;
}
.orderStatus-menu .mat-menu-item.status-new .status-icon {
  background-color: #00bcd4;
}
.orderStatus-menu .mat-menu-item.status-delivered .status-icon {
  background-color: #4caf50;
}
.orderStatus-menu .mat-menu-item.status-processing .status-icon {
  background-color: #2b98e0;
}
.orderStatus-menu .mat-menu-item.status-removed .status-icon {
  background-color: #f1261e;
}
.orderStatus-menu .mat-menu-item.status-cancelled .status-icon {
  background-color: #db3e38;
}
.orderStatus-menu .mat-menu-item.status-draft .status-icon {
  background-color: #ada14b;
}

.required-warn {
  color: var(--red);
}

#organisationName {
  text-transform: capitalize;
}

.errorMsg {
  color: var(--red);
}

.errorBorder {
  border-bottom: 1px solid var(--red);
  padding: 10px;
}

.search-section-heading {
  font-size: 22px;
  font-weight: 500;
  margin: 1px 0px 2px 4px;
}

table .noDataAvailable {
  min-height: 300px;
}

.mat-menu-item {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
}

.owl-carousel .nav-btn {
  height: 60px;
  position: absolute;
  width: 20px;
  cursor: pointer;
  top: 0 !important;
  bottom: 0;
  margin: auto 0;
  padding-top: 20px;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background-color: #d8d8d8;
  left: -160px;
  border-radius: 2px;
}
.owl-carousel .prev-slide i {
  color: black !important;
  font-weight: bold;
}

.owl-carousel .next-slide {
  background-color: #d8d8d8;
  right: -28px;
  border-radius: 2px;
}
.owl-carousel .next-slide i {
  color: black !important;
  font-weight: bold;
}

.owl-theme.row {
  margin-left: 0px !important;
  margin-right: opx !important;
}

@media (max-width: 770px) {
  .owl-carousel .prev-slide {
    left: -145px;
  }
}
.demo {
  background-color: red !important;
  color: gray !important;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}

.brandList.order-brand-disabled .mat-select-disabled .mat-select-value {
  color: #fff !important;
}

.filenameStyle {
  word-break: break-word;
}

.feature-icon {
  color: #26756a !important;
}

.mat-tree-node.mat-tree-title {
  min-height: 30px !important;
  cursor: pointer;
  text-transform: capitalize;
}

.mat-tree-node.tree-child-item {
  padding-left: 45px;
}

.mat-icon-rtl-mirror.category.mat-icon.material-icons {
  line-height: 19px !important;
}

.expension-panel-product.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.expension-panel-product.mat-expanded:hover,
.expension-panel-product.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel-header.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  padding-top: 15px;
}

.listing-tags-blocks .mat-expansion-panel-body {
  padding: 0 !important;
}
.listing-tags-blocks .mat-expansion-panel-content.mat-expanded {
  padding: 0 !important;
  padding-top: 0 !important;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.scrollbar-new::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-new::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

.scrollbar-new::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.p-price .ngx-slider .ngx-slider-pointer {
  width: 15px;
  height: 15px;
  top: -5px;
}
.p-price .ngx-slider .ngx-slider-pointer:after {
  width: 9px;
  height: 9px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.p-price .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  transition: opacity 0.3s linear;
  bottom: -30px;
  opacity: 1 !important;
  visibility: visible !important;
  font-size: 14px;
}
.p-price .ngx-slider .ngx-slider-bubble[class*=ngx-slider-model], .p-price .ngx-slider .ngx-slider-bubble.ngx-slider-combined {
  font-size: 12px;
}
.p-price .ngx-slider .ngx-slider-pointer {
  background-color: #00a093;
  cursor: grab;
}
.p-price .ngx-slider .ngx-slider-pointer.ngx-slider-active {
  cursor: grabbing;
}
.p-price .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: #00a093;
}
.p-price .ngx-slider .ngx-slider-selection {
  background-color: #00a093;
}

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.mat-menu-panel {
  min-height: initial !important;
}

.scrollable::-webkit-scrollbar, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar, .lightbox .lb-outerContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollable::-webkit-scrollbar-thumb, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar-thumb, .lightbox .lb-outerContainer::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}
.scrollable::-webkit-scrollbar-track, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar-track, .lightbox .lb-outerContainer::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.btn-w-svg .mat-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  gap: 10px;
  height: 100%;
}
.btn-w-svg app-svg-icon {
  line-height: 15px;
  display: inline-flex;
  align-items: center;
}
.btn-w-svg svg {
  width: 15px !important;
  height: 15px !important;
  fill: currentColor;
}

.mat-error {
  width: 100%;
  font-size: 12px;
  color: #ef5350;
}

.more-menu-buttons.detailed-preview-menu {
  width: 180px;
}
.more-menu-buttons .action-buttons {
  flex-direction: column;
}
.more-menu-buttons .action-buttons button {
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: left;
  box-shadow: none;
  margin-right: 0 !important;
}
.more-menu-buttons .action-buttons button svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.more-menu-buttons.create-options .create-icons {
  color: var(--blue);
}

.quick-search-filter .mat-expansion-panel-body {
  padding: 0 !important;
}
.quick-search-filter .mat-expansion-panel-content.mat-expanded {
  padding-top: 0 !important;
}
.quick-search-filter .quick-search-btn.btn {
  border: #f9f9f9 2px solid;
  background-color: white;
  color: black;
  margin-bottom: 0px !important;
  border-radius: 5px;
  padding: 10px;
}
.quick-search-filter .quick-search-btn.btn.disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
.quick-search-filter .quick-search-btn.btn input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.quick-search-filter .quick-search-btn.btn .searchForm .cancle-btn-new {
  width: 15px;
  height: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.quick-search-filter .quick-search-btn.btn .searchForm .cancle-btn-new svg {
  width: 100%;
  height: 100%;
}
.quick-search-filter .quick-search-btn.btn svg {
  width: 20px;
  height: 20px;
  fill: #ccc;
}
.quick-search-filter .quick-search-btn.btn.active svg {
  fill: #f5b855;
}
.quick-search-filter .quick-search-btn.btn .quick-search-content {
  display: flex;
  align-items: center;
}
.quick-search-filter .quick-search-btn.btn .quick-search-content span {
  vertical-align: middle;
  margin-left: 10px;
}

.no-records-found {
  padding: 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 0;
  font-family: inherit;
}
.no-records-found.is-sticky {
  position: sticky;
}
.no-records-found.is-sticky.sticky-left {
  left: 0;
}
.no-records-found.is-sticky.sticky-right {
  right: 0;
}

.searchForm .search-btn-style {
  color: #ffffff !important;
  background-color: #00a093 !important;
  border-color: #00a093 !important;
  margin-right: -2px;
  height: 38px;
  border: 1px solid transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
}
.searchForm .search-input {
  height: 38px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cbd5dd;
  border-radius: 0;
  z-index: 11;
}
.searchForm .cancle-btn {
  position: absolute;
  right: 60px;
  top: 7px;
  z-index: 13;
  cursor: pointer;
}
.searchForm .cancle-btn-new {
  position: absolute;
  right: 60px;
  top: 7px;
  z-index: 13;
  cursor: pointer;
  width: 15px;
  height: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.searchForm .cancle-btn-new svg {
  width: 100%;
  height: 100%;
}

.mat-dialog-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}

.mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 12px 10px;
}

.right-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
}
.right-container .header .box {
  margin-bottom: 0;
}
.right-container .header .box-body {
  padding: 1rem;
}

.tab-with-table {
  flex: 1 1 auto;
}
.tab-with-table .mat-tab-body-wrapper {
  flex: 1 1 auto;
}

.otp-new-input {
  border-bottom: 3px solid #319688 !important;
  text-align: center !important;
  font-size: 24px !important;
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 0px !important;
}

.otp-new-input::placeholder {
  color: #dadada;
  opacity: 1;
}

.button-with-loader .mat-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.generate-invoice-modal .mat-dialog-container {
  padding: 0;
}

b,
strong {
  font-weight: 500 !important;
}

.country-code-control {
  width: 90px;
}

.header-sub-menu .mat-menu-content a .menu-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 10px;
}
.header-sub-menu .mat-menu-content a .menu-icon svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
}
.header-sub-menu .mat-menu-content a.active-route {
  background-color: #f2f2f2;
}

#product-details-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: var(--headerHeight, 0px);
  z-index: 1003;
  width: 450px;
  max-width: 100%;
  height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;
}
#product-details-sidebar::-webkit-scrollbar {
  display: none;
}
@media (max-width: 767px) {
  #product-details-sidebar {
    height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  }
}

#activity-feed-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: 0;
  z-index: 1003;
  width: 500px;
  max-width: 100%;
  height: 100%;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;
}
#activity-feed-sidebar::-webkit-scrollbar {
  display: none;
}

.add-trips-sidebar {
  width: 365px !important;
}

.product-size-sheet-container {
  border-radius: 15px 15px 0 0;
  padding: 0 !important;
  width: 450px;
  max-width: 100%;
  min-width: initial !important;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
  position: relative;
  top: 4px;
  left: 3px;
  background: #fff !important;
}

.mat-slide-toggle-bar {
  position: relative;
  width: 40px !important;
  height: 20px !important;
  flex-shrink: 0;
  border-radius: 10px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #009688 !important;
}

.right-container .mat-tab-label,
.detail-modal .mat-tab-label {
  height: 64px !important;
}

.page-container .mat-ink-bar {
  background: #319688 !important;
}

.mat-ink-bar {
  height: 5px !important;
  border-radius: 5px;
}

.product-tab-group .mat-tab-label {
  text-transform: uppercase;
  font-family: roboto, bold;
}
.product-tab-group .mat-tab-label-active {
  background: rgba(0, 150, 136, 0.1490196078);
}

.header-menu-list .mat-button-wrapper {
  font-size: 14px;
}

.page-layout .main-sidebar.products .brand-select-box .mat-form-field-infix,
.page-layout .main-sidebar.orders .brand-select-box .mat-form-field-infix {
  padding: 10px 20px !important;
  border: none !important;
}
.page-layout .main-sidebar.products .brand-select-box .mat-form-field-wrapper,
.page-layout .main-sidebar.orders .brand-select-box .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.page-layout .main-sidebar.products .brand-select-box .brand-logo,
.page-layout .main-sidebar.orders .brand-select-box .brand-logo {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 8px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d5d5d5;
  text-transform: uppercase;
  border-radius: 100%;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
}
.page-layout .main-sidebar.products .brand-select-box .mat-option,
.page-layout .main-sidebar.orders .brand-select-box .mat-option {
  height: 4rem !important;
}
.page-layout .main-sidebar.products .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.page-layout .main-sidebar.products .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple,
.page-layout .main-sidebar.orders .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.page-layout .main-sidebar.orders .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}

.enable-toggle.mat-slide-toggle {
  position: relative;
}
.enable-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-content::after {
  content: "ON";
  font-size: 12px;
  color: black;
  position: absolute;
}
.enable-toggle.mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-content::after {
  content: "OFF";
  font-size: 12px;
  color: white;
  position: absolute;
}
.enable-toggle.mat-slide-toggle .mat-slide-toggle-bar {
  width: 120px;
}

.navs .nav-item {
  height: calc(var(--windowInnerHeight, 100vh) - (var(--headerHeight, 0px) + 114px));
  overflow-y: auto;
}

.list-product-image {
  border-radius: 5px;
}

app-no-records-available {
  display: flex;
  flex-direction: column;
  flex: 1;
}

ngx-skeleton-loader {
  line-height: 0;
  display: block;
}

.internal-load {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.mat-expansion-panel-header[aria-disabled=true] .filter-action-btn {
  color: rgba(0, 0, 0, 0.26);
}

.content-card.card {
  border: solid 1px #e4e4e4;
  border-radius: 12px;
  flex: 1;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 96px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 14px;
  text-transform: capitalize;
  background: #dedede;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #8a8888;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background: #319688;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(187, 187, 187, 0.9411764706);
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: left 0.5s ease-out;
  -moz-transition: left 0.5s ease-out;
  -o-transition: left 0.5s ease-out;
  transition: left 0.5s ease-out;
}

.switch-input:checked ~ .switch-handle {
  left: 67px !important;
  background: #fff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

.lightbox {
  width: 100%;
  height: 100%;
  display: flex !important;
  top: 0 !important;
  position: fixed !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lightbox .lb-container .lb-nav {
  position: static;
}
.lightbox .lb-container .lb-nav .lb-prev,
.lightbox .lb-container .lb-nav .lb-next {
  position: fixed;
  height: 30px;
  background-size: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-position: center;
  width: 30px;
  opacity: 0.5;
}
.lightbox .lb-container .lb-nav .lb-prev:hover,
.lightbox .lb-container .lb-nav .lb-next:hover {
  opacity: 1;
}
.lightbox .lb-container .lb-nav .lb-prev {
  left: 0;
}
.lightbox .lb-container .lb-nav .lb-next {
  right: 0;
}
.lightbox .lb-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.lightbox .lb-data .lb-caption {
  font-size: 16px;
}
.lightbox .lb-outerContainer {
  overflow: auto;
  max-width: 100%;
}
.lightbox .lb-dataContainer {
  margin-top: -40px;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  padding-top: 0;
  border-radius: 0;
}
.lightbox .lb-dataContainer .lb-details {
  float: none;
}
.lightbox .lb-dataContainer .lb-controlContainer {
  float: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-downloadContainer .lb-download {
  height: 20px;
  cursor: pointer;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-closeContainer {
  float: none;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-closeContainer .lb-close {
  width: 20px;
  height: 20px;
  background-size: 100%;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-zoomContainer .lb-zoomOut,
.lightbox .lb-dataContainer .lb-controlContainer .lb-zoomContainer .lb-zoomIn {
  width: 25px;
  height: 25px;
  background-size: 100%;
  cursor: pointer;
}

.input-group.search-bar-fixed-size {
  width: 350px !important;
}

.table-sticky thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 9999 !important;
  display: block !important;
}

/* nav header */
.navs .nav-subheader {
  display: flex !important;
  align-items: center !important;
  height: 54px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  padding: 15px 24px;
  background: #35a093;
  color: #fff;
  font-family: "Roboto", bold;
  margin-top: 0;
  text-align: left;
  border-bottom: none !important;
}
.navs .nav-subheader .clear-button-pro {
  color: #fff;
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
}

.product-size-backdrop + .cdk-global-overlay-wrapper {
  justify-content: flex-end !important;
}

.product-tab-group .mat-tab-label,
.create-org-tab-group .mat-tab-label,
.retailers-tab-group .mat-tab-label,
.order-tab-group .mat-tab-label,
.performance-tab-group .mat-tab-label,
.report-tab-group .mat-tab-label,
.challan-tab-group .mat-tab-label,
.product-create-group .mat-tab-label,
.orderDetailsTabGroup .mat-tab-label,
.product-details-tab .mat-tab-label {
  text-transform: uppercase !important;
  font-family: roboto, bold !important;
  border-right: 1px solid #e4e4e4;
}
.product-tab-group .mat-tab-label-active,
.create-org-tab-group .mat-tab-label-active,
.retailers-tab-group .mat-tab-label-active,
.order-tab-group .mat-tab-label-active,
.performance-tab-group .mat-tab-label-active,
.report-tab-group .mat-tab-label-active,
.challan-tab-group .mat-tab-label-active,
.product-create-group .mat-tab-label-active,
.orderDetailsTabGroup .mat-tab-label-active,
.product-details-tab .mat-tab-label-active {
  background: rgba(0, 150, 136, 0.1490196078) !important;
}
.product-tab-group .mat-tab-label-content svg,
.create-org-tab-group .mat-tab-label-content svg,
.retailers-tab-group .mat-tab-label-content svg,
.order-tab-group .mat-tab-label-content svg,
.performance-tab-group .mat-tab-label-content svg,
.report-tab-group .mat-tab-label-content svg,
.challan-tab-group .mat-tab-label-content svg,
.product-create-group .mat-tab-label-content svg,
.orderDetailsTabGroup .mat-tab-label-content svg,
.product-details-tab .mat-tab-label-content svg {
  width: 16px;
}

.selection-custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
}
.selection-custom-checkbox.checked {
  background-color: var(--blue);
}
.selection-custom-checkbox span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: calc(100% - 4px);
}
.selection-custom-checkbox span svg {
  fill: currentColor;
}
.selection-custom-checkbox span.intermediate {
  height: 2px;
  background-color: #fff;
}

.ox-tooltip {
  position: fixed;
  left: 0;
  z-index: 9999;
  padding: 12px;
}
.ox-tooltip .inner-tooltip {
  background-color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.0784313725);
}
.ox-tooltip:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 12px;
  background-color: white;
  border-top: 0;
  z-index: -1;
  left: 60px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.0784313725);
}
.ox-tooltip.show-bottom:before {
  top: 0px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.ox-tooltip.show-top:before {
  bottom: 0px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.tab-nav-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.tab-nav-header .mat-tab-nav-bar,
.tab-nav-header .mat-tab-header {
  border-bottom: 0;
}
.tab-nav-header .mat-tab-link {
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-right: 1px solid #e4e4e4;
}
.tab-nav-header .mat-tab-link svg {
  width: 14px;
}
.tab-nav-header .mat-tab-link:hover {
  text-decoration: none;
}
.tab-nav-header .mat-tab-link.mat-tab-label-active {
  background-color: rgba(0, 150, 136, 0.1490196078) !important;
}

.not-applicable-text {
  font-size: 12px !important;
  color: #bdbdbd !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding: 0 20px !important;
  padding-right: 0;
}

.brand-name {
  color: #3ba094;
  font-size: 16px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: "Roboto", medium;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.mat-cell span.ui-highlight {
  color: #fff;
  font-size: 14px;
  padding: 5px;
  width: 96px;
  display: block;
  margin: auto;
  border-radius: 20px;
}

.ui-highlight.colored.textcolor-red {
  background-color: #ec3737;
}

.ui-highlight.colored.textcolor-dark-pending {
  background-color: #f7b900;
}

.ui-highlight.colored.textcolor-light {
  background-color: #8bc34a;
}

.ui-highlight.colored.textcolor-dark {
  background-color: #009688;
}

thead tr:first-child {
  border-bottom: none !important;
}

.brand-select-box .brand-name {
  color: #3ba094;
  font-size: 16px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: "Roboto", medium;
}
.brand-select-box .brand-logo {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d5d5d5;
  text-transform: uppercase;
  border-radius: 100%;
}
.brand-select-box .mat-option {
  height: 4rem !important;
}
.brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}

.sort-by-label {
  color: #aaaaaa;
}

.alert-danger {
  background-color: #fbe9e6;
  border-color: #f7d0d1;
  color: #a1513c;
}

.btn-outline-secondary {
  color: #949393;
  background: #fff;
  border-color: #949393;
  padding: 0.5rem 1.75rem;
}

.btn-outline-secondary:hover {
  background-color: #949393;
  border-color: #949393;
  color: #fff;
}

#main-section {
  padding-top: var(--headerHeight, 0px);
}

.fixed-paginator {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: calc(100% - 30px);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 15px;
  background-color: #fff;
}
.fixed-paginator mat-paginator {
  border: 1px solid #e4e4e4 !important;
  border-radius: 0 0 12px 12px !important;
}

@media (max-width: 1199px) {
  .product-detail-modal {
    width: 100% !important;
    max-width: 100vw !important;
  }
}
/* Add Product Image Model */
@media only screen and (max-device-width: 480px) {
  .p-detail-dialog {
    width: 100% !important;
    max-width: 900px !important;
  }
  .p-detail-dialog .mat-dialog-title {
    padding: 10px;
    margin: 0 0 10px;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content {
    max-height: calc(var(--windowInnerHeight, 100vh) - 122px) !important;
    padding: 5px !important;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content .heading-section {
    float: left !important;
    flex: 1 0 100%;
    max-width: 50%;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content .uploaded-image-section .image {
    height: 150px !important;
    padding: 10px !important;
  }
  .p-detail-dialog .mat-dialog-actions .card-action {
    padding: 10px !important;
  }
}
/* Create Product */
@media only screen and (max-device-width: 480px) {
  .imageblock.add-image-plus {
    height: 170px !important;
  }
}
.mat-tooltip {
  color: #fff;
  border-radius: 4px !important;
  background: #3a3939;
  margin: 10px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border: solid 1px #5a5959;
}

.attribute-icon {
  width: 18px;
  height: 18px;
  margin: 4px;
  margin-right: 6px;
}
.attribute-icon svg {
  width: 100%;
  height: 100%;
}

.mat-raised-button.mat-accent[disabled],
.mat-raised-button[disabled][disabled] {
  cursor: not-allowed;
}

.select-retailer-modal {
  height: 90vh;
}
@media (max-width: 767px) {
  .select-retailer-modal {
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
  }
}

perfect-scrollbar > .ps {
  min-height: inherit;
}

.sort-action-btn {
  min-width: 45px !important;
}
.sort-action-btn span {
  width: 15px;
  display: inline-block;
}

.page-auth .phone-form-group .phone-form-field .mat-form-field-infix {
  width: auto;
}

.add-org-logo-modal .mat-dialog-content {
  padding: 0 !important;
}
.add-org-logo-modal .mat-dialog-content .add-org-logo-content {
  max-height: calc(var(--windowInnerHeight, 100vh) - 200px);
}
@media (max-width: 767px) {
  .add-org-logo-modal .mat-dialog-content .add-org-logo-content {
    max-height: calc(var(--windowInnerHeight, 100vh) - 135px);
  }
}
@media (max-width: 375px) {
  .add-org-logo-modal .croppie-container .cr-viewport {
    width: 240px !important;
    height: 240px !important;
  }
}

.ox-custom-radio.status-radio ul {
  gap: 5px;
  margin: 0;
}
.ox-custom-radio label {
  margin: 0;
}
.ox-custom-radio label input[type=radio] {
  display: none;
}
.ox-custom-radio label .radio-box {
  display: flex;
  padding: 3px 5px;
  border: 1px solid var(--blue);
  font-size: 11px;
  line-height: 1.2;
  color: var(--blue);
  cursor: pointer;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.ox-custom-radio label .radio-box .icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ox-custom-radio label .radio-box .icon svg {
  fill: currentColor;
}
.ox-custom-radio label input[type=radio]:checked + .radio-box {
  background-color: var(--blue);
  color: #fff;
}

.table-actions-list {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}
.table-actions-list li a {
  width: 20px;
  height: 20px;
  display: block;
  line-height: 20px;
  margin: 0;
}
.table-actions-list li a app-svg-icon {
  line-height: inherit;
}
.table-actions-list li a.delete-icon {
  color: #df2121;
}
.table-actions-list li a svg {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
}

.enterprise-detail-modal {
  padding: 5px;
  position: relative;
  overflow-x: hidden;
}
.enterprise-detail-modal p {
  margin: 0;
  line-height: 20px;
}
.enterprise-detail-modal h5 {
  margin: 0;
}
.enterprise-detail-modal .close-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 5px;
  background-color: #009688;
  border: 0;
  color: #fff;
  border-radius: 50%;
  margin: auto 0;
  z-index: 2;
  cursor: pointer;
}
.enterprise-detail-modal .close-button svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.enterprise-detail-modal .modal-heading {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 0px 20px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}
.enterprise-detail-modal .modal-heading .menu-icon {
  margin-right: 6px;
  width: 21px;
  height: 19px;
  color: var(--gray-darker);
}
.enterprise-detail-modal .modal-heading .menu-icon app-svg-icon {
  fill: var(--gray-darker);
  display: flex;
  height: 19px;
  width: 21px;
}
.enterprise-detail-modal .enterprise-details {
  padding: 17px 20px 15px 18px;
  margin: 0;
  max-height: calc(96vh - 64px);
  overflow-y: auto;
}
.enterprise-detail-modal .enterprise-details .plane {
  display: inline-block;
}
.enterprise-detail-modal .enterprise-details .plane.fly-plane {
  color: #319688;
  font-size: 18px;
  animation-name: fly;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.735, 0.43, 0.275, 0.76);
}
.enterprise-detail-modal .enterprise-details .enterprise-logo {
  width: 80px;
  height: 80px;
}
.enterprise-detail-modal .enterprise-details .enterprise-logo .ng-star-inserted {
  width: 100%;
  height: 100%;
  font-size: 24px;
}
.enterprise-detail-modal .enterprise-details .enterprise-logo img {
  min-width: 100%;
  min-height: 100%;
}
.enterprise-detail-modal .enterprise-details .enterprise-name {
  margin: 0 !important;
  font-weight: 600 !important;
  color: #009688;
  font-size: 16px;
}
.enterprise-detail-modal .enterprise-details .enterprise-active {
  background-color: rgba(0, 150, 136, 0.19);
  margin: 0 0 0px 7px;
  border-radius: 5px;
  padding: 3px 4px;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
.enterprise-detail-modal .enterprise-details .enterprise-active.inactive-status {
  filter: grayscale(1);
}
.enterprise-detail-modal .enterprise-details .distributor-marked {
  font-size: 10px;
  background-color: #46495B;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  line-height: 1;
  font-weight: 500;
  box-shadow: 0px 3px 6px rgba(68, 70, 88, 0.3019607843);
  margin-left: 7px;
}
.enterprise-detail-modal .enterprise-details .enterprise-contact {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .transproter-GST {
  margin: 0;
  color: #787878;
  font-size: 14px;
}
.enterprise-detail-modal .enterprise-details .transproter-GST span {
  color: #525252;
  font-weight: 500 !important;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title {
  font-size: 12px;
  line-height: 1.3;
  color: #787878;
  margin: 0 0 3px;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span {
  text-transform: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span svg {
  height: 10px;
  width: 10px;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span .help-info {
  height: 11px !important;
  width: 11px !important;
  margin-left: 3px;
  fill: #787878;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .transport-detail-section .trasport-detail-label {
  font-size: 12px;
  color: #787878;
  margin: 10px 0 5px;
  text-transform: uppercase;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .transport-detail-section p {
  line-height: 1.5;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status {
  width: 120px;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  color: white;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-accepted {
  background-color: #00bcd4;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-rejected {
  background-color: #ff0000;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-pending {
  background-color: #ffc107;
}
.enterprise-detail-modal .enterprise-details .address-tag {
  font-size: 12px;
  color: #787878;
  line-height: normal;
}
.enterprise-detail-modal .enterprise-details .enterprise-address {
  color: #2c2c2c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .more-information-section {
  font-size: 13px;
  color: #787878;
  text-transform: uppercase;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .more-information-section span {
  color: #525252;
  font-weight: 500;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag {
  color: #b8b8b8;
  margin-bottom: 5px;
  line-height: normal;
  font-size: 12px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span {
  text-transform: none;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span svg {
  height: 10px;
  width: 10px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span .help-info {
  height: 11px !important;
  width: 11px !important;
  margin-left: 3px;
  fill: #787878;
}
.enterprise-detail-modal .enterprise-details .owner-name {
  color: #525252;
  font-size: 15px;
  font-weight: 600 !important;
  margin-bottom: 0px;
}
.enterprise-detail-modal .enterprise-details .owner-email {
  line-height: normal;
  color: #787878;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit {
  background-color: #009688;
  color: #ffffff;
  border-radius: 3px;
  line-height: normal;
  margin-left: 5px;
  text-decoration: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit:hover {
  text-decoration: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit .mat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px !important;
  width: 21px !important;
  font-size: 16px;
}
.enterprise-detail-modal .enterprise-details .ui-highlight {
  color: #fff;
  font-size: 14px;
  padding: 5px;
  width: 96px;
  display: block;
  text-align: center;
  border-radius: 20px;
}
.enterprise-detail-modal .enterprise-details .invite-btn {
  background: #66bb6a;
  color: #fff;
}
.enterprise-detail-modal .enterprise-details .invite-btn.disabled {
  filter: grayscale(1);
}
.enterprise-detail-modal .enterprise-details .btn-retail-new {
  width: 120px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
}
.enterprise-detail-modal .enterprise-details .btn-retail-new .button-text {
  padding-right: 3px;
}
.enterprise-detail-modal .enterprise-details .btn-warning.btn-retail-new {
  background: #f7b902;
}
@media (max-width: 375px) {
  .enterprise-detail-modal .enterprise-details .enterprise-logo {
    min-width: 65px;
    min-height: 65px;
    height: 65px;
    width: 65px;
  }
}
@media (max-width: 425px) {
  .enterprise-detail-modal .enterprise-details .btn-retail-new {
    width: auto;
  }
  .enterprise-detail-modal .enterprise-details .btn-retail-new .button-text {
    display: none;
  }
  .enterprise-detail-modal .enterprise-details .ui-highlight {
    font-size: 11px;
    padding: 3px 5px;
    width: 80px;
  }
  .enterprise-detail-modal .enterprise-details .resend-invite-btn {
    font-size: 11px;
  }
}

@media (max-width: 479px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 350px !important;
    height: 214px !important;
  }
}
@media (max-width: 425px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 320px !important;
    height: 180px !important;
  }
}
@media (max-width: 375px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 265px !important;
    height: 149px !important;
  }
  .desktop-banner-dialog .page-auth .otp-form-container .otp-input {
    width: 20px !important;
    font-size: 22px !important;
  }
}

@media (max-width: 375px) {
  .unsaved-modal-box .mat-dialog-container {
    padding: 14px;
  }
}
.as-split-gutter {
  background: transparent !important;
}

@media only screen and (max-width: 768px) {
  .billing-history-plan-panel {
    max-width: 100vw !important;
    width: 100% !important;
  }
}

.mat-slide-toggle {
  margin-bottom: 0;
}
.mat-slide-toggle .mat-slide-toggle-bar {
  height: 21px !important;
}
.mat-slide-toggle .mat-slide-toggle-thumb {
  width: 15px !important;
  height: 15px !important;
  top: 6px;
  left: 3px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(19px, 0, 0) !important;
}

.createMI-menu-dropdown {
  min-width: 100px;
}
.createMI-menu-dropdown .mat-menu-content a {
  display: block;
  padding: 7px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.5;
}
.createMI-menu-dropdown .mat-menu-content a:hover {
  background-color: #f6f6f6;
}

.custom-multi-select .mat-option-multiple .mat-checkbox-layout {
  margin: 0;
}
.custom-multi-select .mat-option-multiple .mat-pseudo-checkbox-checked::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.attr-search-box {
  position: relative;
}
.attr-search-box .clear-search-box {
  position: absolute;
  width: 12px;
  height: 12px;
  display: block;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 7px;
  margin: auto;
  line-height: 1;
  cursor: pointer;
}
.attr-search-box input {
  width: 100%;
  padding: 6px 9px;
  border: 1px solid rgba(120, 120, 120, 0.52);
  border-radius: 2px;
  color: #707070;
  position: relative;
  z-index: 2;
  background-color: #ffffff;
}
.attr-search-box .attr-search-menu {
  position: absolute;
  z-index: 2;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 3px 3px;
  top: 100%;
  max-height: 205px;
  overflow-y: auto;
}
.attr-search-box .attr-search-menu span {
  display: block;
  padding: 10px 9px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.attr-search-box .attr-search-menu span:hover {
  background-color: #f2f2f2;
}

.custom-table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 7px;
}

.paginator-section .custom-table-scrollbar {
  position: absolute;
  top: -7px;
  width: 100%;
  left: 0;
  right: 0;
}

.more-inventory {
  width: 215px;
}
.more-inventory a {
  color: #2E2E2E;
  text-transform: uppercase;
  padding: 10px;
  font-size: 12px;
  display: block;
  text-decoration: none;
  height: auto;
  line-height: inherit;
}
.more-inventory a .material-icons {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
  height: auto !important;
  background-color: transparent;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #2E2E2E;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-content {
  padding: 0;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
  background-color: #F2F2F2;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-body a {
  padding: 4px 10px;
}

.create-dispatch-menu {
  min-width: 140px !important;
  border-radius: 4px 0 4px 4px !important;
}
.create-dispatch-menu a {
  display: block;
  padding: 5px 10px;
  color: #222222;
  text-transform: uppercase;
  text-decoration: none;
}
.create-dispatch-menu a:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.dispatch-filters-dropdown {
  border-radius: 0 0 4px 4px !important;
  max-height: 185px !important;
  overflow-y: auto;
  min-width: var(--selectDropdownWidth) !important;
  max-width: var(--selectDropdownWidth) !important;
}
.dispatch-filters-dropdown .mat-menu-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dispatch-filters-dropdown .dispatch-menu-content {
  flex: 1;
  overflow-y: auto;
}
.dispatch-filters-dropdown ul {
  margin: 0;
}
.dispatch-filters-dropdown ul li {
  font-size: 12px;
  line-height: 1.2;
  padding: 8px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dispatch-filters-dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}
.dispatch-filters-dropdown ul li.selected {
  background-color: rgba(0, 150, 136, 0.2);
  font-weight: bold;
}
.dispatch-filters-dropdown .search-input {
  position: sticky;
  top: 0;
  z-index: 22;
  border-bottom: 2px solid #dddddd;
}
.dispatch-filters-dropdown .search-input input {
  border: 0;
  padding: 5px 8px;
  width: 100%;
  font-size: 12px;
  padding-right: 32px;
}
.dispatch-filters-dropdown .search-input .search-action {
  position: absolute;
  z-index: 23;
  width: auto;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0 10px;
}
.dispatch-filters-dropdown .search-input .search-action .icon {
  min-width: 12px;
  height: 12px;
  width: 12px;
  display: inline-block;
  cursor: pointer;
}
.dispatch-filters-dropdown .search-input .search-action .icon app-svg-icon {
  display: block;
}
.dispatch-filters-dropdown .search-input .search-action .icon svg {
  fill: currentColor;
}

.common-mat-dialog .mat-dialog-title {
  position: relative;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}
.common-mat-dialog .mat-dialog-title .close-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 5px;
  background-color: var(--blue);
  border: 0;
  color: #fff;
  border-radius: 50%;
  margin: auto 0;
  z-index: 2;
  cursor: pointer;
}
.common-mat-dialog .mat-dialog-title .close-button svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.product-copy-content-order {
  background: #319688;
  width: calc(100% - 70px);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.product-copy-content-order span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 115px);
  cursor: pointer;
}
.product-copy-content-order span.clear-copy {
  float: right;
  border-left: 1px white solid;
  padding-left: 10px;
  margin-left: auto;
  flex: 1 1 45px;
  max-width: 45px;
  cursor: pointer;
}
.product-copy-content-order span.copied {
  flex: 1 1 70px;
  max-width: 70px;
  cursor: pointer;
}
.product-copy-content-order span.copied i.copied {
  color: #f7c326;
  text-transform: uppercase;
}

.all-org-menu {
  min-width: 170px !important;
  border-radius: 0 0 4px 4px !important;
}
.all-org-menu ul {
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 385px;
  overflow: hidden;
  overflow-y: auto;
}
.all-org-menu ul a {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  color: black;
  gap: 8px;
  height: 100%;
  text-decoration: none;
  transition: all 0.3s;
}
.all-org-menu ul a:hover {
  background-color: #e2e2e2;
  color: black;
}
.all-org-menu ul a figure {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.all-org-menu ul a figure span {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 26px;
  text-transform: capitalize;
  height: 100%;
  background-color: #808080;
}
.all-org-menu ul a figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.all-org-menu ul a .selected-org-detail {
  flex: 1;
  overflow: hidden;
}
.all-org-menu ul a .selected-org-detail .org-name {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.all-org-menu ul a .selected-org-detail .org-type {
  margin: 0;
  font-size: 10px;
  color: #858585;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.all-org-menu ul a.selected {
  background-color: var(--blue);
  color: white;
}
.all-org-menu ul a.selected .selected-org-detail .org-type {
  color: currentColor;
}
.all-org-menu ul .create-org-button {
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  position: sticky;
  bottom: 0;
}
.all-org-menu ul .create-org-button a {
  text-transform: uppercase;
  font-weight: 500;
  position: sticky;
  bottom: 0;
}
.all-org-menu ul .create-org-button a .icon {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding: 6px;
}
.all-org-menu ul .create-org-button a .icon app-svg-icon {
  display: block;
}

@media screen and (min-width: 1280px) {
  .right-container.with-filter {
    margin-left: 267px;
  }
}
.edit-variation-modal .edit-variation-form .product-name {
  font-size: 20px;
  font-weight: 500 !important;
  margin: 0;
}
.edit-variation-modal .edit-variation-form .product-sku {
  font-size: 13px;
  margin: 0;
  line-height: 1.5;
}
.edit-variation-modal .edit-variation-form ul {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0 10px 0;
}
.edit-variation-modal .edit-variation-form ul li {
  background-color: #eeeeee;
  padding: 3px 5px;
  line-height: 1;
  font-size: 12px;
  border-radius: 3px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.profile-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  display: block !important;
  position: relative !important;
  border-radius: 2px !important;
  padding: 0px !important;
  width: 280px;
}

.profile-card-header {
  display: flex;
  align-items: center;
  background-size: cover;
  padding: 1rem 1rem;
  background-color: #319688;
  overflow-wrap: anywhere;
}
.profile-card-header .profile-avatar {
  float: left;
  background: white;
  border-radius: 100%;
  border: solid 2px #dad7d7;
}
.profile-card-header .profile-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.profile-card-header .proifle-title {
  float: left;
  flex-grow: 1;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  padding-left: 1rem;
}
.profile-card-header .user-phone {
  font-size: 13px;
  color: whitesmoke;
}

.profile-content .pro-menu {
  align-items: center;
  border-bottom: 1px solid #f7f8fa;
  font-size: 16px !important;
  transition: background-color 0.3s ease;
}
.profile-content .material-icons {
  font-size: 16px;
  color: #319688;
  display: block;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}
.profile-content .profile-list {
  list-style: none;
  padding-left: 0;
}

.header-profile-dropdown .mat-menu-item {
  font-family: inherit !important;
  line-height: none !important;
  height: auto !important;
  border-bottom: solid 1px #eaeaea;
}
.header-profile-dropdown .mat-menu-item .icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  color: #319688;
  line-height: 1;
  margin-right: 10px;
}
.header-profile-dropdown .mat-menu-item .icon svg {
  fill: currentColor;
  stroke: currentColor;
}

img {
  user-drag: none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.ox-tab-nav {
  background-color: #efefef;
}
.ox-tab-nav a {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  width: 200px !important;
}
.ox-tab-nav a:hover {
  text-decoration: none;
}

.pac-container {
  z-index: 9999;
}

#barcode-scanning-section {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.retailer-group-list .selected-retailer {
  background-color: rgba(0, 150, 136, 0.1490196078) !important;
}

.challan-update-party h3,
.challan-update-party p {
  text-align: left !important;
}
.challan-update-party .actions-button {
  justify-content: flex-end !important;
}

.croppie-container {
  width: 100%;
  height: 100%;
}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 0 2000px 2000px rgba(105, 105, 105, 0.5);
  box-shadow: 0 0 2000px 2000px rgba(105, 105, 105, 0.5);
  z-index: 0;
}

.croppie-container .cr-resizer {
  z-index: 2;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: "";
}

.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px;
}

.croppie-container .cr-original-image {
  display: none;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  -ms-touch-action: none;
  touch-action: none;
}

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */ /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent; /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}

/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.cr-rotate-controls button {
  border: 0;
  background: none;
}

.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}

.cr-rotate-l i:before {
  content: "↺";
}

.cr-rotate-r i:before {
  content: "↻";
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.left-sidebar > .sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar > .sidebar,
.right-sidebar > .sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  position: relative !important;
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  box-shadow: none;
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1000;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.left-sidebar > .sidebar .header,
.right-sidebar > .sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.left-sidebar > .sidebar .content,
.right-sidebar > .sidebar .content {
  background: #ffffff;
  flex: 1 1 auto;
}

/* listing display section */
.left-sidebar > .sidebar.locked-open.left-positioned + .center,
.right-sidebar > .sidebar.locked-open.left-positioned + .center {
  margin-left: 0;
}

.left-sidebar > .sidebar.locked-open + .center,
.right-sidebar > .sidebar.locked-open + .center {
  z-index: 1001;
}

.left-sidebar.inner-scroll > .center,
.left-sidebar.inner-scroll > .center > .content-card,
.right-sidebar.inner-scroll > .center,
.right-sidebar.inner-scroll > .center > .content-card {
  flex: 1 1 auto;
}

.left-sidebar > .center,
.right-sidebar > .center {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
}

.left-sidebar > .center > .header,
.right-sidebar > .center > .header {
  display: -webkit-box;
  display: flex;
  height: 136px;
  min-height: 136px;
  max-height: 136px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

:focus {
  outline: none !important;
}

button[mat-fab], button[mat-mini-fab], button[mat-raised-button],
a[mat-fab],
a[mat-mini-fab],
a[mat-raised-button] {
  text-decoration: none;
}
button[mat-raised-button].mat-warn, button[mat-fab].mat-warn, button[mat-mini-fab].mat-warn, button[mat-raised-button].mat-accent, button[mat-fab].mat-accent, button[mat-mini-fab].mat-accent,
a[mat-raised-button].mat-warn,
a[mat-fab].mat-warn,
a[mat-mini-fab].mat-warn,
a[mat-raised-button].mat-accent,
a[mat-fab].mat-accent,
a[mat-mini-fab].mat-accent {
  color: #fff;
}
button[mat-fab], button[mat-mini-fab],
a[mat-fab],
a[mat-mini-fab] {
  color: #fff;
}
button.btn-lg,
a.btn-lg {
  padding: 0.8rem 1.2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
button.btn-sm,
a.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.mat-menu-content a[mat-menu-item] {
  text-decoration: none;
}
.mat-menu-content a[mat-menu-item]:hover, .mat-menu-content a[mat-menu-item]:focus {
  color: rgba(0, 0, 0, 0.87);
}

[mat-tab-nav-bar], .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

[mdInput] {
  width: 100%;
}

mat-input-container.mat-icon-left {
  position: relative;
  padding-left: 36px;
}
mat-input-container.mat-icon-left .mat-input-wrapper {
  position: relative;
}
mat-input-container.mat-icon-left .mat-input-infix > mat-icon {
  position: absolute;
  top: 0;
  left: -32px;
  right: auto;
}

.mat-input-container .mat-icon.material-icons {
  font-size: 24px;
}

.mat-radio-group-spacing mat-radio-button {
  margin-right: 20px;
}
.mat-radio-group-spacing mat-radio-button:last-child {
  margin-right: 0;
}

mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline {
  border-color: #ff0000;
}
mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline .mat-input-ripple {
  background-color: #ff0000;
}
mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline {
  border-color: #66bb6a;
}
mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline .mat-input-ripple {
  background-color: #66bb6a;
}

.app-sidebar [mat-button], .app-sidebar [mat-raised-button], .app-sidebar [mat-fab], .app-sidebar [mat-icon-button], .app-sidebar [mat-mini-fab] {
  font-weight: normal;
  min-width: inherit;
}

.quickview-inner .mat-list-item .mat-list-item-content {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.quickview-inner .mat-list-item .mat-list-item-content > p {
  flex: 1 1 auto;
  margin: 0;
}
.quickview-inner .mat-list-item .mat-list-item-content .mat-secondary {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0;
  justify-content: flex-end;
}

.app-header .mat-button {
  min-width: 60px;
}

body .cdk-overlay-container {
  z-index: 1001;
}

.page-with-tabs > .page-title {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 30px 15px 30px 39px;
}
.page-with-tabs > .page-title h2 {
  margin: 0;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #009688;
}
.page-with-tabs > mat-tab-group > mat-tab-header {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
}

.typo-styles dd {
  display: block;
  margin-left: 80px;
  margin-bottom: 20px;
}

.typo-styles .typo-styles__demo {
  margin-bottom: 8px;
}

.page-icons .card .fa,
.page-icons .card .material-icons,
.page-icons .card .wi {
  color: rgba(0, 0, 0, 0.5);
}
.page-icons .card .fa,
.page-icons .card .wi {
  font-size: 20px;
  margin: 5px;
}

.theme-gray .page-icons .card .fa,
.theme-gray .page-icons .card .material-icons,
.theme-gray .page-icons .card .wi,
.theme-dark .page-icons .card .fa,
.theme-dark .page-icons .card .material-icons,
.theme-dark .page-icons .card .wi {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
}

.page-grids .grid-structure .row {
  margin-top: 0.8rem;
}
.page-grids .grid-structure .row .widget-container {
  margin-top: 5px;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px 15px 12px;
  font-size: 0.875rem;
  min-height: 0;
  border-radius: 0.2rem;
}

.color-palette {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 60px;
}
.color-palette ul {
  margin: 0;
  padding: 0;
}
.color-palette .dark {
  color: rgba(0, 0, 0, 0.87);
}
.color-palette .color-group {
  padding-bottom: 40px;
}
.color-palette .color-group:first-child,
.color-palette .color-group:nth-of-type(3n+1),
.color-palette .color-group:last-child:first-child,
.color-palette .color-group:last-child:nth-of-type(3n+1) {
  clear: left;
  margin-left: 0;
}
.color-palette .color-group li.divide,
.color-palette .color-group:last-child li.divide {
  border-top: 4px solid #fafafa;
}
.color-palette .color-group li.color,
.color-palette .color-group:last-child li.color {
  padding: 15px;
}
.color-palette .color-group li,
.color-palette .color-group:last-child li {
  list-style-type: none;
}
.color-palette .color-group li.main-color,
.color-palette .color-group:last-child li.main-color {
  border-bottom: 4px solid #fafafa;
}
.color-palette .color-group li.main-color .name,
.color-palette .color-group:last-child li.main-color .name {
  display: block;
  margin-bottom: 60px;
}
.color-palette .color-group li.color .hex,
.color-palette .color-group:last-child li.color .hex {
  float: right;
  text-transform: uppercase;
}

.page-auth {
  background-color: var(--alice_blue);
  min-height: 100%;
  background-size: cover;
  padding: 0 10px;
}
.page-auth .card {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.page-auth .logo {
  font-size: 32px;
  margin-bottom: 1.3em;
}
.page-auth .logo.text-center {
  padding: 0;
}
.page-auth .logo a {
  font-weight: normal;
  text-decoration: none;
}
.page-auth .main-body {
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  padding: 50px 0 20px;
}
@media (min-width: 768px) {
  .page-auth .main-body {
    padding-top: 100px;
  }
}
.page-auth .additional-info {
  color: #868e96;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 10px 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.875rem;
}
.page-auth .additional-info a {
  color: #636c72;
}
.page-auth .additional-info .divider-h {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 15px;
}
.page-auth.page-confirm-email .logo {
  font-size: 24px;
  margin-bottom: 0.8em;
}
.page-auth .confirm-mail-icon {
  text-align: center;
}
.page-auth .confirm-mail-icon .material-icons {
  color: #636c72;
  font-size: 100px;
}

.page-err {
  background-color: #343a40;
  height: 100%;
  position: relative;
}
.page-err .err-container {
  background-color: #343a40;
  padding: 45px 10px 0;
}
@media (min-width: 768px) {
  .page-err .err-container {
    padding: 100px 0 0;
  }
}
.page-err .err {
  color: #fafafa;
}
.page-err .err h1 {
  margin-bottom: 35px;
  color: #fafafa;
  color: rgba(255, 255, 255, 0.8);
  font-size: 150px;
  font-weight: 300;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .page-err .err h1 {
    font-size: 180px;
  }
}
.page-err .err h2 {
  color: #fafafa;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .page-err .err h2 {
    font-size: 36px;
  }
}
.page-err .err-body {
  padding: 20px 10px;
}
.page-err .btn-goback {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.8);
}
.page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.open .page-err .btn-goback.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.page-err .footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.page-lock {
  height: 100%;
  background-size: cover;
}
.page-lock .lock-centered {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -65px;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-centered {
    margin-top: -75px;
  }
}
.page-lock .lock-container {
  position: relative;
  max-width: 420px;
  margin: 0 auto;
}
.page-lock .lock-box {
  position: absolute;
  left: 0;
  right: 0;
}
.page-lock .lock-box .lock-user {
  background: #fff;
  width: 50%;
  float: left;
  height: 50px;
  line-height: 50px;
  margin-top: 50px;
  padding: 0 20px;
  border-top-left-radius: 0.2rem 0 0 0.2rem;
  color: #868e96;
}
.page-lock .lock-box .lock-img img {
  position: absolute;
  border-radius: 50%;
  left: 40%;
  width: 80px;
  height: 80px;
  border: 6px solid #fff;
  background: #fff;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-box .lock-img img {
    left: 33%;
    width: 150px;
    height: 150px;
    border: 10px solid #fff;
  }
}
.page-lock .lock-box .lock-pwd {
  background: #fff;
  width: 50%;
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0 0 0 50px;
  margin-top: 50px;
  border-top-right-radius: 0 0.2rem 0.2rem 0;
  color: #009688;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-box .lock-pwd {
    padding: 0 0 0 80px;
  }
}
.page-lock .lock-box .lock-pwd input {
  width: 80%;
  height: 50px;
  color: #495057;
  border: 0;
}
.page-lock .lock-box .lock-pwd .btn-submit {
  position: absolute;
  top: 50%;
  right: 20px;
  color: rgba(0, 0, 0, 0.87);
}
.page-lock .lock-box .lock-pwd .btn-submit .material-icons {
  line-height: 50px;
  height: 50px;
}

.page-profile .profile-header {
  position: relative;
  margin: 0 0 15px;
  padding: 50px 30px 90px;
  background: url("../../assets/images//background/1.png") no-repeat center center fixed;
  background-size: cover;
}
.page-profile .profile-img {
  display: inline-block;
  margin-right: 20px;
}
.page-profile .profile-img img {
  max-width: 120px;
  height: auto;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2);
}
.page-profile .profile-social {
  display: inline-block;
}
.page-profile .profile-social > a {
  margin-right: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.page-profile .profile-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 10px 30px;
  color: #fafafa;
}
.page-profile .profile-info ul {
  margin: 0;
}
.page-profile img.media-object {
  border-radius: 0.2rem;
}

.page-invoice {
  color: rgba(0, 0, 0, 0.87);
}
.page-invoice .invoice-wrapper {
  padding: 0 0 30px;
  background-color: #fff;
}

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #fff;
}
.invoice-inner .invoice-sum li {
  margin-bottom: 5px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
}
.invoice-inner .table.table-bordered {
  border: 0;
}
.invoice-inner .table .bg-color-dark > th {
  border: 0;
}

.page-dashboard h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 30px;
}
.page-dashboard .box {
  position: relative;
  border-radius: 0.2rem;
}
.page-dashboard .box .box-top,
.page-dashboard .box .box-bottom {
  height: 100px;
  padding: 32px 15px;
  font-size: 40px;
  line-height: 1;
  text-align: center;
  font-weight: 300;
}
.page-dashboard .box .box-top .material-icons,
.page-dashboard .box .box-bottom .material-icons {
  font-size: 40px;
}
.page-dashboard .box .box-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.page-dashboard .box .box-info {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -12px;
  text-align: center;
}
.page-dashboard .box .box-info span {
  height: 24px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  line-height: 14px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 11px;
  color: #868e96;
  border-radius: 1em;
}

.theme-gray .page-dashboard .box .box-info span {
  background-color: #444;
}

.theme-dark .page-dashboard .box .box-info span {
  background-color: #3a4047;
}

.vprogressbar-container {
  height: 250px;
  margin-top: 25px;
  border-bottom: rgba(221, 221, 221, 0.3) 2px solid;
  position: relative;
}
.vprogressbar-container.brand-info {
  border-color: #00bcd4;
}
.vprogressbar-container.brand-success {
  border-color: #66bb6a;
}
.vprogressbar-container .vprogressbar {
  padding-left: 10px;
}
.vprogressbar-container .vprogressbar li {
  position: relative;
  height: 248px;
  width: 35px;
  background: rgba(221, 221, 221, 0.3);
  margin-right: 18px;
  float: left;
  list-style: none;
}
.vprogressbar-container .vprogressbar-percent {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.vprogressbar-container .vprogressbar-legend {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-left: 0;
  padding: 5px 10px;
  text-align: left;
  border-radius: 0.2rem;
  background: rgba(255, 255, 255, 0.15);
}
.vprogressbar-container .vprogressbar-legend li {
  display: block;
  font-size: 11px;
  margin-bottom: 5px;
  list-style: none;
}
.vprogressbar-container .vprogressbar-legend .vpointer {
  height: 10px;
  width: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.vprogressbar-container .vprogressbar-info {
  color: #ccc;
}

.theme-gray .box-info .box-icon .material-icons,
.theme-dark .box-info .box-icon .material-icons {
  color: rgba(255, 255, 255, 0.54);
}
.theme-gray .box-info .box-num,
.theme-dark .box-info .box-num {
  color: rgba(255, 255, 255, 0.54);
}

.page-maintenance .top-header {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 32px;
  line-height: 1;
}
.page-maintenance .top-header a.logo {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.page-maintenance .content {
  max-width: 1140px;
  margin: 50px auto 0;
}
.page-maintenance .main-content {
  margin-bottom: 80px;
}
.page-maintenance .main-content h1 {
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 15px;
}
.page-maintenance .main-content p {
  font-size: 22px;
}

.theme-dark .page-maintenance .top-header a.logo,
.theme-gray .page-maintenance .top-header a.logo {
  color: #fff;
}

.page-about .hero.hero-bg-img {
  background-size: cover;
  padding: 0;
}
.page-about .hero .hero-inner {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 90px 0;
}
.page-about .hero .hero-title,
.page-about .hero .hero-tagline {
  color: #fff;
}
.page-about .stat-container {
  margin-bottom: 30px;
}
.page-about .stat-container .stat-item {
  margin-bottom: 20px;
  border-bottom: 2px solid #f5f5f5;
}
.page-about .stat-container .stat-num {
  display: block;
  color: #009688;
  font-size: 72px;
  font-weight: 300;
  line-height: 66px;
}
.page-about .stat-container .stat-desc {
  display: inline-block;
  margin-bottom: -2px;
  padding-bottom: 20px;
  border-bottom: 2px solid #009688;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}
.page-about .article:nth-of-type(1) {
  padding-top: 75px;
}
.page-about .space-bar {
  padding: 3px;
  border-radius: 0.2rem;
  margin-right: 5px;
}

.page-terms h4 {
  margin-top: 2em;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.blog-item {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  margin-top: 70px;
  padding: 70px 0 10px;
}
.blog-item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
.blog-item h2 {
  font-size: 35px;
  line-height: 1;
}
.blog-item h2 a {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
  text-decoration: none;
}
.blog-item .blog-info {
  margin: 10px 0;
}
.blog-item .blog-info > span {
  margin-right: 8px;
}
.blog-item .blog-info .avatar {
  width: 30px;
  height: auto;
  border-radius: 50%;
}
.blog-item .blog-info .date {
  opacity: 0.7;
}
.blog-item .blog-info .category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 0.2rem;
  background-color: rgba(0, 0, 0, 0.08);
}
.blog-item .blog-info .category a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  opacity: 0.7;
}
.blog-item .desc {
  font-size: 16px;
  opacity: 0.7;
}

.theme-gray .blog-item h2 a,
.theme-dark .blog-item h2 a {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .blog-item .blog-info .category a,
.theme-dark .blog-item .blog-info .category a {
  color: rgba(255, 255, 255, 0.7);
}

.mat-select-search-inside-mat-option .mat-select-search-input {
  padding-left: 0;
}

.fc-trips-event-row.drag-able-row {
  cursor: move;
  font-size: 0.9rem;
  font-weight: 400;
}
.fc-trips-event-row.drag-able-row.drag-able-row {
  display: table;
}
.fc-trips-event-row td {
  text-align: center;
  padding: 0 10px !important;
  padding-left: 0;
  vertical-align: middle !important;
}
.fc-trips-event-row .mat-column-image {
  width: 80px !important;
  max-width: 80px !important;
  padding: 20px !important;
}
.fc-trips-event-row .mat-column-image .trip-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
}
.fc-trips-event-row .mat-column-image svg {
  width: 100%;
  height: 100%;
}
.fc-trips-event-row .mat-column-name {
  min-width: 250px;
  width: 250px;
}
.fc-trips-event-row .mat-column-name .title {
  font-size: 14px;
  display: block;
  text-align: left;
  color: #009688;
  font-weight: 600;
  text-transform: uppercase;
}
.fc-trips-event-row .mat-column-name .region {
  display: block;
  text-align: left;
  color: #787878;
  font-size: 12px;
  font-weight: 500;
}
.fc-trips-event-row .mat-column-period {
  width: 350px;
  text-align: left;
  min-width: 350px;
  max-width: 350px;
}
.fc-trips-event-row .mat-column-period .trip-date {
  font-size: 14px;
  color: #2c2c2c;
  font-weight: 500;
}
.fc-trips-event-row .mat-column-assigned {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.fc-trips-event-row .mat-column-assigned .team-images {
  gap: 10px;
}
.fc-trips-event-row .mat-column-assigned .team-images span {
  width: 27px;
  height: 27px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #2c2c2c;
  line-height: 1;
  border-radius: 50%;
}
.fc-trips-event-row .mat-column-assigned .team-images span.team-image {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
.fc-trips-event-row .mat-column-assigned .team-images span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fc-trips-event-row .mat-column-assigned .unassigned-icon {
  width: 27px;
  height: 27px;
  display: flex;
}
.fc-trips-event-row .mat-column-assigned .unassigned-icon app-svg-icon {
  width: inherit;
  height: inherit;
}
.fc-trips-event-row .mat-column-assigned .unassigned-icon app-svg-icon svg {
  width: inherit;
  height: inherit;
}
.fc-trips-event-row .mat-column-assigned span.user-count {
  color: #2c2c2c;
  padding: 4px;
  font-weight: 500;
}
.fc-trips-event-row .mat-column-status {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  text-align: left;
}
.fc-trips-event-row .mat-column-status .status-drop-btn {
  max-width: 120px;
  width: 80px;
  margin-right: 10px;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-flex {
  background: transparent;
  padding: 0 !important;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-flex .mat-form-field-infix {
  width: 100% !important;
  height: 2.5rem;
  color: #fff;
  min-width: 5rem;
  line-height: 1.6rem;
  border: 0px solid #9c9c9c;
  border-radius: 0.5rem;
  background: #f6f6f6;
  margin-top: 0.3rem;
  font-size: 1rem;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-flex .mat-form-field-infix .mat-select {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-value {
  color: #007b70 !important;
  padding: 0 0.5rem !important;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-form-field-underline {
  display: none !important;
}
.fc-trips-event-row .mat-column-status .status-drop-btn .mat-select-arrow-wrapper .mat-select-arrow {
  color: #007b70 !important;
}
.fc-trips-event-row .mat-column-status .trip-progress {
  max-width: 220px;
}
.fc-trips-event-row .mat-column-status .trip-progress .progress {
  font-size: 0.9rem !important;
  width: 65px;
  padding: 4px 10px;
  height: 25px;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  color: #000000;
  line-height: 1;
  background-color: rgba(0, 150, 135, 0.19);
  border-radius: 5px;
}
.fc-trips-event-row .mat-column-status .trip-progress .progress .progress-bar {
  position: absolute;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  color: inherit;
  border-radius: 5px;
}
.fc-trips-event-row .mat-column-status .trip-progress .progress .progress-value {
  mix-blend-mode: multiply;
}
.fc-trips-event-row .mat-column-action {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  text-align: center;
}
.fc-trips-event-row .mat-column-action .edit-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: var(--blue);
}
.fc-trips-event-row .mat-column-action .edit-icon svg {
  fill: currentColor;
}

.custom-multi-select .mat-option-disabled .mat-pseudo-checkbox {
  display: none;
}
.custom-multi-select .search-option {
  padding: 0;
}
.custom-multi-select .search-filter {
  padding: 0 16px;
  border-bottom: 1px solid #dddddd;
  height: 3rem;
}
.custom-multi-select .search-filter input {
  border: 0;
  outline: 0;
  height: 100%;
}