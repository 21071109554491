@use "sass:math";
// rgba(#000, .035) // #eee

$image_path: "../../assets/images/" !default;
$image_pathdemo: "../../assets/images-demo/" !default;

$skin: theme-color("primary");
$skin-light: #42a5f5 !default; // Blue 400
$dark: #333c44 !default; // darken Blue 100 > #343E46
$dark-light: lighten($dark, 5%) !default;
$grayscale-dark-light: #484848;
$bright: #fafafa !default;
$gray-light: #636c72 !default;
$gray-light2: #999 !default;

$page-bg: #f5f5f5 !default; // background color of .app-page-container

$state-default-bg: rgba(0, 0, 0, 0.1) !default; // also: pagination bg
$state-default-border: darken(adjust-hue($state-default-bg, -10), 5%) !default;

//== Typography
$font-weight-thin: 300 !default;
$font-family-condensed: "Roboto Condensed", "Roboto", "Helvetica Neue", Arial,
  sans-serif !default;

// UI
$container_fluid_maxwidth: 1200px;

// Cards
$card-padding: 20px !default;
$border-radius-card: 2px !default;

// Grid Variables
$element-top-margin: math.div($grid-gutter-width, 3) !default;
$element-bottom-margin: math.div(($grid-gutter-width * 2), 3) !default;

// Tables
$theme-dark-data-table-header-color: rgba(#fff, 0.54);

// box shadow
@mixin z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
@mixin z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
@mixin z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@mixin z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
@mixin z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
@mixin z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
