@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: Roboto;
}

:root {
  --black: #000;
}

html {
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  height: 100%;
}
html.cdk-global-scrollblock {
  top: 0 !important;
  position: relative;
  overflow: hidden;
}

.mat-checkbox {
  display: initial !important;
}

/**
 * Firefox specific rule
 */
body {
  background-color: #f5f5f5 !important;
  color: var(--gray-darker) !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
}
body .bodyOverlay,
body .ox-tooltip-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
  top: 0;
  left: 0;
  display: none;
}
body.sidebarIsOpen, body.productSidebarOpen, body.sidebar-overlay {
  overflow: hidden;
}
body.sidebar-overlay .bodyOverlay, body.productSidebarOpen .bodyOverlay {
  display: block;
}
body.ox-tooltip-open {
  overflow: hidden;
}
body.ox-tooltip-open .ox-tooltip-overlay {
  z-index: 9999;
  display: block;
}

.form-model h1,
.form-model h2,
.form-model h3 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400 !important;
}

::-moz-selection {
  /* Code for Firefox */
  background: rgb(0, 150, 136);
  color: var(--white);
}

::selection {
  background: rgb(0, 150, 136);
  color: var(--white);
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a {
  font-weight: 400;
}

#loader-container,
#load-overlay {
  background: rgba(237, 240, 241, 0.6) url("../assets/images/audio.svg") no-repeat center center !important;
}

.show-loader {
  display: block;
}

.hide-loader {
  display: none;
}

.hide {
  display: none;
}

.mat-menu-item:hover:not([disabled]) {
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: #f7f8fa;
  color: #000;
}

.brand-menu-dropdown {
  width: 500px;
  max-width: 100% !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.fixed-header .app-page-container .app-content-wrapper .app-content {
  padding-top: 0px !important;
}

.p-24 {
  padding: 24px !important;
}

.btn.btn-min-width {
  min-width: auto;
}

.form-control {
  padding: 0.75rem 1.5rem 0.75rem 0.8rem !important;
}

.form-control:focus {
  border-color: #009688 !important;
  box-shadow: none !important;
}

.form-error {
  color: var(--red);
  top: -15px;
  font-size: 12px;
  position: relative;
  float: right;
  height: 0px !important;
}

.form-error.error2 {
  top: 0;
}

.from-error-message {
  top: -61px !important;
}

.form-control:focus {
  border-color: #009688;
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.11);
}

.mat-select,
.mat-button,
.mat-toolbar {
  font-family: inherit !important;
}

.btn-cancel {
  background-color: rgba(158, 158, 158, 0.4) !important;
}

.mat-button-wrapper {
  font-family: roboto, bold;
}

a.btn-w-sm,
button.btn-w-sm {
  min-width: 88px !important;
}

.overlay.transparent {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.large-model-size.nsm-dialog {
  max-width: 900px !important;
  z-index: 1400 !important;
}

.medium-model-size.nsm-dialog {
  max-width: 55vw !important;
}

.small-model-size.nsm-dialog {
  max-width: 330px !important;
  z-index: 9999;
}

.mat-dialog-container {
  min-width: 350px !important;
}

.mat-snack-bar-container {
  background-color: transparent !important;
  padding: 0px;
}

.success {
  padding: 14px 24px;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.error {
  padding: 14px 24px;
  background-color: var(--red) !important;
  border-color: var(--red) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.warning {
  padding: 14px 24px;
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification {
  padding: 14px 24px;
  background-color: #548cf1 !important;
  border-color: #548cf1 !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/**/
.error-input-class {
  background-color: var(--red);
}

.table thead th {
  vertical-align: middle;
}

.table {
  margin-bottom: 0;
}

tr.mat-row {
  height: 65px !important;
}

.ng-value-container {
  height: 44px;
}

/*material form css*/
.mat-form-field,
.mat-raised-button {
  line-height: inherit;
}

.mat-raised-button {
  letter-spacing: 0.14px !important;
}

button[mat-raised-button]:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {
  .mat-drawer-opened.mat-drawer-container {
    height: var(--windowInnerHeight, 100vh) !important;
  }
  .mat-dialog-container {
    min-width: 100% !important;
  }
  .cropper-dialog,
.search-dialog,
.retailer-dialog,
.add-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: auto !important;
  }
  .addOrg-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: 100% !important;
    min-height: auto !important;
  }
}
.mat-drawer-container .mat-drawer {
  background: #262b33;
}

.mat-drawer-container .mat-nav-list .mat-list-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

.mat-drawer-container .mat-nav-list .mat-list-item .mat-icon {
  margin-right: 10px;
}

.mat-nav-list .mat-list-item:hover {
  color: var(--white);
  background-color: rgba(158, 158, 158, 0.2);
}

/*Global css responsive*/
label {
  text-align: left !important;
}

/* Cursor setting */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.search-dialog,
.add-dialog,
.retailer-dialog,
.addOrg-dialog {
  width: 600px;
  height: auto;
  margin: 30px auto !important;
}

.cropper-dialog {
  min-width: 600px;
  width: auto;
  height: auto;
  margin: 30px auto !important;
}

.cate-actions {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.cate-actions li {
  width: 18px;
  margin-right: 10px;
  color: #009688;
  height: 18px;
}
.cate-actions li svg {
  fill: currentColor;
}

.brand-details-modal.mat-bottom-sheet-container {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  max-height: 90vh;
  animation: none !important;
}
.brand-details-modal .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  animation: none !important;
}

.search-dialog .mat-dialog-container,
.add-dialog .mat-dialog-container,
.retailer-dialog .mat-dialog-container,
.cropper-dialog .mat-dialog-container,
.addOrg-dialog .mat-dialog-container,
.edit-organisation .mat-dialog-container,
.markDown-modal .mat-dialog-container,
.unsaved-dialog .mat-dialog-container,
.padding-0 .mat-dialog-container,
.image-cropper-modal .mat-dialog-container,
.brand-details-modal .mat-dialog-container {
  padding: 0 !important;
}

.padding-y-0 .mat-dialog-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.share-catalogue-modal .mat-dialog-container {
  background-color: transparent;
  overflow: visible;
}

.search-dialog .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 240px;
}

.search-dialog {
  height: auto;
  min-height: 204px;
}

.retailer-dialog {
  height: auto;
  min-height: 119px;
}

.add-dialog {
  height: auto;
  min-height: 347px;
}

.addOrg-dialog {
  height: auto;
}

.vertical-center-modal {
  margin: auto !important;
}

.section-header {
  font-weight: 400 !important;
  font-size: 34px;
  line-height: 40px;
  color: var(--blue);
}

.filter-action-btn .mat-icon-button {
  width: auto;
}

.filter-div {
  border: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.download-btn {
  min-width: 35px !important;
  line-height: 31px !important;
}

.download-btn.mat-raised-button {
  padding: 0 9px;
}

.download-btn i {
  font-size: 18px;
}

.table-responsive {
  max-height: 430px !important;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    overflow-y: initial !important;
  }
}

.table th {
  border: 0;
}

.mat-elevation-z8 {
  padding: 15px;
  background: var(--white);
}

.mat-dialog-actions {
  padding: 0 10px !important;
  margin-bottom: 0 !important;
}

.card-action {
  padding: 15px !important;
}

#distributor {
  font-weight: 400;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.icon-box.ibox-lg .ibox-icon {
  height: 60px !important;
  width: 60px !important;
}

.mat-dialog-container {
  color: var(--gray-darker);
}

.outerTemplate {
  padding: 0 35px;
}

#retailer .mat-form-field-label {
  color: rgba(0, 0, 0, 0.26);
  font-weight: 400 !important;
}

#retailer .mat-form-field-hide-placeholder {
  color: red;
  font-weight: 400 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: rgb(0, 150, 136) !important;
}

#retailer .mat-sort-header-container {
  padding: 5px 0;
}

.mat-column-logo .mat-sort-header-container {
  justify-content: center;
}

.avatar-img,
.retailer-avatar-img {
  height: 55px;
  width: 55px;
  font-weight: 600;
}

.avatar-img.notification-drop {
  height: 40px;
  width: 40px;
  font-weight: 600;
  margin: auto;
  border: solid 1px #4ca79c;
}

.avatar-img,
.retailer-avatar-img {
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  border: solid 1px #e4e4e4;
}

.avatar-img.color,
.retailer-avatar-img.color {
  background-color: var(--blue);
  margin: auto;
}

#retailer .mat-cell {
  font-weight: 500;
}

.retailer-add-dialog {
  margin: auto !important;
}

.mat-dialog-content {
  padding: 15px !important;
  margin: 0 !important;
}

.retailer-add-dialog .mat-dialog-content {
  padding: 25px 40px !important;
}

.mat-menu-panel.edit-menu {
  margin-top: 35px;
  min-width: 63px;
  margin-left: -31px;
}

.edit-menu .mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.edit-menu .mat-menu-item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.mat-toolbar-row {
  justify-content: center;
}

.mat-drawer-container {
  background-color: inherit !important;
}

.nsm-content {
  padding: 0 !important;
}

#frmAddOrganization .form-group {
  padding: 0 15px;
}

.add-org-btn {
  text-align: center;
}

.e-tooltip-wrap {
  padding: 5px;
  background-color: #262b33 !important;
  font-size: 16px;
}

/* Image cropper css */
.image-cropper-modal {
  min-width: 790px !important;
}

.edit-organisation {
  margin: 30px auto !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
}

.cdk-global-overlay-wrapper::before {
  content: "";
  height: auto;
  max-height: 100%;
  white-space: nowrap;
}

.cdk-overlay-container .cdk-global-overlay-wrapper,
.cdk-overlay-container .cdk-overlay-container {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ----- */
.mat-menu-panel .mat-menu-content {
  padding: 0px !important;
}

.mat-list[dense] .mat-list-item .mat-line {
  display: inline;
  margin-left: 14px;
}

.closeTop {
  min-width: 20px !important;
  width: 20px;
  height: 20px;
}

.header-profile-dropdown {
  margin-top: -1px;
}

.page-notification .mat-list-item-content {
  padding: 0 !important;
}

.reject-dialog .mat-button-wrapper {
  color: #ffffff !important;
}
.reject-dialog .reject-title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #e40306;
  text-align: center;
}
.reject-dialog .rejected-text {
  text-align: center;
  padding-bottom: 1rem;
}
.reject-dialog .rject-btn.failure {
  background-color: #e40306 !important;
}
.reject-dialog .rject-btn.failure .mat-button-wrapper {
  color: #ffffff !important;
}

.browse-btn:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.otpText span {
  font-weight: 500 !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-size: 14px;
  color: var(--gray-darker);
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mat-tree-node {
  color: var(--gray-darker);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  min-height: 30px !important;
}

.mat-tree-node .mat-checkbox-layout {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-darker);
  text-transform: capitalize;
}

.tree-outer-div,
.tree-inner-div {
  padding: 0;
}

.filter-field {
  border-top: 1px solid #eee;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background: transparent;
  border-bottom: 1px #eeeeee solid;
}

.mat-expansion-panel-body {
  border-bottom: 1px solid #eee;
}

.mat-checkbox-layout {
  width: 100%;
  display: flex !important;
  align-items: center !important;
}
.mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 0;
  margin-right: 8px;
}

.search-options .mat-menu-item {
  height: 30px;
  line-height: 30px;
}

.product-header .mat-fab,
.org-drop .mat-fab {
  height: 26px !important;
  width: 26px !important;
}

.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}

.brandList .mat-select-value {
  color: #fff;
  overflow: visible;
}
.brandList .mat-select-trigger {
  height: auto;
}
.brandList mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

textarea {
  resize: none !important;
}

.page-layout table {
  width: 100%;
}
.page-layout tr.isDeleting {
  pointer-events: none;
  background-color: rgba(223, 33, 33, 0.3) !important;
}
.page-layout tr.isDeleting * {
  pointer-events: none;
}
.page-layout tr.isDeleting:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(223, 33, 33, 0.3);
  z-index: 2;
  font-size: 18px;
  color: #df2121;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
}
.page-layout .mat-table {
  overflow: auto;
  max-height: 500px;
  border-radius: 12px;
  border: 0px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 0;
  border-collapse: separate;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background: transparent;
}
.page-layout .table thead th,
.page-layout table thead th .mat-sort-header-button {
  background: #fff;
  font-family: roboto, medium;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  color: #505050;
}
.page-layout .table:not(.tab-group-table) thead th:first-child {
  border-top-left-radius: 12px;
}
.page-layout .table:not(.tab-group-table) thead th:last-child {
  border-top-right-radius: 12px;
}
.page-layout .mat-table thead {
  background: transparent;
}
.page-layout .mat-elevation-z8 {
  padding: 0px;
}
.page-layout td.mat-cell {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #1e1e1e;
  text-decoration: none;
  vertical-align: middle;
  border: 0;
}
.page-layout tr.mat-header-row {
  height: 64px !important;
  min-height: 64px;
  max-height: 64px;
}
.page-layout th.mat-header-cell {
  padding: 10px;
}
.page-layout .mat-cell {
  font-weight: 500;
}
.page-layout .mat-table > thead > tr {
  border-bottom: none;
}
.page-layout .mat-column-image .mat-sort-header-container {
  justify-content: center;
}
.page-layout .mat-column-noData {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
.page-layout .table thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.page-layout .mat-form-field-infix {
  border-bottom: 0.1em solid #bfbbb8;
}
.page-layout .mat-select-arrow {
  color: #bfbbb8;
}
.page-layout .mat-paginator {
  border-radius: 12px;
  border: none;
}
.page-layout tr.mat-row {
  cursor: pointer;
}

.view-status {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  background-color: #e5f4f1;
  color: var(--blue) !important;
  border-radius: 3px;
  width: 75px;
  justify-content: center;
}
.view-status.view-status-inactive {
  filter: grayscale(1);
}
.view-status .check-icon {
  min-width: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;
  line-height: 10px;
  margin-right: 5px;
  font-size: 12px !important;
  color: inherit !important;
}
.view-status .check-icon svg {
  fill: currentColor;
}

.product-listing .color-pallete-table,
.product-catlogue-listing .color-pallete-table {
  width: auto;
}
.product-listing .color-pallete-table td,
.product-catlogue-listing .color-pallete-table td {
  padding: 4px 1px 0px 0px;
  border-top: 0;
}
.product-listing .color-palette,
.product-catlogue-listing .color-palette {
  float: left;
  width: 20px !important;
  height: 20px !important;
  margin: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 0 !important;
  margin-left: 0px;
  position: relative;
}
.product-listing .color-palette span,
.product-catlogue-listing .color-palette span {
  font-size: 10px;
  top: -0.7rem;
  position: relative;
}
.product-listing .plt-image-container,
.product-catlogue-listing .plt-image-container {
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}
.product-listing th.mat-column-image,
.product-listing th.mat-column-status,
.product-catlogue-listing th.mat-column-image,
.product-catlogue-listing th.mat-column-status {
  border-radius: 0;
}
.product-listing .mat-column-detail,
.product-catlogue-listing .mat-column-detail {
  padding: 10px 20px !important;
  text-align: left !important;
  width: auto;
  min-width: 180px;
}
.product-listing .mat-column-type,
.product-catlogue-listing .mat-column-type {
  width: 140px;
  padding: 0 20px !important;
}
.product-listing .mat-column-check,
.product-catlogue-listing .mat-column-check {
  width: 60px;
  padding: 0 20px !important;
}
.product-listing .mat-column-image,
.product-catlogue-listing .mat-column-image {
  width: 80px;
  padding: 10px 0 !important;
}
.product-listing .mat-column-image:first-of-type,
.product-catlogue-listing .mat-column-image:first-of-type {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.product-listing .mat-column-category,
.product-catlogue-listing .mat-column-category {
  padding: 20px !important;
  width: 140px;
}
.product-listing td.mat-column-category,
.product-catlogue-listing td.mat-column-category {
  font-size: 16px !important;
}
.product-listing .mat-column-feature,
.product-catlogue-listing .mat-column-feature {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-label,
.product-catlogue-listing .mat-column-label {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-action,
.product-catlogue-listing .mat-column-action {
  width: 140px;
  padding: 20px !important;
}
.product-listing .mat-column-status,
.product-catlogue-listing .mat-column-status {
  width: 140px;
  padding: 20px !important;
}

.fixed-header .app-header {
  z-index: 1003;
}
.fixed-header .app-header .mat-sidenav-container {
  position: absolute;
  min-height: auto !important;
  height: auto !important;
}
.fixed-header .app-header .mat-sidenav-container.mat-drawer-opened, .fixed-header .app-header .mat-sidenav-container.mat-drawer-container-has-open {
  height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px)) !important;
}

body .cdk-overlay-container {
  z-index: 1003;
}

.product-detail-dialog .mat-dialog-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}

.p-detail-dialog .mat-dialog-container {
  padding: 0px;
}

.p-detail-dialog .mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 0 10px !important;
  margin: 0 !important;
}

.p-detail-dialog .mat-dialog-content {
  padding: 15px;
  margin: 0;
}

.p-detail-dialog .table th {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.p-detail-dialog tr.mat-row {
  height: 48px !important;
}
.p-detail-dialog tr.mat-header-row {
  height: 45px;
  background: #504b51 !important;
}
.p-detail-dialog .mat-header-cell {
  color: #fff !important;
}
.p-detail-dialog .mat-cell,
.p-detail-dialog .mat-header-cell {
  padding: 10px !important;
  text-align: center;
  vertical-align: middle;
}
.p-detail-dialog .mat-column-size {
  min-width: 100px;
}
.p-detail-dialog .mat-column-color {
  min-width: 167px;
}
.p-detail-dialog .mat-column-stock {
  min-width: 50px;
}
.p-detail-dialog .mat-column-status {
  min-width: 150px;
}
.p-detail-dialog .mat-column-mrp {
  min-width: 50px;
}

.mat-paginator-container {
  border-left: none;
  border-right: none;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mat-expansion-panel-body {
  position: relative !important;
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .search-section .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  .search-section .mat-form-field-infix {
    border-top: 0 !important;
  }
  .search-section .mat-form-field-underline {
    bottom: 0 !important;
  }
}
/* new page design */
.top-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
}

.accent {
  background-color: #504b51 !important;
  color: #fff !important;
}

.left-sidebar > .sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  top: var(--headerHeight, 0px);
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar > .sidebar,
.right-sidebar > .sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1003;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  border-right: solid 5px #35a093;
}

.left-sidebar > .sidebar .header,
.right-sidebar > .sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.p-24 {
  padding: 24px !important;
}

.header .brandList {
  width: 100%;
}

.left-sidebar > .sidebar .content,
.right-sidebar > .sidebar .content {
  background: #f5f5f5;
  flex: 1 1 auto;
  position: fixed !important;
  top: 0 !important;
  width: calc(100% - 5px) !important;
}

.ps {
  position: relative;
}

/* nav header */
.navs .nav-subheader {
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 600;
  padding-left: 24px;
  margin-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}

/* listing display section */
.left-sidebar > .sidebar.locked-open.left-positioned + .center,
.right-sidebar > .sidebar.locked-open.left-positioned + .center {
  margin-left: 0;
  padding-left: 15px !important;
  margin-left: 267px !important;
}

.left-sidebar > .sidebar.locked-open + .center,
.right-sidebar > .sidebar.locked-open + .center {
  z-index: 1001;
}

.left-sidebar.inner-scroll > .center {
  flex: 1 1 auto;
}

.left-sidebar > .center > .header,
.right-sidebar > .center > .header {
  display: -webkit-box;
  display: flex;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  margin: 0px;
  margin-bottom: 10px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.page-layout {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}

.content-card {
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 10px;
  box-shadow: none !important;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.resolution-hint {
  font-size: 14px;
  padding: 15px;
  background: #f3f3f3;
  color: #949393;
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;
}
.resolution-hint i.fa-info-circle {
  color: currentColor;
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

.hiddenMatField {
  visibility: hidden;
  padding-bottom: 1.6em;
}

.clear-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.clear-section-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  padding-right: 10px;
}

.material-icons.icon-setting {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000;
  line-height: 22px;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.filter-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.filter-title svg {
  width: 14px;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.mat-checkbox-background {
  background-color: #f4f5f9;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  margin: 0 !important;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.orderStatus-menu .mat-menu-item {
  height: 30px;
  line-height: 30px;
}
.orderStatus-menu .mat-menu-item .status-icon {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ddd;
  vertical-align: middle;
  margin-right: 5px;
  opacity: 0;
}
.orderStatus-menu .mat-menu-item.status-selected .status-icon {
  opacity: 1;
}
.orderStatus-menu .mat-menu-item.status-pending .status-icon {
  background-color: #e67a57;
}
.orderStatus-menu .mat-menu-item.status-completed .status-icon {
  background-color: #15224c;
}
.orderStatus-menu .mat-menu-item.status-partially-delivered .status-icon {
  background-color: #a5a5a5;
}
.orderStatus-menu .mat-menu-item.status-new .status-icon {
  background-color: #00bcd4;
}
.orderStatus-menu .mat-menu-item.status-delivered .status-icon {
  background-color: #4caf50;
}
.orderStatus-menu .mat-menu-item.status-processing .status-icon {
  background-color: #2b98e0;
}
.orderStatus-menu .mat-menu-item.status-removed .status-icon {
  background-color: #f1261e;
}
.orderStatus-menu .mat-menu-item.status-cancelled .status-icon {
  background-color: #db3e38;
}
.orderStatus-menu .mat-menu-item.status-draft .status-icon {
  background-color: #ada14b;
}

.required-warn {
  color: var(--red);
}

#organisationName {
  text-transform: capitalize;
}

.errorMsg {
  color: var(--red);
}

.errorBorder {
  border-bottom: 1px solid var(--red);
  padding: 10px;
}

.search-section-heading {
  font-size: 22px;
  font-weight: 500;
  margin: 1px 0px 2px 4px;
}

table .noDataAvailable {
  min-height: 300px;
}

.mat-menu-item {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
}

.owl-carousel .nav-btn {
  height: 60px;
  position: absolute;
  width: 20px;
  cursor: pointer;
  top: 0 !important;
  bottom: 0;
  margin: auto 0;
  padding-top: 20px;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background-color: #d8d8d8;
  left: -160px;
  border-radius: 2px;
}
.owl-carousel .prev-slide i {
  color: black !important;
  font-weight: bold;
}

.owl-carousel .next-slide {
  background-color: #d8d8d8;
  right: -28px;
  border-radius: 2px;
}
.owl-carousel .next-slide i {
  color: black !important;
  font-weight: bold;
}

.owl-theme.row {
  margin-left: 0px !important;
  margin-right: opx !important;
}

@media (max-width: 770px) {
  .owl-carousel .prev-slide {
    left: -145px;
  }
}
.demo {
  background-color: red !important;
  color: gray !important;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}

.brandList.order-brand-disabled .mat-select-disabled .mat-select-value {
  color: #fff !important;
}

.filenameStyle {
  word-break: break-word;
}

.feature-icon {
  color: #26756a !important;
}

.mat-tree-node.mat-tree-title {
  min-height: 30px !important;
  cursor: pointer;
  text-transform: capitalize;
}

.mat-tree-node.tree-child-item {
  padding-left: 45px;
}

.mat-icon-rtl-mirror.category.mat-icon.material-icons {
  line-height: 19px !important;
}

.expension-panel-product.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.expension-panel-product.mat-expanded:hover,
.expension-panel-product.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel-header.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  padding-top: 15px;
}

.listing-tags-blocks .mat-expansion-panel-body {
  padding: 0 !important;
}
.listing-tags-blocks .mat-expansion-panel-content.mat-expanded {
  padding: 0 !important;
  padding-top: 0 !important;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.scrollbar-new::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-new::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

.scrollbar-new::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.p-price .ngx-slider .ngx-slider-pointer {
  width: 15px;
  height: 15px;
  top: -5px;
}
.p-price .ngx-slider .ngx-slider-pointer:after {
  width: 9px;
  height: 9px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.p-price .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  transition: opacity 0.3s linear;
  bottom: -30px;
  opacity: 1 !important;
  visibility: visible !important;
  font-size: 14px;
}
.p-price .ngx-slider .ngx-slider-bubble[class*=ngx-slider-model], .p-price .ngx-slider .ngx-slider-bubble.ngx-slider-combined {
  font-size: 12px;
}
.p-price .ngx-slider .ngx-slider-pointer {
  background-color: #00a093;
  cursor: grab;
}
.p-price .ngx-slider .ngx-slider-pointer.ngx-slider-active {
  cursor: grabbing;
}
.p-price .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: #00a093;
}
.p-price .ngx-slider .ngx-slider-selection {
  background-color: #00a093;
}

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.mat-menu-panel {
  min-height: initial !important;
}

.scrollable::-webkit-scrollbar, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar, .lightbox .lb-outerContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollable::-webkit-scrollbar-thumb, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar-thumb, .lightbox .lb-outerContainer::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}
.scrollable::-webkit-scrollbar-track, .enterprise-detail-modal .enterprise-details::-webkit-scrollbar-track, .lightbox .lb-outerContainer::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.btn-w-svg .mat-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  gap: 10px;
  height: 100%;
}
.btn-w-svg app-svg-icon {
  line-height: 15px;
  display: inline-flex;
  align-items: center;
}
.btn-w-svg svg {
  width: 15px !important;
  height: 15px !important;
  fill: currentColor;
}

.mat-error {
  width: 100%;
  font-size: 12px;
  color: #ef5350;
}

.more-menu-buttons.detailed-preview-menu {
  width: 180px;
}
.more-menu-buttons .action-buttons {
  flex-direction: column;
}
.more-menu-buttons .action-buttons button {
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: left;
  box-shadow: none;
  margin-right: 0 !important;
}
.more-menu-buttons .action-buttons button svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.more-menu-buttons.create-options .create-icons {
  color: var(--blue);
}

.quick-search-filter .mat-expansion-panel-body {
  padding: 0 !important;
}
.quick-search-filter .mat-expansion-panel-content.mat-expanded {
  padding-top: 0 !important;
}
.quick-search-filter .quick-search-btn.btn {
  border: #f9f9f9 2px solid;
  background-color: white;
  color: black;
  margin-bottom: 0px !important;
  border-radius: 5px;
  padding: 10px;
}
.quick-search-filter .quick-search-btn.btn.disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
.quick-search-filter .quick-search-btn.btn input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.quick-search-filter .quick-search-btn.btn .searchForm .cancle-btn-new {
  width: 15px;
  height: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.quick-search-filter .quick-search-btn.btn .searchForm .cancle-btn-new svg {
  width: 100%;
  height: 100%;
}
.quick-search-filter .quick-search-btn.btn svg {
  width: 20px;
  height: 20px;
  fill: #ccc;
}
.quick-search-filter .quick-search-btn.btn.active svg {
  fill: #f5b855;
}
.quick-search-filter .quick-search-btn.btn .quick-search-content {
  display: flex;
  align-items: center;
}
.quick-search-filter .quick-search-btn.btn .quick-search-content span {
  vertical-align: middle;
  margin-left: 10px;
}

.no-records-found {
  padding: 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 0;
  font-family: inherit;
}
.no-records-found.is-sticky {
  position: sticky;
}
.no-records-found.is-sticky.sticky-left {
  left: 0;
}
.no-records-found.is-sticky.sticky-right {
  right: 0;
}

.searchForm .search-btn-style {
  color: #ffffff !important;
  background-color: #00a093 !important;
  border-color: #00a093 !important;
  margin-right: -2px;
  height: 38px;
  border: 1px solid transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
}
.searchForm .search-input {
  height: 38px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cbd5dd;
  border-radius: 0;
  z-index: 11;
}
.searchForm .cancle-btn {
  position: absolute;
  right: 60px;
  top: 7px;
  z-index: 13;
  cursor: pointer;
}
.searchForm .cancle-btn-new {
  position: absolute;
  right: 60px;
  top: 7px;
  z-index: 13;
  cursor: pointer;
  width: 15px;
  height: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.searchForm .cancle-btn-new svg {
  width: 100%;
  height: 100%;
}

.mat-dialog-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}

.mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 12px 10px;
}

.right-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
}
.right-container .header .box {
  margin-bottom: 0;
}
.right-container .header .box-body {
  padding: 1rem;
}

.tab-with-table {
  flex: 1 1 auto;
}
.tab-with-table .mat-tab-body-wrapper {
  flex: 1 1 auto;
}

.otp-new-input {
  border-bottom: 3px solid #319688 !important;
  text-align: center !important;
  font-size: 24px !important;
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 0px !important;
}

.otp-new-input::placeholder {
  color: #dadada;
  opacity: 1;
}

.button-with-loader .mat-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.generate-invoice-modal .mat-dialog-container {
  padding: 0;
}

b,
strong {
  font-weight: 500 !important;
}

.country-code-control {
  width: 90px;
}

.header-sub-menu .mat-menu-content a .menu-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 10px;
}
.header-sub-menu .mat-menu-content a .menu-icon svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
}
.header-sub-menu .mat-menu-content a.active-route {
  background-color: #f2f2f2;
}

#product-details-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: var(--headerHeight, 0px);
  z-index: 1003;
  width: 450px;
  max-width: 100%;
  height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;
}
#product-details-sidebar::-webkit-scrollbar {
  display: none;
}
@media (max-width: 767px) {
  #product-details-sidebar {
    height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  }
}

#activity-feed-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: 0;
  z-index: 1003;
  width: 500px;
  max-width: 100%;
  height: 100%;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;
}
#activity-feed-sidebar::-webkit-scrollbar {
  display: none;
}

.add-trips-sidebar {
  width: 365px !important;
}

.product-size-sheet-container {
  border-radius: 15px 15px 0 0;
  padding: 0 !important;
  width: 450px;
  max-width: 100%;
  min-width: initial !important;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
  position: relative;
  top: 4px;
  left: 3px;
  background: #fff !important;
}

.mat-slide-toggle-bar {
  position: relative;
  width: 40px !important;
  height: 20px !important;
  flex-shrink: 0;
  border-radius: 10px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #009688 !important;
}

.right-container .mat-tab-label,
.detail-modal .mat-tab-label {
  height: 64px !important;
}

.page-container .mat-ink-bar {
  background: #319688 !important;
}

.mat-ink-bar {
  height: 5px !important;
  border-radius: 5px;
}

.product-tab-group .mat-tab-label {
  text-transform: uppercase;
  font-family: roboto, bold;
}
.product-tab-group .mat-tab-label-active {
  background: rgba(0, 150, 136, 0.1490196078);
}

.header-menu-list .mat-button-wrapper {
  font-size: 14px;
}

.page-layout .main-sidebar.products .brand-select-box .mat-form-field-infix,
.page-layout .main-sidebar.orders .brand-select-box .mat-form-field-infix {
  padding: 10px 20px !important;
  border: none !important;
}
.page-layout .main-sidebar.products .brand-select-box .mat-form-field-wrapper,
.page-layout .main-sidebar.orders .brand-select-box .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.page-layout .main-sidebar.products .brand-select-box .brand-logo,
.page-layout .main-sidebar.orders .brand-select-box .brand-logo {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 8px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d5d5d5;
  text-transform: uppercase;
  border-radius: 100%;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
}
.page-layout .main-sidebar.products .brand-select-box .mat-option,
.page-layout .main-sidebar.orders .brand-select-box .mat-option {
  height: 4rem !important;
}
.page-layout .main-sidebar.products .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.page-layout .main-sidebar.products .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple,
.page-layout .main-sidebar.orders .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.page-layout .main-sidebar.orders .brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}

.enable-toggle.mat-slide-toggle {
  position: relative;
}
.enable-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-content::after {
  content: "ON";
  font-size: 12px;
  color: black;
  position: absolute;
}
.enable-toggle.mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-content::after {
  content: "OFF";
  font-size: 12px;
  color: white;
  position: absolute;
}
.enable-toggle.mat-slide-toggle .mat-slide-toggle-bar {
  width: 120px;
}

.navs .nav-item {
  height: calc(var(--windowInnerHeight, 100vh) - (var(--headerHeight, 0px) + 114px));
  overflow-y: auto;
}

.list-product-image {
  border-radius: 5px;
}

app-no-records-available {
  display: flex;
  flex-direction: column;
  flex: 1;
}

ngx-skeleton-loader {
  line-height: 0;
  display: block;
}

.internal-load {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.mat-expansion-panel-header[aria-disabled=true] .filter-action-btn {
  color: rgba(0, 0, 0, 0.26);
}

.content-card.card {
  border: solid 1px #e4e4e4;
  border-radius: 12px;
  flex: 1;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 96px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 14px;
  text-transform: capitalize;
  background: #dedede;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #8a8888;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background: #319688;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(187, 187, 187, 0.9411764706);
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: left 0.5s ease-out;
  -moz-transition: left 0.5s ease-out;
  -o-transition: left 0.5s ease-out;
  transition: left 0.5s ease-out;
}

.switch-input:checked ~ .switch-handle {
  left: 67px !important;
  background: #fff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

.lightbox {
  width: 100%;
  height: 100%;
  display: flex !important;
  top: 0 !important;
  position: fixed !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lightbox .lb-container .lb-nav {
  position: static;
}
.lightbox .lb-container .lb-nav .lb-prev,
.lightbox .lb-container .lb-nav .lb-next {
  position: fixed;
  height: 30px;
  background-size: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-position: center;
  width: 30px;
  opacity: 0.5;
}
.lightbox .lb-container .lb-nav .lb-prev:hover,
.lightbox .lb-container .lb-nav .lb-next:hover {
  opacity: 1;
}
.lightbox .lb-container .lb-nav .lb-prev {
  left: 0;
}
.lightbox .lb-container .lb-nav .lb-next {
  right: 0;
}
.lightbox .lb-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.lightbox .lb-data .lb-caption {
  font-size: 16px;
}
.lightbox .lb-outerContainer {
  overflow: auto;
  max-width: 100%;
}
.lightbox .lb-dataContainer {
  margin-top: -40px;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  padding-top: 0;
  border-radius: 0;
}
.lightbox .lb-dataContainer .lb-details {
  float: none;
}
.lightbox .lb-dataContainer .lb-controlContainer {
  float: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-downloadContainer .lb-download {
  height: 20px;
  cursor: pointer;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-closeContainer {
  float: none;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-closeContainer .lb-close {
  width: 20px;
  height: 20px;
  background-size: 100%;
}
.lightbox .lb-dataContainer .lb-controlContainer .lb-zoomContainer .lb-zoomOut,
.lightbox .lb-dataContainer .lb-controlContainer .lb-zoomContainer .lb-zoomIn {
  width: 25px;
  height: 25px;
  background-size: 100%;
  cursor: pointer;
}

.input-group.search-bar-fixed-size {
  width: 350px !important;
}

.table-sticky thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 9999 !important;
  display: block !important;
}

/* nav header */
.navs .nav-subheader {
  display: flex !important;
  align-items: center !important;
  height: 54px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  padding: 15px 24px;
  background: #35a093;
  color: #fff;
  font-family: "Roboto", bold;
  margin-top: 0;
  text-align: left;
  border-bottom: none !important;
}
.navs .nav-subheader .clear-button-pro {
  color: #fff;
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
}

.product-size-backdrop + .cdk-global-overlay-wrapper {
  justify-content: flex-end !important;
}

.product-tab-group .mat-tab-label,
.create-org-tab-group .mat-tab-label,
.retailers-tab-group .mat-tab-label,
.order-tab-group .mat-tab-label,
.performance-tab-group .mat-tab-label,
.report-tab-group .mat-tab-label,
.challan-tab-group .mat-tab-label,
.product-create-group .mat-tab-label,
.orderDetailsTabGroup .mat-tab-label,
.product-details-tab .mat-tab-label {
  text-transform: uppercase !important;
  font-family: roboto, bold !important;
  border-right: 1px solid #e4e4e4;
}
.product-tab-group .mat-tab-label-active,
.create-org-tab-group .mat-tab-label-active,
.retailers-tab-group .mat-tab-label-active,
.order-tab-group .mat-tab-label-active,
.performance-tab-group .mat-tab-label-active,
.report-tab-group .mat-tab-label-active,
.challan-tab-group .mat-tab-label-active,
.product-create-group .mat-tab-label-active,
.orderDetailsTabGroup .mat-tab-label-active,
.product-details-tab .mat-tab-label-active {
  background: rgba(0, 150, 136, 0.1490196078) !important;
}
.product-tab-group .mat-tab-label-content svg,
.create-org-tab-group .mat-tab-label-content svg,
.retailers-tab-group .mat-tab-label-content svg,
.order-tab-group .mat-tab-label-content svg,
.performance-tab-group .mat-tab-label-content svg,
.report-tab-group .mat-tab-label-content svg,
.challan-tab-group .mat-tab-label-content svg,
.product-create-group .mat-tab-label-content svg,
.orderDetailsTabGroup .mat-tab-label-content svg,
.product-details-tab .mat-tab-label-content svg {
  width: 16px;
}

.selection-custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
}
.selection-custom-checkbox.checked {
  background-color: var(--blue);
}
.selection-custom-checkbox span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: calc(100% - 4px);
}
.selection-custom-checkbox span svg {
  fill: currentColor;
}
.selection-custom-checkbox span.intermediate {
  height: 2px;
  background-color: #fff;
}

.ox-tooltip {
  position: fixed;
  left: 0;
  z-index: 9999;
  padding: 12px;
}
.ox-tooltip .inner-tooltip {
  background-color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.0784313725);
}
.ox-tooltip:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 12px;
  background-color: white;
  border-top: 0;
  z-index: -1;
  left: 60px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.0784313725);
}
.ox-tooltip.show-bottom:before {
  top: 0px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.ox-tooltip.show-top:before {
  bottom: 0px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.tab-nav-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.tab-nav-header .mat-tab-nav-bar,
.tab-nav-header .mat-tab-header {
  border-bottom: 0;
}
.tab-nav-header .mat-tab-link {
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-right: 1px solid #e4e4e4;
}
.tab-nav-header .mat-tab-link svg {
  width: 14px;
}
.tab-nav-header .mat-tab-link:hover {
  text-decoration: none;
}
.tab-nav-header .mat-tab-link.mat-tab-label-active {
  background-color: rgba(0, 150, 136, 0.1490196078) !important;
}

.not-applicable-text {
  font-size: 12px !important;
  color: #bdbdbd !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding: 0 20px !important;
  padding-right: 0;
}

.brand-name {
  color: #3ba094;
  font-size: 16px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: "Roboto", medium;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.mat-cell span.ui-highlight {
  color: #fff;
  font-size: 14px;
  padding: 5px;
  width: 96px;
  display: block;
  margin: auto;
  border-radius: 20px;
}

.ui-highlight.colored.textcolor-red {
  background-color: #ec3737;
}

.ui-highlight.colored.textcolor-dark-pending {
  background-color: #f7b900;
}

.ui-highlight.colored.textcolor-light {
  background-color: #8bc34a;
}

.ui-highlight.colored.textcolor-dark {
  background-color: #009688;
}

thead tr:first-child {
  border-bottom: none !important;
}

.brand-select-box .brand-name {
  color: #3ba094;
  font-size: 16px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: "Roboto", medium;
}
.brand-select-box .brand-logo {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d5d5d5;
  text-transform: uppercase;
  border-radius: 100%;
}
.brand-select-box .mat-option {
  height: 4rem !important;
}
.brand-select-box.mat-form-field-appearance-legacy .mat-form-field-underline,
.brand-select-box.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}

.sort-by-label {
  color: #aaaaaa;
}

.alert-danger {
  background-color: #fbe9e6;
  border-color: #f7d0d1;
  color: #a1513c;
}

.btn-outline-secondary {
  color: #949393;
  background: #fff;
  border-color: #949393;
  padding: 0.5rem 1.75rem;
}

.btn-outline-secondary:hover {
  background-color: #949393;
  border-color: #949393;
  color: #fff;
}

#main-section {
  padding-top: var(--headerHeight, 0px);
}

.fixed-paginator {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: calc(100% - 30px);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 15px;
  background-color: #fff;
}
.fixed-paginator mat-paginator {
  border: 1px solid #e4e4e4 !important;
  border-radius: 0 0 12px 12px !important;
}

@media (max-width: 1199px) {
  .product-detail-modal {
    width: 100% !important;
    max-width: 100vw !important;
  }
}
/* Add Product Image Model */
@media only screen and (max-device-width: 480px) {
  .p-detail-dialog {
    width: 100% !important;
    max-width: 900px !important;
  }
  .p-detail-dialog .mat-dialog-title {
    padding: 10px;
    margin: 0 0 10px;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content {
    max-height: calc(var(--windowInnerHeight, 100vh) - 122px) !important;
    padding: 5px !important;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content .heading-section {
    float: left !important;
    flex: 1 0 100%;
    max-width: 50%;
  }
  .p-detail-dialog .add-image-dialog .mat-dialog-content .uploaded-image-section .image {
    height: 150px !important;
    padding: 10px !important;
  }
  .p-detail-dialog .mat-dialog-actions .card-action {
    padding: 10px !important;
  }
}
/* Create Product */
@media only screen and (max-device-width: 480px) {
  .imageblock.add-image-plus {
    height: 170px !important;
  }
}
.mat-tooltip {
  color: #fff;
  border-radius: 4px !important;
  background: #3a3939;
  margin: 10px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border: solid 1px #5a5959;
}

.attribute-icon {
  width: 18px;
  height: 18px;
  margin: 4px;
  margin-right: 6px;
}
.attribute-icon svg {
  width: 100%;
  height: 100%;
}

.mat-raised-button.mat-accent[disabled],
.mat-raised-button[disabled][disabled] {
  cursor: not-allowed;
}

.select-retailer-modal {
  height: 90vh;
}
@media (max-width: 767px) {
  .select-retailer-modal {
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
  }
}

perfect-scrollbar > .ps {
  min-height: inherit;
}

.sort-action-btn {
  min-width: 45px !important;
}
.sort-action-btn span {
  width: 15px;
  display: inline-block;
}

.page-auth .phone-form-group .phone-form-field .mat-form-field-infix {
  width: auto;
}

.add-org-logo-modal .mat-dialog-content {
  padding: 0 !important;
}
.add-org-logo-modal .mat-dialog-content .add-org-logo-content {
  max-height: calc(var(--windowInnerHeight, 100vh) - 200px);
}
@media (max-width: 767px) {
  .add-org-logo-modal .mat-dialog-content .add-org-logo-content {
    max-height: calc(var(--windowInnerHeight, 100vh) - 135px);
  }
}
@media (max-width: 375px) {
  .add-org-logo-modal .croppie-container .cr-viewport {
    width: 240px !important;
    height: 240px !important;
  }
}

.ox-custom-radio.status-radio ul {
  gap: 5px;
  margin: 0;
}
.ox-custom-radio label {
  margin: 0;
}
.ox-custom-radio label input[type=radio] {
  display: none;
}
.ox-custom-radio label .radio-box {
  display: flex;
  padding: 3px 5px;
  border: 1px solid var(--blue);
  font-size: 11px;
  line-height: 1.2;
  color: var(--blue);
  cursor: pointer;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.ox-custom-radio label .radio-box .icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ox-custom-radio label .radio-box .icon svg {
  fill: currentColor;
}
.ox-custom-radio label input[type=radio]:checked + .radio-box {
  background-color: var(--blue);
  color: #fff;
}

.table-actions-list {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}
.table-actions-list li a {
  width: 20px;
  height: 20px;
  display: block;
  line-height: 20px;
  margin: 0;
}
.table-actions-list li a app-svg-icon {
  line-height: inherit;
}
.table-actions-list li a.delete-icon {
  color: #df2121;
}
.table-actions-list li a svg {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
}

.enterprise-detail-modal {
  padding: 5px;
  position: relative;
  overflow-x: hidden;
}
.enterprise-detail-modal p {
  margin: 0;
  line-height: 20px;
}
.enterprise-detail-modal h5 {
  margin: 0;
}
.enterprise-detail-modal .close-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 5px;
  background-color: #009688;
  border: 0;
  color: #fff;
  border-radius: 50%;
  margin: auto 0;
  z-index: 2;
  cursor: pointer;
}
.enterprise-detail-modal .close-button svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.enterprise-detail-modal .modal-heading {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 0px 20px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}
.enterprise-detail-modal .modal-heading .menu-icon {
  margin-right: 6px;
  width: 21px;
  height: 19px;
  color: var(--gray-darker);
}
.enterprise-detail-modal .modal-heading .menu-icon app-svg-icon {
  fill: var(--gray-darker);
  display: flex;
  height: 19px;
  width: 21px;
}
.enterprise-detail-modal .enterprise-details {
  padding: 17px 20px 15px 18px;
  margin: 0;
  max-height: calc(96vh - 64px);
  overflow-y: auto;
}
.enterprise-detail-modal .enterprise-details .plane {
  display: inline-block;
}
.enterprise-detail-modal .enterprise-details .plane.fly-plane {
  color: #319688;
  font-size: 18px;
  animation-name: fly;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.735, 0.43, 0.275, 0.76);
}
.enterprise-detail-modal .enterprise-details .enterprise-logo {
  width: 80px;
  height: 80px;
}
.enterprise-detail-modal .enterprise-details .enterprise-logo .ng-star-inserted {
  width: 100%;
  height: 100%;
  font-size: 24px;
}
.enterprise-detail-modal .enterprise-details .enterprise-logo img {
  min-width: 100%;
  min-height: 100%;
}
.enterprise-detail-modal .enterprise-details .enterprise-name {
  margin: 0 !important;
  font-weight: 600 !important;
  color: #009688;
  font-size: 16px;
}
.enterprise-detail-modal .enterprise-details .enterprise-active {
  background-color: rgba(0, 150, 136, 0.19);
  margin: 0 0 0px 7px;
  border-radius: 5px;
  padding: 3px 4px;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
.enterprise-detail-modal .enterprise-details .enterprise-active.inactive-status {
  filter: grayscale(1);
}
.enterprise-detail-modal .enterprise-details .distributor-marked {
  font-size: 10px;
  background-color: #46495B;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  line-height: 1;
  font-weight: 500;
  box-shadow: 0px 3px 6px rgba(68, 70, 88, 0.3019607843);
  margin-left: 7px;
}
.enterprise-detail-modal .enterprise-details .enterprise-contact {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .transproter-GST {
  margin: 0;
  color: #787878;
  font-size: 14px;
}
.enterprise-detail-modal .enterprise-details .transproter-GST span {
  color: #525252;
  font-weight: 500 !important;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title {
  font-size: 12px;
  line-height: 1.3;
  color: #787878;
  margin: 0 0 3px;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span {
  text-transform: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span svg {
  height: 10px;
  width: 10px;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .details-item-title span .help-info {
  height: 11px !important;
  width: 11px !important;
  margin-left: 3px;
  fill: #787878;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .transport-detail-section .trasport-detail-label {
  font-size: 12px;
  color: #787878;
  margin: 10px 0 5px;
  text-transform: uppercase;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .transport-detail-section p {
  line-height: 1.5;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status {
  width: 120px;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  color: white;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-accepted {
  background-color: #00bcd4;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-rejected {
  background-color: #ff0000;
}
.enterprise-detail-modal .enterprise-details .enterprise-details-item .status.status-pending {
  background-color: #ffc107;
}
.enterprise-detail-modal .enterprise-details .address-tag {
  font-size: 12px;
  color: #787878;
  line-height: normal;
}
.enterprise-detail-modal .enterprise-details .enterprise-address {
  color: #2c2c2c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .more-information-section {
  font-size: 13px;
  color: #787878;
  text-transform: uppercase;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .more-information-section span {
  color: #525252;
  font-weight: 500;
  line-height: 20px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag {
  color: #b8b8b8;
  margin-bottom: 5px;
  line-height: normal;
  font-size: 12px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span {
  text-transform: none;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span svg {
  height: 10px;
  width: 10px;
}
.enterprise-detail-modal .enterprise-details .owner-detail-tag span .help-info {
  height: 11px !important;
  width: 11px !important;
  margin-left: 3px;
  fill: #787878;
}
.enterprise-detail-modal .enterprise-details .owner-name {
  color: #525252;
  font-size: 15px;
  font-weight: 600 !important;
  margin-bottom: 0px;
}
.enterprise-detail-modal .enterprise-details .owner-email {
  line-height: normal;
  color: #787878;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit {
  background-color: #009688;
  color: #ffffff;
  border-radius: 3px;
  line-height: normal;
  margin-left: 5px;
  text-decoration: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit:hover {
  text-decoration: none;
}
.enterprise-detail-modal .enterprise-details .enterprise-edit .mat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px !important;
  width: 21px !important;
  font-size: 16px;
}
.enterprise-detail-modal .enterprise-details .ui-highlight {
  color: #fff;
  font-size: 14px;
  padding: 5px;
  width: 96px;
  display: block;
  text-align: center;
  border-radius: 20px;
}
.enterprise-detail-modal .enterprise-details .invite-btn {
  background: #66bb6a;
  color: #fff;
}
.enterprise-detail-modal .enterprise-details .invite-btn.disabled {
  filter: grayscale(1);
}
.enterprise-detail-modal .enterprise-details .btn-retail-new {
  width: 120px;
  font-size: 14px;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
}
.enterprise-detail-modal .enterprise-details .btn-retail-new .button-text {
  padding-right: 3px;
}
.enterprise-detail-modal .enterprise-details .btn-warning.btn-retail-new {
  background: #f7b902;
}
@media (max-width: 375px) {
  .enterprise-detail-modal .enterprise-details .enterprise-logo {
    min-width: 65px;
    min-height: 65px;
    height: 65px;
    width: 65px;
  }
}
@media (max-width: 425px) {
  .enterprise-detail-modal .enterprise-details .btn-retail-new {
    width: auto;
  }
  .enterprise-detail-modal .enterprise-details .btn-retail-new .button-text {
    display: none;
  }
  .enterprise-detail-modal .enterprise-details .ui-highlight {
    font-size: 11px;
    padding: 3px 5px;
    width: 80px;
  }
  .enterprise-detail-modal .enterprise-details .resend-invite-btn {
    font-size: 11px;
  }
}

@media (max-width: 479px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 350px !important;
    height: 214px !important;
  }
}
@media (max-width: 425px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 320px !important;
    height: 180px !important;
  }
}
@media (max-width: 375px) {
  .desktop-banner-dialog .croppie-container .cr-viewport {
    width: 265px !important;
    height: 149px !important;
  }
  .desktop-banner-dialog .page-auth .otp-form-container .otp-input {
    width: 20px !important;
    font-size: 22px !important;
  }
}

@media (max-width: 375px) {
  .unsaved-modal-box .mat-dialog-container {
    padding: 14px;
  }
}
.as-split-gutter {
  background: transparent !important;
}

@media only screen and (max-width: 768px) {
  .billing-history-plan-panel {
    max-width: 100vw !important;
    width: 100% !important;
  }
}

.mat-slide-toggle {
  margin-bottom: 0;
}
.mat-slide-toggle .mat-slide-toggle-bar {
  height: 21px !important;
}
.mat-slide-toggle .mat-slide-toggle-thumb {
  width: 15px !important;
  height: 15px !important;
  top: 6px;
  left: 3px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(19px, 0, 0) !important;
}

.createMI-menu-dropdown {
  min-width: 100px;
}
.createMI-menu-dropdown .mat-menu-content a {
  display: block;
  padding: 7px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.5;
}
.createMI-menu-dropdown .mat-menu-content a:hover {
  background-color: #f6f6f6;
}

.custom-multi-select .mat-option-multiple .mat-checkbox-layout {
  margin: 0;
}
.custom-multi-select .mat-option-multiple .mat-pseudo-checkbox-checked::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.attr-search-box {
  position: relative;
}
.attr-search-box .clear-search-box {
  position: absolute;
  width: 12px;
  height: 12px;
  display: block;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 7px;
  margin: auto;
  line-height: 1;
  cursor: pointer;
}
.attr-search-box input {
  width: 100%;
  padding: 6px 9px;
  border: 1px solid rgba(120, 120, 120, 0.52);
  border-radius: 2px;
  color: #707070;
  position: relative;
  z-index: 2;
  background-color: #ffffff;
}
.attr-search-box .attr-search-menu {
  position: absolute;
  z-index: 2;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 3px 3px;
  top: 100%;
  max-height: 205px;
  overflow-y: auto;
}
.attr-search-box .attr-search-menu span {
  display: block;
  padding: 10px 9px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.attr-search-box .attr-search-menu span:hover {
  background-color: #f2f2f2;
}

.custom-table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 7px;
}

.paginator-section .custom-table-scrollbar {
  position: absolute;
  top: -7px;
  width: 100%;
  left: 0;
  right: 0;
}

.more-inventory {
  width: 215px;
}
.more-inventory a {
  color: #2E2E2E;
  text-transform: uppercase;
  padding: 10px;
  font-size: 12px;
  display: block;
  text-decoration: none;
  height: auto;
  line-height: inherit;
}
.more-inventory a .material-icons {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
  height: auto !important;
  background-color: transparent;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #2E2E2E;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-content {
  padding: 0;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
  background-color: #F2F2F2;
}
.more-inventory .mat-expansion-panel .mat-expansion-panel-body a {
  padding: 4px 10px;
}

.create-dispatch-menu {
  min-width: 140px !important;
  border-radius: 4px 0 4px 4px !important;
}
.create-dispatch-menu a {
  display: block;
  padding: 5px 10px;
  color: #222222;
  text-transform: uppercase;
  text-decoration: none;
}
.create-dispatch-menu a:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.dispatch-filters-dropdown {
  border-radius: 0 0 4px 4px !important;
  max-height: 185px !important;
  overflow-y: auto;
  min-width: var(--selectDropdownWidth) !important;
  max-width: var(--selectDropdownWidth) !important;
}
.dispatch-filters-dropdown .mat-menu-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dispatch-filters-dropdown .dispatch-menu-content {
  flex: 1;
  overflow-y: auto;
}
.dispatch-filters-dropdown ul {
  margin: 0;
}
.dispatch-filters-dropdown ul li {
  font-size: 12px;
  line-height: 1.2;
  padding: 8px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dispatch-filters-dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}
.dispatch-filters-dropdown ul li.selected {
  background-color: rgba(0, 150, 136, 0.2);
  font-weight: bold;
}
.dispatch-filters-dropdown .search-input {
  position: sticky;
  top: 0;
  z-index: 22;
  border-bottom: 2px solid #dddddd;
}
.dispatch-filters-dropdown .search-input input {
  border: 0;
  padding: 5px 8px;
  width: 100%;
  font-size: 12px;
  padding-right: 32px;
}
.dispatch-filters-dropdown .search-input .search-action {
  position: absolute;
  z-index: 23;
  width: auto;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0 10px;
}
.dispatch-filters-dropdown .search-input .search-action .icon {
  min-width: 12px;
  height: 12px;
  width: 12px;
  display: inline-block;
  cursor: pointer;
}
.dispatch-filters-dropdown .search-input .search-action .icon app-svg-icon {
  display: block;
}
.dispatch-filters-dropdown .search-input .search-action .icon svg {
  fill: currentColor;
}

.common-mat-dialog .mat-dialog-title {
  position: relative;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}
.common-mat-dialog .mat-dialog-title .close-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 5px;
  background-color: var(--blue);
  border: 0;
  color: #fff;
  border-radius: 50%;
  margin: auto 0;
  z-index: 2;
  cursor: pointer;
}
.common-mat-dialog .mat-dialog-title .close-button svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.product-copy-content-order {
  background: #319688;
  width: calc(100% - 70px);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.product-copy-content-order span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 115px);
  cursor: pointer;
}
.product-copy-content-order span.clear-copy {
  float: right;
  border-left: 1px white solid;
  padding-left: 10px;
  margin-left: auto;
  flex: 1 1 45px;
  max-width: 45px;
  cursor: pointer;
}
.product-copy-content-order span.copied {
  flex: 1 1 70px;
  max-width: 70px;
  cursor: pointer;
}
.product-copy-content-order span.copied i.copied {
  color: #f7c326;
  text-transform: uppercase;
}

.all-org-menu {
  min-width: 170px !important;
  border-radius: 0 0 4px 4px !important;
}
.all-org-menu ul {
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 385px;
  overflow: hidden;
  overflow-y: auto;
}
.all-org-menu ul a {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  color: black;
  gap: 8px;
  height: 100%;
  text-decoration: none;
  transition: all 0.3s;
}
.all-org-menu ul a:hover {
  background-color: #e2e2e2;
  color: black;
}
.all-org-menu ul a figure {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.all-org-menu ul a figure span {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 26px;
  text-transform: capitalize;
  height: 100%;
  background-color: #808080;
}
.all-org-menu ul a figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.all-org-menu ul a .selected-org-detail {
  flex: 1;
  overflow: hidden;
}
.all-org-menu ul a .selected-org-detail .org-name {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.all-org-menu ul a .selected-org-detail .org-type {
  margin: 0;
  font-size: 10px;
  color: #858585;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.all-org-menu ul a.selected {
  background-color: var(--blue);
  color: white;
}
.all-org-menu ul a.selected .selected-org-detail .org-type {
  color: currentColor;
}
.all-org-menu ul .create-org-button {
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  position: sticky;
  bottom: 0;
}
.all-org-menu ul .create-org-button a {
  text-transform: uppercase;
  font-weight: 500;
  position: sticky;
  bottom: 0;
}
.all-org-menu ul .create-org-button a .icon {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding: 6px;
}
.all-org-menu ul .create-org-button a .icon app-svg-icon {
  display: block;
}

@media screen and (min-width: 1280px) {
  .right-container.with-filter {
    margin-left: 267px;
  }
}
.edit-variation-modal .edit-variation-form .product-name {
  font-size: 20px;
  font-weight: 500 !important;
  margin: 0;
}
.edit-variation-modal .edit-variation-form .product-sku {
  font-size: 13px;
  margin: 0;
  line-height: 1.5;
}
.edit-variation-modal .edit-variation-form ul {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0 10px 0;
}
.edit-variation-modal .edit-variation-form ul li {
  background-color: #eeeeee;
  padding: 3px 5px;
  line-height: 1;
  font-size: 12px;
  border-radius: 3px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.profile-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  display: block !important;
  position: relative !important;
  border-radius: 2px !important;
  padding: 0px !important;
  width: 280px;
}

.profile-card-header {
  display: flex;
  align-items: center;
  background-size: cover;
  padding: 1rem 1rem;
  background-color: #319688;
  overflow-wrap: anywhere;
}
.profile-card-header .profile-avatar {
  float: left;
  background: white;
  border-radius: 100%;
  border: solid 2px #dad7d7;
}
.profile-card-header .profile-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.profile-card-header .proifle-title {
  float: left;
  flex-grow: 1;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  padding-left: 1rem;
}
.profile-card-header .user-phone {
  font-size: 13px;
  color: whitesmoke;
}

.profile-content .pro-menu {
  align-items: center;
  border-bottom: 1px solid #f7f8fa;
  font-size: 16px !important;
  transition: background-color 0.3s ease;
}
.profile-content .material-icons {
  font-size: 16px;
  color: #319688;
  display: block;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}
.profile-content .profile-list {
  list-style: none;
  padding-left: 0;
}

.header-profile-dropdown .mat-menu-item {
  font-family: inherit !important;
  line-height: none !important;
  height: auto !important;
  border-bottom: solid 1px #eaeaea;
}
.header-profile-dropdown .mat-menu-item .icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  color: #319688;
  line-height: 1;
  margin-right: 10px;
}
.header-profile-dropdown .mat-menu-item .icon svg {
  fill: currentColor;
  stroke: currentColor;
}

img {
  user-drag: none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.ox-tab-nav {
  background-color: #efefef;
}
.ox-tab-nav a {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  width: 200px !important;
}
.ox-tab-nav a:hover {
  text-decoration: none;
}

.pac-container {
  z-index: 9999;
}

#barcode-scanning-section {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.retailer-group-list .selected-retailer {
  background-color: rgba(0, 150, 136, 0.1490196078) !important;
}

.challan-update-party h3,
.challan-update-party p {
  text-align: left !important;
}
.challan-update-party .actions-button {
  justify-content: flex-end !important;
}

.croppie-container {
  width: 100%;
  height: 100%;
}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 0 2000px 2000px rgba(105, 105, 105, 0.5);
  box-shadow: 0 0 2000px 2000px rgba(105, 105, 105, 0.5);
  z-index: 0;
}

.croppie-container .cr-resizer {
  z-index: 2;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: "";
}

.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px;
}

.croppie-container .cr-original-image {
  display: none;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  -ms-touch-action: none;
  touch-action: none;
}

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */ /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent; /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: red;
  margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}

/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.cr-rotate-controls button {
  border: 0;
  background: none;
}

.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}

.cr-rotate-l i:before {
  content: "↺";
}

.cr-rotate-r i:before {
  content: "↻";
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.left-sidebar > .sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar > .sidebar,
.right-sidebar > .sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  position: relative !important;
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  box-shadow: none;
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1000;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.left-sidebar > .sidebar .header,
.right-sidebar > .sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.left-sidebar > .sidebar .content,
.right-sidebar > .sidebar .content {
  background: #ffffff;
  flex: 1 1 auto;
}

/* listing display section */
.left-sidebar > .sidebar.locked-open.left-positioned + .center,
.right-sidebar > .sidebar.locked-open.left-positioned + .center {
  margin-left: 0;
}

.left-sidebar > .sidebar.locked-open + .center,
.right-sidebar > .sidebar.locked-open + .center {
  z-index: 1001;
}

.left-sidebar.inner-scroll > .center,
.left-sidebar.inner-scroll > .center > .content-card,
.right-sidebar.inner-scroll > .center,
.right-sidebar.inner-scroll > .center > .content-card {
  flex: 1 1 auto;
}

.left-sidebar > .center,
.right-sidebar > .center {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
}

.left-sidebar > .center > .header,
.right-sidebar > .center > .header {
  display: -webkit-box;
  display: flex;
  height: 136px;
  min-height: 136px;
  max-height: 136px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* Material Design Lite */ /**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
html, body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/**
* Styles for HTML elements
*/
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
}

h1 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px;
}

h2 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h3 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h4 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px;
}

h5 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
}

h6 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px;
}

a {
  color: #009688;
  font-weight: 500;
}

blockquote {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em;
}
blockquote:before {
  position: absolute;
  left: -0.5em;
  content: "“";
}
blockquote:after {
  content: "”";
  margin-left: -0.05em;
}

mark {
  background-color: #f4ff81;
}

dt {
  font-weight: 700;
}

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal;
}

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
}

.mdl-typography--display-4-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54;
}

.mdl-typography--display-3 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
}

.mdl-typography--display-3-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
}

.mdl-typography--display-2 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
}

.mdl-typography--display-2-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54;
}

.mdl-typography--display-1 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.mdl-typography--display-1-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54;
}

.mdl-typography--headline {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
}

.mdl-typography--headline-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87;
}

.mdl-typography--title {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}

.mdl-typography--title-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87;
}

.mdl-typography--subhead {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.mdl-typography--subhead-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87;
}

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-2-force-preferred-font {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1-force-preferred-font {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-force-preferred-font {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--menu {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--menu-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--button-color-contrast {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--text-left {
  text-align: left;
}

.mdl-typography--text-right {
  text-align: right;
}

.mdl-typography--text-center {
  text-align: center;
}

.mdl-typography--text-justify {
  text-align: justify;
}

.mdl-typography--text-nowrap {
  white-space: nowrap;
}

.mdl-typography--text-lowercase {
  text-transform: lowercase;
}

.mdl-typography--text-uppercase {
  text-transform: uppercase;
}

.mdl-typography--text-capitalize {
  text-transform: capitalize;
}

.mdl-typography--font-thin {
  font-weight: 200 !important;
}

.mdl-typography--font-light {
  font-weight: 300 !important;
}

.mdl-typography--font-regular {
  font-weight: 400 !important;
}

.mdl-typography--font-medium {
  font-weight: 500 !important;
}

.mdl-typography--font-bold {
  font-weight: 700 !important;
}

.mdl-typography--font-black {
  font-weight: 900 !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}
.mdl-badge:not([data-badge]) {
  margin-right: auto;
}
.mdl-badge[data-badge]:after {
  content: attr(data-badge);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 75%;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #00bcd4;
  color: #333;
}
.mdl-button .mdl-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}
.mdl-badge.mdl-badge--no-background[data-badge]:after {
  color: #00bcd4;
  background: #999;
  box-shadow: 0 0 1px gray;
}
.mdl-badge.mdl-badge--overlap {
  margin-right: 10px;
}
.mdl-badge.mdl-badge--overlap:after {
  right: -10px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: rgb(#66bb6a);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
}
.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0, 0.54);
  margin: 0;
}

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%;
}
.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 8px;
  box-sizing: border-box;
}
.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.mdl-card--expand {
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255);
}
.mdl-data-table thead {
  padding-bottom: 3px;
}
.mdl-data-table thead .mdl-data-table__select {
  margin-top: 0;
}
.mdl-data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}
.mdl-data-table tbody tr.is-selected {
  background-color: #e0e0e0;
}
.mdl-data-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.mdl-data-table td, .mdl-data-table th {
  padding: 0 18px 12px 18px;
  text-align: right;
}
.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
  padding-left: 24px;
}
.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
  padding-right: 24px;
}
.mdl-data-table td {
  position: relative;
  vertical-align: middle;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  box-sizing: border-box;
}
.mdl-data-table td .mdl-data-table__select {
  vertical-align: middle;
}
.mdl-data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending, .mdl-data-table th.mdl-data-table__header--sorted-descending {
  color: rgba(0, 0, 0, 0.87);
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  content: "\e5d8";
  margin-right: 5px;
  vertical-align: sub;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
  cursor: pointer;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
  color: rgba(0, 0, 0, 0.26);
}
.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  content: "\e5db";
}

.mdl-data-table__select {
  width: 16px;
}

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-shadow--2dp, .card, .card-panel {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

address {
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

.mat-dialog-content {
  max-height: 100% !important;
}

.divider {
  display: block;
  border: 0;
  border-top: 1px solid transparent;
  margin-top: 10px;
  margin-bottom: 10px;
}
.divider.divider-solid {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
}
.divider.divider-dashed {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.1);
}
.divider.divider-dotted {
  border-style: dotted;
  border-color: rgba(0, 0, 0, 0.1);
}

.divider-xs {
  margin-top: 3px;
  margin-bottom: 3px;
}

.divider-sm {
  margin-top: 5px;
  margin-bottom: 5px;
}

.divider-md {
  margin-top: 15px;
  margin-bottom: 15px;
}

.divider-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider-xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.divider-xxl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.space {
  display: inline;
  padding: 6px;
}

.space-md {
  padding: 15px;
}

.space-lg {
  padding: 25px;
}

span.block {
  display: block;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-shadow {
  box-shadow: 0;
}

.full-width {
  width: 100%;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.margin-b-lg {
  margin-bottom: 30px;
}

.article-title,
.article-title-style {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: 0.6em 0;
  color: #009688;
}
.article-title.color-dark,
.article-title-style.color-dark {
  color: rgba(0, 0, 0, 0.87);
}

.chapter .article {
  padding-bottom: 30px;
}
.chapter .article:nth-of-type(1) .article-title {
  margin: 0 0 30px;
  border-top: none;
}
.chapter .article.article-dark {
  background-color: rgba(0, 0, 0, 0.015);
}
.chapter .article.article-bordered {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
}
.chapter .article.article-bordered:last-child {
  border-bottom: 0;
}
.chapter .article.padding-lg-v {
  padding: 75px 0;
}
.chapter .article-title {
  font-size: 24px;
  padding-top: 80px;
  font-weight: normal;
  line-height: 32px;
  margin: 48px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  color: #009688;
}

.hero {
  padding: 70px 0 75px;
  text-align: center;
}

.hero-title {
  -webkit-font-smoothing: antialiased;
}

.hero-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 45px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 10px;
}

.hero-tagline {
  margin: 10px auto 30px;
  max-width: 700px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.theme-gray .hero-title,
.theme-gray .hero-tagline,
.theme-dark .hero-title,
.theme-dark .hero-tagline {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .article-title.color-dark,
.theme-dark .article-title.color-dark {
  color: rgba(255, 255, 255, 0.7);
}

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.container-fluid.padding-lg-h {
  padding-left: 30px;
  padding-right: 30px;
}
.container-fluid.with-maxwidth {
  max-width: 1200px;
}
.container-fluid.with-maxwidth-lg {
  max-width: 960px;
}
.container-fluid.with-maxwidth-md {
  max-width: 960px;
}
.container-fluid.with-maxwidth-sm {
  max-width: 720px;
}
.container-fluid.no-breadcrumbs {
  padding-top: 2em;
  padding-bottom: 1em;
}

.gradient-text {
  font-size: 150px;
  font-weight: 300;
  color: #009688;
  background: -webkit-linear-gradient(92deg, #fb83fa, #00aced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-small {
  font-size: 12px;
}

.text-normal {
  font-size: 1rem;
}

.text-huge {
  font-size: 72px;
}

.text-large {
  font-size: 50px;
}

.text-thin {
  font-weight: 300;
}

.text-ultralight {
  font-weight: 100;
}

.bold {
  font-weight: 500;
}

.color-primary,
a.color-primary {
  color: #009688;
}
.color-primary:hover,
a.color-primary:hover {
  color: #009688;
}

.color-success,
a.color-success {
  color: #66bb6a;
}
.color-success:hover,
a.color-success:hover {
  color: #66bb6a;
}

.color-info,
a.color-info {
  color: #00bcd4;
}
.color-info:hover,
a.color-info:hover {
  color: #00bcd4;
}

.color-warning,
a.color-warning {
  color: #ffc107;
}
.color-warning:hover,
a.color-warning:hover {
  color: #ffc107;
}

.color-danger,
a.color-danger {
  color: #ff0000;
}
.color-danger:hover,
a.color-danger:hover {
  color: #ff0000;
}

.color-dark,
a.color-dark {
  color: #343a40;
}
.color-dark:hover,
a.color-dark:hover {
  color: #343a40;
}

.color-white,
a.color-white {
  color: #fff;
}
.color-white:hover,
a.color-white:hover {
  color: #fff;
}

.color-gray-darker,
a.color-gray-darker {
  color: #e9ecef;
}
.color-gray-darker:hover,
a.color-gray-darker:hover {
  color: #e9ecef;
}

.color-gray-dark,
a.color-gray-dark {
  color: #343a40;
}
.color-gray-dark:hover,
a.color-gray-dark:hover {
  color: #343a40;
}

.color-gray,
a.color-gray {
  color: #495057;
}
.color-gray:hover,
a.color-gray:hover {
  color: #495057;
}

.color-gray-light,
a.color-gray-light {
  color: #636c72;
}
.color-gray-light:hover,
a.color-gray-light:hover {
  color: #636c72;
}

.color-gray-lighter,
a.color-gray-lighter {
  color: #e9ecef;
}
.color-gray-lighter:hover,
a.color-gray-lighter:hover {
  color: #e9ecef;
}

.dropcap,
.dropcap-square,
.dropcap-circle {
  display: block;
  float: left;
  font-weight: normal;
  line-height: 36px;
  margin-right: 6px;
  text-shadow: none;
}

.dropcap {
  font-size: 3.1em;
}

.dropcap-square,
.dropcap-circle {
  background-color: #e9ecef;
  color: rgba(0, 0, 0, 0.87);
  width: 36px;
  text-align: center;
}

.dropcap-square {
  border-radius: 0.2rem;
  font-size: 2.3em;
}

.dropcap-circle {
  border-radius: 50%;
  font-size: 1.78em;
}

.dropcap.colored {
  color: #009688;
}

.dropcap-square.colored,
.dropcap-circle.colored {
  background-color: #009688;
  color: #fff;
}

.ui-highlight {
  background-color: #343a40;
  color: #fff;
  border-radius: 0.2rem;
  padding: 2px 5px;
}
.ui-highlight.colored {
  background-color: #009688;
}

a.btn-w-xs,
button.btn-w-xs {
  min-width: 80px;
}
a.btn-w-sm,
button.btn-w-sm {
  min-width: 100px;
}
a.btn-w-md,
button.btn-w-md {
  min-width: 135px;
}
a.btn-w-lg,
button.btn-w-lg {
  min-width: 160px;
}
a.btn-round,
button.btn-round {
  border-radius: 2em;
}
a.btn-gap,
button.btn-gap {
  margin: 5px;
}
a.btn-gap-h,
button.btn-gap-h {
  margin: 0 5px;
}
a.btn-gap-v,
button.btn-gap-v {
  margin: 0 0 5px;
}

.btn-icon {
  padding: 0;
  display: inline-block;
  text-align: center;
  border-radius: 0.2rem;
  height: 35px;
  width: 35px;
  line-height: 35px;
}
.btn-icon i {
  line-height: 35px;
}
.btn-icon.btn-icon-lined {
  line-height: 31px;
}
.btn-icon.btn-icon-lined i {
  line-height: 31px;
}
.btn-icon.btn-icon-lined.btn-icon-thin {
  line-height: 33px;
}
.btn-icon.btn-icon-lined.btn-icon-thin i {
  line-height: 33px;
}

.btn-icon-round {
  border-radius: 50%;
}

.btn-icon-sm {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.btn-icon-sm i {
  line-height: 30px;
}
.btn-icon-sm.btn-icon-lined {
  line-height: 26px;
}
.btn-icon-sm.btn-icon-lined i {
  line-height: 26px;
}
.btn-icon-sm.btn-icon-lined.btn-icon-thin {
  line-height: 28px;
}
.btn-icon-sm.btn-icon-lined.btn-icon-thin i {
  line-height: 28px;
}

.btn-icon-md {
  height: 45px;
  width: 45px;
  line-height: 45px;
  font-size: 18px;
}
.btn-icon-md i {
  line-height: 45px;
}
.btn-icon-md.btn-icon-lined {
  line-height: 41px;
}
.btn-icon-md.btn-icon-lined i {
  line-height: 41px;
}
.btn-icon-md.btn-icon-lined.btn-icon-thin {
  line-height: 43px;
}
.btn-icon-md.btn-icon-lined.btn-icon-thin i {
  line-height: 43px;
}

.btn-icon-lg {
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 28px;
}
.btn-icon-lg i {
  line-height: 65px;
}
.btn-icon-lg.btn-icon-lined {
  line-height: 61px;
}
.btn-icon-lg.btn-icon-lined i {
  line-height: 61px;
}
.btn-icon-lg.btn-icon-lined.btn-icon-thin {
  line-height: 63px;
}
.btn-icon-lg.btn-icon-lined.btn-icon-thin i {
  line-height: 63px;
}

.btn-icon-lg-alt {
  height: 70px;
  width: 70px;
  line-height: 70px;
}
.btn-icon-lg-alt i {
  line-height: 70px;
}
.btn-icon-lg-alt.btn-icon-lined {
  line-height: 66px;
}
.btn-icon-lg-alt.btn-icon-lined i {
  line-height: 66px;
}
.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin {
  line-height: 68px;
}
.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin i {
  line-height: 68px;
}

.btn-icon-xl {
  height: 80px;
  width: 80px;
  line-height: 80px;
}
.btn-icon-xl i {
  line-height: 80px;
}
.btn-icon-xl.btn-icon-lined {
  line-height: 76px;
}
.btn-icon-xl.btn-icon-lined i {
  line-height: 76px;
}
.btn-icon-xl.btn-icon-lined.btn-icon-thin {
  line-height: 78px;
}
.btn-icon-xl.btn-icon-lined.btn-icon-thin i {
  line-height: 78px;
}

.btn-twitter {
  color: #fff;
  background-color: #00c7f7;
  border-color: #00c7f7;
}
.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  color: #fff;
  text-decoration: none;
  background-color: #00a6ce;
  border-color: #0096ba;
}

.btn-facebook {
  color: #fff;
  background-color: #335397;
  border-color: #335397;
}
.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  color: #fff;
  text-decoration: none;
  background-color: #294279;
  border-color: #243a69;
}

.btn-gplus,
.btn-google-plus {
  color: #fff;
  background-color: #dd4a38;
  border-color: #dd4a38;
}
.btn-gplus:hover, .btn-gplus:focus, .btn-gplus:active,
.btn-google-plus:hover,
.btn-google-plus:focus,
.btn-google-plus:active {
  color: #fff;
  text-decoration: none;
  background-color: #ca3522;
  border-color: #b8301f;
}

.btn-instagram {
  color: #fff;
  background-color: #82685A;
  border-color: #82685A;
}
.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  color: #fff;
  text-decoration: none;
  background-color: #6a5549;
  border-color: #5e4b41;
}

.btn-vimeo {
  color: #fff;
  background-color: #63879C;
  border-color: #63879C;
}
.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  color: #fff;
  text-decoration: none;
  background-color: #537183;
  border-color: #4b6777;
}

.btn-flickr {
  color: #fff;
  background-color: #0061DB;
  border-color: #0061DB;
}
.btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
  color: #fff;
  text-decoration: none;
  background-color: #004fb2;
  border-color: #00469e;
}

.btn-github {
  color: #fff;
  background-color: #3B3B3B;
  border-color: #3B3B3B;
}
.btn-github:hover, .btn-github:focus, .btn-github:active {
  color: #fff;
  text-decoration: none;
  background-color: #272727;
  border-color: #1c1c1c;
}

.btn-pinterest {
  color: #fff;
  background-color: #D73532;
  border-color: #D73532;
}
.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  color: #fff;
  text-decoration: none;
  background-color: #bc2725;
  border-color: #ab2421;
}

.btn-tumblr {
  color: #fff;
  background-color: #586980;
  border-color: #586980;
}
.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  color: #fff;
  text-decoration: none;
  background-color: #475568;
  border-color: #3f4b5c;
}

.btn-linkedin {
  color: #fff;
  background-color: #018FAF;
  border-color: #018FAF;
}
.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  color: #fff;
  text-decoration: none;
  background-color: #016e86;
  border-color: #015d72;
}

.btn-dribbble {
  color: #fff;
  background-color: #EA73A0;
  border-color: #EA73A0;
}
.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  color: #fff;
  text-decoration: none;
  background-color: #e55088;
  border-color: #e23e7c;
}

.btn-stumbleupon {
  color: #fff;
  background-color: #EA4B24;
  border-color: #EA4B24;
}
.btn-stumbleupon:hover, .btn-stumbleupon:focus, .btn-stumbleupon:active {
  color: #fff;
  text-decoration: none;
  background-color: #d13914;
  border-color: #bf3412;
}

.btn-lastfm {
  color: #fff;
  background-color: #B80638;
  border-color: #B80638;
}
.btn-lastfm:hover, .btn-lastfm:focus, .btn-lastfm:active {
  color: #fff;
  text-decoration: none;
  background-color: #90052c;
  border-color: #7d0426;
}

.btn-evernote {
  color: #fff;
  background-color: #3BAB27;
  border-color: #3BAB27;
}
.btn-evernote:hover, .btn-evernote:focus, .btn-evernote:active {
  color: #fff;
  text-decoration: none;
  background-color: #308a1f;
  border-color: #2a791c;
}

.btn-skype {
  color: #fff;
  background-color: #00B0F6;
  border-color: #00B0F6;
}
.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  color: #fff;
  text-decoration: none;
  background-color: #0093cd;
  border-color: #0084b9;
}

.btn-soundcloud {
  color: #fff;
  background-color: #0066FF;
  border-color: #0066FF;
}
.btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
  color: #fff;
  text-decoration: none;
  background-color: #0056d6;
  border-color: #004ec2;
}

.btn-behance {
  color: #fff;
  background-color: #B80638;
  border-color: #B80638;
}
.btn-behance:hover, .btn-behance:focus, .btn-behance:active {
  color: #fff;
  text-decoration: none;
  background-color: #90052c;
  border-color: #7d0426;
}

.btn-rss {
  color: #fff;
  background-color: #F79638;
  border-color: #F79638;
}
.btn-rss:hover, .btn-rss:focus, .btn-rss:active {
  color: #fff;
  text-decoration: none;
  background-color: #f58111;
  border-color: #e87709;
}

.btn-youtube {
  color: #fff;
  background-color: #CC181E;
  border-color: #CC181E;
}
.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  color: #fff;
  text-decoration: none;
  background-color: #a71419;
  border-color: #951216;
}

.theme-gray .btn-default,
.theme-gray .btn-secondary,
.theme-dark .btn-default,
.theme-dark .btn-secondary {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 800px) {
  .table-flip-scroll {
    /* sort out borders */
  }
  .table-flip-scroll .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .table-flip-scroll * html .cf {
    zoom: 1;
  }
  .table-flip-scroll *:first-child + html .cf {
    zoom: 1;
  }
  .table-flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .table-flip-scroll th,
.table-flip-scroll td {
    margin: 0;
    vertical-align: top;
  }
  .table-flip-scroll th {
    text-align: left;
  }
  .table-flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }
  .table-flip-scroll thead {
    display: block;
    float: left;
  }
  .table-flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-flip-scroll thead tr {
    display: block;
  }
  .table-flip-scroll .table > thead > tr > th:first-child {
    border-top: 1px solid #ddd;
  }
  .table-flip-scroll th {
    display: block;
    text-align: right;
  }
  .table-flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .table-flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  .table-flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }
  .table-flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .table-flip-scroll tbody tr {
    border-left: 1px solid #babcbf;
  }
  .table-flip-scroll th:last-child,
.table-flip-scroll td:last-child {
    border-bottom: 1px solid #babcbf;
  }
  .table-flip-scroll .mdl-data-table tbody tr {
    height: auto;
  }
}
.theme-gray .form-control,
.theme-gray .input-group-addon,
.theme-dark .form-control,
.theme-dark .input-group-addon {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .help-block,
.theme-dark .help-block {
  color: #636c72;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.input-round {
  border-radius: 2em;
}

input.input-primary {
  border-color: #009688;
}
input.input-primary:focus {
  border-color: #009688;
}

input.input-info {
  border-color: #00bcd4;
}
input.input-info:focus {
  border-color: #00bcd4;
}

input.input-success {
  border-color: #66bb6a;
}
input.input-success:focus {
  border-color: #66bb6a;
}

input.input-warning {
  border-color: #ffc107;
}
input.input-warning:focus {
  border-color: #ffc107;
}

input.input-danger {
  border-color: #ff0000;
}
input.input-danger:focus {
  border-color: #ff0000;
}

.box {
  position: relative;
  margin-bottom: 1.5rem;
  border: 0;
  border-radius: 0.2rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}
.box.box-transparent {
  box-shadow: none;
}
.box .box-dark {
  background-color: rgba(0, 0, 0, 0.03);
}

.box-default {
  background-color: #fff;
}

.box-header,
.box-heading {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid transparent;
  border-radius: -0.8rem -0.8rem 0 0;
  font-weight: 500;
}

.box-toolbar {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}
.box-toolbar a {
  color: #868e96;
}
.box-toolbar a .material-icons {
  font-size: 1rem;
}

.box-divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 0 1.25rem;
}

.box-body {
  padding: 1.25rem;
}
.box-body::after {
  display: block;
  clear: both;
  content: "";
}
.box-body.padding-lg {
  padding: 30px 15px;
}
.box-body.padding-xl {
  padding: 60px 15px;
}
.box-body.padding-lg-h {
  padding: 15px 30px;
}
.box-body.padding-lg-v {
  padding: 30px 15px;
}

.theme-gray .box-default {
  background-color: #4c4c4c;
}

.theme-dark .box-default {
  background-color: #424951;
}

.breadcrumb {
  display: block;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 0;
  text-transform: uppercase;
  color: #ccc;
  text-shadow: none;
  font-size: 11px;
}
.breadcrumb a {
  color: #ccc;
  text-decoration: none;
}
.breadcrumb a:hover {
  color: #009688;
}
.breadcrumb li {
  padding-left: 0;
}

.call-to-action {
  position: relative;
  text-align: center;
}
.call-to-action .cta-inner {
  padding: 2em 0;
}
.call-to-action .cta-text {
  font-size: 24px;
  line-height: 1.5;
}
.call-to-action .cta-btn {
  margin: 30px 0 10px;
}
.call-to-action .cta-muted {
  opacity: 0.7;
}
.call-to-action.cta-inline .cta-text {
  font-size: 20px;
  font-weight: 300;
}
.call-to-action.cta-inline .cta-btn {
  display: inline-block;
  margin: 0 0 0 30px;
}
.call-to-action.cta-full-width .cta-inner {
  padding: 6em 1em;
}
.call-to-action.cta-bg-img {
  background-size: cover;
  background-position: center;
  color: rgba(255, 255, 255, 0.8);
}
.call-to-action.cta-bg-img .cta-inner {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 7em 1em;
}
@media (max-width: 425px) {
  .call-to-action .cta-text {
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .call-to-action .cta-text {
    font-size: 18px;
  }
}

.callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}
.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.25rem;
  line-height: 1.5;
}
.callout p:last-child {
  margin-bottom: 0;
}

.callout-success {
  background-color: rgba(102, 187, 106, 0.08);
  border-color: #66bb6a;
}
.callout-success h4 {
  color: #66bb6a;
}

.callout-info {
  background-color: rgba(0, 188, 212, 0.05);
  border-color: #00bcd4;
}
.callout-info h4 {
  color: #00bcd4;
}

.callout-warning {
  background-color: rgba(255, 193, 7, 0.08);
  border-color: #ffc107;
}
.callout-warning h4 {
  color: #ffc107;
}

.callout-danger {
  background-color: rgba(255, 0, 0, 0.05);
  border-color: #ff0000;
}
.callout-danger h4 {
  color: #ff0000;
}

.card-white {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
}

a.item-card {
  display: block;
  text-decoration: none;
}
a.item-card:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.item-card {
  position: relative;
  transition: 0.2s linear;
}
.item-card img {
  max-width: 100%;
  max-height: 100%;
}
.item-card .card__image {
  padding: 30px 15px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.035);
  text-align: center;
}
.item-card h1, .item-card h2, .item-card h3, .item-card h4, .item-card h5, .item-card h6 {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
}
.item-card h6 {
  font-size: 10px;
  opacity: 0.5;
}
.item-card h4 {
  font-size: 16px;
}
.item-card .card__body {
  position: relative;
  padding: 30px 15px;
  margin-bottom: 30px;
}
.item-card .card__price {
  position: absolute;
  top: 30px;
  right: 15px;
}
.item-card .card__price span {
  display: block;
  color: #636c72;
}
.item-card .card__price span.type--strikethrough {
  opacity: 0.5;
  text-decoration: line-through;
}
.item-card.card__horizontal {
  position: relative;
  margin-bottom: 30px;
}
.item-card.card__horizontal .card__image {
  width: 50%;
}
.item-card.card__horizontal .card__body {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 40px 30px;
}
.item-card.card__horizontal .card__price {
  top: 30px;
  right: 30px;
}
.item-card.card__horizontal .card__desc {
  margin: 0 25% 3em 0;
  opacity: 0.7;
}

.theme-gray .card-white,
.theme-dark .card-white {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .card-white h1, .theme-gray .card-white h2, .theme-gray .card-white h3, .theme-gray .card-white h4, .theme-gray .card-white h5, .theme-gray .card-white h6,
.theme-dark .card-white h1,
.theme-dark .card-white h2,
.theme-dark .card-white h3,
.theme-dark .card-white h4,
.theme-dark .card-white h5,
.theme-dark .card-white h6 {
  color: rgba(255, 255, 255, 0.7);
}

.theme-gray .card-white {
  background-color: #4c4c4c;
}

.theme-dark .card-white {
  background-color: #424951;
}

.feature-callout {
  position: relative;
  clear: both;
}
@media (min-width: 768px) {
  .feature-callout.feature-content-left .callout-feature-content {
    padding-right: 2em;
  }
}
@media (min-width: 1200px) {
  .feature-callout.feature-content-left .callout-feature-content {
    padding-right: 3em;
  }
}
@media (min-width: 768px) {
  .feature-callout.feature-content-right .callout-feature-content {
    padding-left: 2em;
  }
}
@media (min-width: 1200px) {
  .feature-callout.feature-content-right .callout-feature-content {
    padding-left: 3em;
  }
}
.feature-callout.feature-content-center .callout-feature-content {
  text-align: center;
  max-width: 75%;
  margin: 0 auto;
}
.feature-callout .md-button {
  padding: 5px 0;
  min-width: 150px;
}
@media (min-width: 768px) {
  .feature-callout .offset-md-6 {
    margin-left: 50%;
  }
}

.feature-callout-cover,
.feature-callout-image-pull {
  background-size: cover;
  background-position: center;
}

.feature-callout-image-pull {
  min-height: 200px;
}
@media (min-width: 768px) {
  .feature-callout-image-pull {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.callout-feature-content {
  padding: 3em 0;
}
@media (min-width: 768px) {
  .callout-feature-content {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}
@media (min-width: 992px) {
  .callout-feature-content {
    padding-top: 8em;
    padding-bottom: 8em;
  }
}
@media (min-width: 1200px) {
  .callout-feature-content {
    padding-top: 12em;
    padding-bottom: 12em;
  }
}
.callout-feature-content h2 {
  font-size: 24px;
  margin-top: 0;
}
@media (min-width: 768px) {
  .callout-feature-content h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.9;
    margin: 0;
  }
}
.callout-feature-content p {
  line-height: 1.75;
  hyphens: none;
}
.callout-feature-content p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .callout-feature-content p {
    line-height: 2;
    margin: 1.5em 0;
  }
}

.feature-callout-cover.has-overlay {
  color: #fff;
}
.feature-callout-cover.has-overlay:after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.feature-callout-cover .with-maxwidth {
  position: relative;
  z-index: 2;
}
.feature-callout-cover h2 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .feature-callout-cover h2 {
    font-size: 48px;
  }
}

.ih-item {
  position: relative;
  border-radius: 0.2rem;
  transition: all 0.35s ease-in-out;
}
.ih-item a {
  color: #333;
}
.ih-item a:hover {
  text-decoration: none;
}
.ih-item img {
  border-radius: 0.2rem;
  width: 100%;
  height: 100%;
}

.ih-item.square {
  position: relative;
  margin-bottom: 30px;
}
.ih-item.square .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  backface-visibility: hidden;
}

.ih-item.ih-material {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.ih-item.ih-material .info-mask {
  content: "";
  position: absolute;
  top: -25%;
  left: 0;
  right: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.ih-item.ih-material .info-content {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  transform: scale(0.5);
  transition: all 0.3s ease;
}
.ih-item.ih-material .info-content:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-left: -0.5em;
}
.ih-item.ih-material .info-content .info-inner {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}
.ih-item.ih-material .info-content .info-inner h3 {
  margin: 0 0 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  line-height: 1;
}
.ih-item.ih-material .info-content .info-inner p {
  font-style: italic;
  font-size: 12px;
  color: #e9ecef;
}
.ih-item.ih-material .bg-color-white + .info-content .info-inner h3,
.ih-item.ih-material .bg-color-warning + .info-content .info-inner h3 {
  color: rgba(0, 0, 0, 0.87);
}
.ih-item.ih-material .bg-color-white + .info-content .info-inner p,
.ih-item.ih-material .bg-color-warning + .info-content .info-inner p {
  color: #999;
}
.ih-item.ih-material a:hover .info-content {
  opacity: 1;
  transform: scale(1);
}
.ih-item.ih-material a:hover .info-mask {
  opacity: 0.8;
  transform: scale(1.21);
}

.ih-item.square.effect3 {
  overflow: hidden;
}
.ih-item.square.effect3 .info {
  height: 65px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease-in-out;
}
.ih-item.square.effect3 .info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  line-height: 1;
  padding: 10px 10px 0 4px;
  margin: 4px 0 0 0;
}
.ih-item.square.effect3 .info p {
  margin: 0;
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  text-align: center;
}
.ih-item.square.effect3 .info.bg-color-primary {
  background-color: rgba(0, 150, 136, 0.8);
}
.ih-item.square.effect3 .info.bg-color-info {
  background-color: rgba(0, 188, 212, 0.8);
}
.ih-item.square.effect3 a:hover .info {
  visibility: visible;
  opacity: 1;
}

.ih-item.square.effect3.bottom_to_top .info {
  top: auto;
  transform: translateY(100%);
}
.ih-item.square.effect3.bottom_to_top a:hover .info {
  transform: translateY(0);
}

.ih-item.square.effect3.top_to_bottom .info {
  bottom: auto;
  transform: translateY(-100%);
}
.ih-item.square.effect3.top_to_bottom a:hover .info {
  transform: translateY(0);
}

.icon-box {
  margin-top: 30px;
  position: relative;
  padding: 0 0 0 80px;
}
.icon-box .ibox-icon {
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
}
.icon-box .ibox-icon a {
  text-decoration: none;
}
.icon-box .ibox-icon a,
.icon-box .ibox-icon i,
.icon-box .ibox-icon img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: #FFF;
}
.icon-box .ibox-icon i,
.icon-box .ibox-icon img {
  border-radius: 50%;
  background-color: #009688;
}
.icon-box .ibox-icon i {
  font-style: normal;
  font-size: 28px;
  text-align: center;
  line-height: 64px;
}
.icon-box h3 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}
.icon-box p {
  margin: 8px 0 0 0;
  color: #999;
}
.icon-box .before-heading {
  font-size: 14px;
}

/* Icon Box - Icon Large
-----------------------------------------------------------------*/
.icon-box.ibox-lg {
  padding: 0 0 0 115px;
}
.icon-box.ibox-lg .ibox-icon {
  width: 96px;
  height: 96px;
}
.icon-box.ibox-lg .ibox-icon i {
  font-size: 42px;
  line-height: 96px;
}

/* Icon Box - Icon Outline
-----------------------------------------------------------------*/
.icon-box.ibox-outline .ibox-icon {
  border: 1px solid #009688;
  border-radius: 50%;
  padding: 3px;
}
.icon-box.ibox-outline .ibox-icon i {
  line-height: 56px;
}

/* Icon Box - Icon Outline Large
-----------------------------------------------------------------*/
.icon-box.ibox-outline.ibox-lg .ibox-icon {
  padding: 4px;
}
.icon-box.ibox-outline.ibox-lg .ibox-icon i {
  line-height: 86px;
}

/* Icon Box - Icon Rounded
-----------------------------------------------------------------*/
.icon-box.ibox-rounded .ibox-icon {
  border-radius: 0.2rem !important;
}
.icon-box.ibox-rounded .ibox-icon i,
.icon-box.ibox-rounded .ibox-icon img {
  border-radius: 0.2rem !important;
}

/* Icon Box - Icon Rounded & Large
-----------------------------------------------------------------*/
.icon-box.ibox-rounded.ibox-lg .ibox-icon {
  border-radius: 0.2rem !important;
}
.icon-box.ibox-rounded.ibox-lg .ibox-icon i,
.icon-box.ibox-rounded.ibox-lg .ibox-icon img {
  border-radius: 0.2rem !important;
}

/* Icon Box - Light Background
-----------------------------------------------------------------*/
.icon-box.ibox-light i,
.icon-box.ibox-light img {
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87);
}
.icon-box.ibox-light.ibox-outline .ibox-icon {
  border-color: #E5E5E5;
}
.icon-box.ibox-light.ibox-outline .ibox-icon i {
  line-height: 54px;
}
.icon-box.ibox-light.ibox-outline i,
.icon-box.ibox-light.ibox-outline img {
  border: 1px solid #E5E5E5;
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87);
}
.icon-box.ibox-light.ibox-outline.ibox-lg .ibox-icon i {
  line-height: 84px;
}
.icon-box.ibox-light .ibox-icon i {
  line-height: 62px;
}
.icon-box.ibox-lg .ibox-icon i {
  line-height: 96px;
}

/* Icon Box - Dark Background
-----------------------------------------------------------------*/
.icon-box.ibox-dark.ibox-outline .ibox-icon {
  border-color: rgba(0, 0, 0, 0.87);
}
.icon-box.ibox-dark .ibox-icon i,
.icon-box.ibox-dark .ibox-icon img {
  background-color: rgba(0, 0, 0, 0.87);
}

/* Icon Box - Border
-----------------------------------------------------------------*/
.icon-box.ibox-border .ibox-icon {
  border: 1px solid #009688;
  border-radius: 50%;
}
.icon-box.ibox-border .ibox-icon i,
.icon-box.ibox-border .ibox-icon img {
  border: none;
  background-color: transparent !important;
  color: #009688;
}
.icon-box.ibox-border .ibox-icon {
  padding: 0;
}
.icon-box.ibox-border .ibox-icon i {
  line-height: 62px !important;
}
.icon-box.ibox-border.ibox-lg .ibox-icon i {
  line-height: 94px !important;
}

/* Icon Box - Border - Light
-----------------------------------------------------------------*/
.icon-box.ibox-border.ibox-light .ibox-icon {
  border-color: #E5E5E5;
}
.icon-box.ibox-border.ibox-light i,
.icon-box.ibox-border.ibox-light img {
  color: #888;
}

/* Icon Box - Border - Dark
-----------------------------------------------------------------*/
.icon-box.ibox-border.ibox-dark .ibox-icon {
  border-color: #333;
}
.icon-box.ibox-border.ibox-dark i,
.icon-box.ibox-border.ibox-dark img {
  color: rgba(0, 0, 0, 0.87);
}

/* Icon Box - Plain
-----------------------------------------------------------------*/
.icon-box.ibox-plain .ibox-icon {
  border: none !important;
  height: auto !important;
}
.icon-box.ibox-plain .ibox-icon i,
.icon-box.ibox-plain .ibox-icon img {
  border: none !important;
  background-color: transparent !important;
  color: #009688;
  border-radius: 0;
}
.icon-box.ibox-plain .ibox-icon i {
  font-size: 48px;
  line-height: 1 !important;
}
.icon-box.ibox-plain .ibox-icon img {
  height: 64px;
}
.icon-box.ibox-plain.ibox-image .ibox-icon {
  width: auto;
}
.icon-box.ibox-plain.ibox-image img {
  width: auto;
  display: inline-block;
}
.icon-box.ibox-plain.ibox-small {
  padding-left: 42px;
}
.icon-box.ibox-plain.ibox-small .ibox-icon {
  width: 28px;
}
.icon-box.ibox-plain.ibox-small .ibox-icon i {
  font-size: 28px;
}
.icon-box.ibox-plain.ibox-small .ibox-icon img {
  height: 28px;
}
.icon-box.ibox-plain.ibox-small h3 {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 10px;
}
.icon-box.ibox-plain.ibox-small p {
  margin-left: -42px;
}
.icon-box.ibox-plain.ibox-lg .ibox-icon i {
  font-size: 72px;
}
.icon-box.ibox-plain.ibox-lg .ibox-icon img {
  height: 96px;
}
.icon-box.ibox-plain.ibox-light .ibox-icon i,
.icon-box.ibox-plain.ibox-light .ibox-icon img {
  color: #888;
}
.icon-box.ibox-plain.ibox-dark .ibox-icon i,
.icon-box.ibox-plain.ibox-dark .ibox-icon img {
  color: rgba(0, 0, 0, 0.87);
}

/* Icon Box - Center
-----------------------------------------------------------------*/
.icon-box.ibox-center {
  padding: 0;
  text-align: center;
}
.icon-box.ibox-center.ibox-small {
  padding-left: 0 !important;
}
.icon-box.ibox-center .ibox-icon {
  position: relative;
  margin: 0 auto;
}
.icon-box.ibox-center h3 {
  margin: 0.8em 0 0;
}
.icon-box.ibox-center p {
  font-size: 14px;
  margin-top: 0em;
}

/* Icon Box - Right
-----------------------------------------------------------------*/
.icon-box.ibox-right {
  padding: 0 80px 0 0;
  text-align: right;
}
.icon-box.ibox-right.ibox-lg {
  padding-right: 115px;
}
.icon-box.ibox-right .ibox-icon {
  left: auto;
  right: 0;
}
.icon-box.ibox-right.ibox-plain.ibox-small {
  padding: 0 42px 0 0;
}
.icon-box.ibox-right.ibox-plain.ibox-small p {
  margin: 0 -42px 0 0;
}

/* Dark Theme
-----------------------------------------------------------------*/
.theme-gray .icon-box h3,
.theme-dark .icon-box h3 {
  color: rgba(255, 255, 255, 0.7);
}

/* Boxed
-----------------------------------------------------------------*/
.box-body > .icon-box {
  margin-top: 0;
}

.preloaderbar {
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  margin-bottom: -4px;
}
.preloaderbar .bar {
  position: absolute;
  width: 100%;
  height: 0;
  text-indent: -9999px;
  background-color: #23b7e5;
}
.preloaderbar .bar:before {
  position: absolute;
  right: 50%;
  left: 50%;
  height: 4px;
  background-color: inherit;
  content: "";
}
.preloaderbar.active {
  animation: changebar 2.25s infinite 0.75s;
}
.preloaderbar.active .bar {
  animation: changebar 2.25s infinite;
}
.preloaderbar.active .bar:before {
  animation: movingbar 0.75s infinite;
}

@keyframes movingbar {
  0% {
    right: 50%;
    left: 50%;
  }
  99.9% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}
@keyframes changebar {
  0% {
    background-color: #9CCC65;
  }
  33.3% {
    background-color: #9CCC65;
  }
  33.33% {
    background-color: #ffc107;
  }
  66.6% {
    background-color: #ffc107;
  }
  66.66% {
    background-color: #ff0000;
  }
  99.9% {
    background-color: #ff0000;
  }
  100% {
    background-color: #9CCC65;
  }
}
.mdl-card.mdl-card-full-width {
  width: 100%;
}

.card-panel {
  padding: 20px;
  margin: 10px 0 20px 0;
  border-radius: 2px;
}

.card {
  position: relative;
  overflow: hidden;
  margin: 10px 0 20px 0;
  border-radius: 2px;
}
.card .card-title {
  font-size: 24px;
}
.card .card-title.activator {
  cursor: pointer;
}
.card .card-action > a {
  margin-right: 20px;
  text-transform: uppercase;
}
.card .card-image {
  position: relative;
}
.card .card-image img {
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.card .card-image .card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}
.card .card-content {
  padding: 20px;
  border-radius: 0 0 2px 2px;
}
.card .card-content p {
  margin: 0;
  color: inherit;
}
.card .card-content .card-title {
  line-height: 48px;
}
.card .card-content .card-profile-img {
  position: relative;
  z-index: 1;
  margin-top: -70px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 5px;
  background-color: #fff;
}
.card .card-content .card-profile-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.card .card-content .card-profile-img.profile-img-sm {
  margin-top: -50px;
  width: 60px;
  height: 60px;
  padding: 2px;
}
.card .card-content .card-profile-img.profile-img-sm img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.card .card-content .card-button .btn-icon {
  position: relative;
  z-index: 1;
  margin-top: -65px;
}
.card .card-action {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
}
.card .card-action.no-border {
  border: 0;
}
.card .card-reveal {
  padding: 20px;
  position: absolute;
  background-color: #FFF;
  width: 100%;
  overflow-y: auto;
  top: 100%;
  height: 100%;
  z-index: 1;
}
.card .card-reveal .card-title {
  cursor: pointer;
  display: block;
}

.theme-gray .mdl-card,
.theme-dark .mdl-card {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .mdl-card__supporting-text,
.theme-dark .mdl-card__supporting-text {
  color: rgba(255, 255, 255, 0.7);
}

.theme-gray .mdl-card {
  background-color: #4c4c4c;
}

.theme-dark .mdl-card {
  background-color: #424951;
}

.mdl-data-table.mdl-data-table-non-numeric td, .mdl-data-table.mdl-data-table-non-numeric th {
  text-align: left;
}

.box.table-box {
  border-radius: 0;
}
.box > .mdl-data-table {
  width: 100%;
  background-color: transparent;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 1.125rem;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .table-responsive > .mdl-data-table {
    margin-bottom: 0;
  }
  .table-responsive > .mdl-data-table > thead > tr > th,
.table-responsive > .mdl-data-table > thead > tr > td,
.table-responsive > .mdl-data-table > tbody > tr > th,
.table-responsive > .mdl-data-table > tbody > tr > td,
.table-responsive > .mdl-data-table > tfoot > tr > th,
.table-responsive > .mdl-data-table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
.table-responsive > .table-bordered > thead > tr > td:first-child,
.table-responsive > .table-bordered > tbody > tr > th:first-child,
.table-responsive > .table-bordered > tbody > tr > td:first-child,
.table-responsive > .table-bordered > tfoot > tr > th:first-child,
.table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
.table-responsive > .table-bordered > thead > tr > td:last-child,
.table-responsive > .table-bordered > tbody > tr > th:last-child,
.table-responsive > .table-bordered > tbody > tr > td:last-child,
.table-responsive > .table-bordered > tfoot > tr > th:last-child,
.table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
.table-responsive > .table-bordered > tbody > tr:last-child > td,
.table-responsive > .table-bordered > tfoot > tr:last-child > th,
.table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.theme-gray .box > .mdl-data-table th,
.theme-dark .box > .mdl-data-table th {
  color: rgba(255, 255, 255, 0.5);
}

img.img30_30 {
  width: 30px;
  height: 30px;
}
img.img40_40 {
  width: 40px;
  height: 40px;
}
img.img64_64 {
  width: 64px;
  height: 64px;
}
img.img80_80 {
  width: 80px;
  height: 80px;
}

.pricing-table {
  position: relative;
  margin-bottom: 20px;
  background-color: #fcfcfc;
  color: #999;
  border-radius: 0.2rem;
  box-shadow: none;
  text-align: center;
  transition: 0.25s ease-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .pricing-table {
    transition: none;
  }
}
.pricing-table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pricing-table header {
  background-color: #8c8c8c;
  text-align: center;
  padding: 1.25rem 0.75rem;
}
.pricing-table header h2 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1;
  font-weight: normal;
  text-transform: uppercase;
  color: #fff;
}
.pricing-table .pricing-price {
  padding: 45px 15px;
  background-color: #999;
  font-size: 56px;
  line-height: 1;
  color: #fff;
  font-weight: 300;
}
.pricing-table .pricing-price .pricing-sign {
  margin-right: 5px;
  font-size: 24px;
  font-weight: normal;
}
.pricing-table .pricing-price .pricing-sub {
  margin-left: 5px;
  font-size: 22px;
  line-height: 1;
}
.pricing-table .pricing-lead {
  margin-bottom: 1.5em;
  font-size: 18px;
  text-transform: uppercase;
}
.pricing-table .pricing-lead:after {
  margin: 0 auto;
  content: "";
  height: 0px;
  width: 80px;
  padding-top: 0.6em;
  border-bottom: solid 3px #eff0f3;
  display: block;
}
.pricing-table .pricing-plan-details {
  padding: 1em;
}
.pricing-table .pricing-plan-details > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1rem;
  line-height: 2em;
}
.pricing-table > footer {
  padding: 2em;
  text-align: center;
}
.pricing-table > footer > a {
  display: block;
  margin: 0 auto;
  padding: 12px;
  font-size: 14px;
  line-height: 1;
  width: 100%;
  max-width: 200px;
}
.pricing-table.pricing-table-primary header {
  background-color: #007d71;
}
.pricing-table.pricing-table-primary .pricing-price {
  background-color: #009688;
}
.pricing-table.pricing-table-primary:after {
  border-top: 6px solid #009688;
  border-left: 6px solid #009688;
}
.pricing-table.pricing-table-secondary header, .pricing-table.pricing-table-info header {
  background-color: #00a5bb;
}
.pricing-table.pricing-table-secondary .pricing-price, .pricing-table.pricing-table-info .pricing-price {
  background-color: #00bcd4;
}
.pricing-table.pricing-table-secondary:after, .pricing-table.pricing-table-info:after {
  border-top: 6px solid #00bcd4;
  border-left: 6px solid #00bcd4;
}
.pricing-table.pricing-table-success header {
  background-color: #54b359;
}
.pricing-table.pricing-table-success .pricing-price {
  background-color: #66bb6a;
}
.pricing-table.pricing-table-success:after {
  border-top: 6px solid #66bb6a;
  border-left: 6px solid #66bb6a;
}
.pricing-table.pricing-table-warning header {
  background-color: #edb100;
}
.pricing-table.pricing-table-warning .pricing-price {
  background-color: #ffc107;
}
.pricing-table.pricing-table-warning:after {
  border-top: 6px solid #ffc107;
  border-left: 6px solid #ffc107;
}
.pricing-table.pricing-table-danger header {
  background-color: #e60000;
}
.pricing-table.pricing-table-danger .pricing-price {
  background-color: #ff0000;
}
.pricing-table.pricing-table-danger:after {
  border-top: 6px solid #ff0000;
  border-left: 6px solid #ff0000;
}

.theme-gray .pricing-table {
  background-color: #4c4c4c;
}

.theme-dark .pricing-table {
  background-color: #424951;
}

.ui-ribbon-container {
  position: relative;
}
.ui-ribbon-container .ui-ribbon-wrapper {
  position: absolute;
  overflow: hidden;
  width: 85px;
  height: 88px;
  top: -3px;
  right: -3px;
}
.ui-ribbon-container .ui-ribbon {
  position: relative;
  display: block;
  text-align: center;
  font-size: 15px;
  color: #fff;
  transform: rotate(45deg);
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  line-height: 20px;
  background-color: #495057;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.ui-ribbon-container .ui-ribbon:before, .ui-ribbon-container .ui-ribbon:after {
  position: absolute;
  content: " ";
  line-height: 0;
  border-top: 2px solid #495057;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  bottom: -2px;
}
.ui-ribbon-container .ui-ribbon:before {
  left: 0;
  bottom: -1px;
}
.ui-ribbon-container .ui-ribbon:after {
  right: 0;
}
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon {
  background-color: #009688;
}
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-primary .ui-ribbon:after {
  border-top: 2px solid #009688;
}
.ui-ribbon-container.ui-ribbon-success .ui-ribbon {
  background-color: #66bb6a;
}
.ui-ribbon-container.ui-ribbon-success .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-success .ui-ribbon:after {
  border-top: 2px solid #66bb6a;
}
.ui-ribbon-container.ui-ribbon-info .ui-ribbon {
  background-color: #00bcd4;
}
.ui-ribbon-container.ui-ribbon-info .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-info .ui-ribbon:after {
  border-top: 2px solid #00bcd4;
}
.ui-ribbon-container.ui-ribbon-warning .ui-ribbon {
  background-color: #ffc107;
}
.ui-ribbon-container.ui-ribbon-warning .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-warning .ui-ribbon:after {
  border-top: 2px solid #ffc107;
}
.ui-ribbon-container.ui-ribbon-danger .ui-ribbon {
  background-color: #ff0000;
}
.ui-ribbon-container.ui-ribbon-danger .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-danger .ui-ribbon:after {
  border-top: 2px solid #ff0000;
}

.sash {
  display: block;
  position: absolute;
  left: 0;
  right: auto;
  top: 25px;
  z-index: 1;
}
.sash .material-icons {
  font-size: 14px;
  height: 20px;
  height: 20px;
  line-height: 30px;
}

.sash {
  cursor: default;
  overflow: visible;
  pointer-events: none;
  position: absolute;
  background-color: #636c72;
  pointer-events: auto;
  color: #fff;
}
.sash > div {
  position: relative;
  z-index: 1;
  height: 30px;
  line-height: 30px;
  transition: width 0.1s 0.05s cubic-bezier(0.86, 0, 0.07, 1);
  width: 25px;
}
.sash > div:hover {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  width: 100%;
}
.sash > div:hover .sash-text {
  opacity: 1;
  width: 100%;
  padding-left: 15px;
}
.sash .sash-text {
  display: block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s, padding 0.25s, width 0.25s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  white-space: nowrap;
  width: 3em;
  float: left;
  margin-left: 5px;
  padding-right: 20px;
}
.sash i {
  position: absolute;
  right: 1px;
  margin-left: 5px;
}
.sash i:before {
  color: #f5f5f5;
}
.sash.sash-triangle-right:after {
  background-color: transparent;
  border: 15px solid #636c72;
  border-right-color: transparent;
  bottom: 0;
  content: " ";
  position: absolute;
  right: -20px;
}
.sash.sash-primary {
  background-color: #009688;
}
.sash.sash-primary.sash-triangle-right:after {
  border-color: #009688;
  border-right-color: transparent;
}
.sash.sash-info {
  background-color: #00bcd4;
}
.sash.sash-info.sash-triangle-right:after {
  border-color: #00bcd4;
  border-right-color: transparent;
}
.sash.sash-success {
  background-color: #66bb6a;
}
.sash.sash-success.sash-triangle-right:after {
  border-color: #66bb6a;
  border-right-color: transparent;
}
.sash.sash-warning {
  background-color: #ffc107;
}
.sash.sash-warning.sash-triangle-right:after {
  border-color: #ffc107;
  border-right-color: transparent;
}
.sash.sash-danger {
  background-color: #ff0000;
}
.sash.sash-danger.sash-triangle-right:after {
  border-color: #ff0000;
  border-right-color: transparent;
}
.sash.sash-primary {
  background-color: #009688;
}
.sash.sash-primary.sash-triangle-right:after {
  border-color: #009688;
  border-right-color: transparent;
}
.sash.sash-white {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.sash.sash-white.sash-triangle-right:after {
  border-color: #fff;
  border-right-color: transparent;
}

.testimonial {
  text-align: center;
}
.testimonial .testimonial__quote {
  display: block;
  text-align: center;
}
.testimonial .testimonial__quote .material-icons {
  font-size: 3.5em;
  line-height: 1em;
}
.testimonial blockquote:before, .testimonial blockquote:after {
  content: none;
}
.testimonial .avatar {
  border-radius: 50%;
  max-width: 80px;
}
.testimonial h5 {
  font-size: 1rem;
  margin-bottom: 0.4em;
}
.testimonial .title {
  opacity: 0.54;
}

.testimonial-alt .avatar {
  max-width: 100px;
  margin-bottom: 1rem;
}
.testimonial-alt blockquote {
  margin: 0;
  font-size: 1.25rem;
  opacity: 0.9;
  border-left: 0;
}
.testimonial-alt .citation {
  font-size: 1rem;
  opacity: 0.54;
}

.ui-timline-container {
  padding: 15px;
}

.ui-timline-left .ui-timeline:before {
  left: 0;
}
@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item .tl-caption {
    margin-left: -55px;
  }
}
@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item .tl-body .tl-time {
    left: auto;
    right: 15px;
    color: #636c72;
  }
}

.ui-timeline {
  display: table;
  position: relative;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.ui-timeline:before {
  background-color: rgba(0, 0, 0, 0.15);
  bottom: 0px;
  content: "";
  position: absolute;
  left: 0;
  top: 30px;
  width: 1px;
  z-index: 0;
}
@media (min-width: 768px) {
  .ui-timeline:before {
    left: 50%;
  }
}
.ui-timeline .tl-item {
  display: table-row;
  margin-bottom: 5px;
}
.ui-timeline .tl-item:before {
  display: none;
  content: "";
}
@media (min-width: 768px) {
  .ui-timeline .tl-item:before {
    display: block;
    width: 50%;
  }
}
.ui-timeline .tl-item .tl-caption {
  width: 150px;
  margin-left: -55px;
}
@media (min-width: 768px) {
  .ui-timeline .tl-item .tl-caption {
    margin-left: -110px;
  }
}
@media (min-width: 768px) {
  .ui-timeline .tl-item.alt {
    text-align: right;
  }
  .ui-timeline .tl-item.alt:before {
    display: none;
  }
  .ui-timeline .tl-item.alt:after {
    content: "";
    display: block;
    width: 50%;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-entry {
    margin: 0 35px 15px 0;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-time {
    right: -220px;
    left: auto;
    text-align: left;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-icon {
    right: -53px;
    left: auto;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-content:after {
    right: -16px;
    left: auto;
    border: 8px solid transparent;
    border-left: 8px solid rgba(255, 255, 255, 0.9);
    border-top: 8px solid rgba(255, 255, 255, 0.9);
  }
}
.ui-timeline .tl-item .tl-body {
  display: table-cell;
  width: 50%;
  vertical-align: top;
}
.ui-timeline .tl-item .tl-body .tl-entry {
  position: relative;
  margin: 0 0 15px 36px;
}
.ui-timeline .tl-item .tl-body .tl-time {
  z-index: 1;
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  width: 150px;
  color: #636c72;
  line-height: 35px;
  text-align: right;
}
@media (min-width: 768px) {
  .ui-timeline .tl-item .tl-body .tl-time {
    left: -220px;
    right: auto;
    color: rgba(0, 0, 0, 0.87);
  }
}
.ui-timeline .tl-item .tl-body .tl-icon {
  position: absolute;
  left: -53px;
  top: 0;
}
.ui-timeline .tl-item .tl-body .tl-icon .material-icons {
  font-size: 20px;
}
.ui-timeline .tl-item .tl-body .tl-content {
  position: relative;
  padding: 15px;
  border-radius: 0.2rem;
  background-color: rgba(255, 255, 255, 0.9);
}
.ui-timeline .tl-item .tl-body .tl-content:after {
  content: " ";
  line-height: 0;
  position: absolute;
  left: -16px;
  top: 0;
  border: 8px solid transparent;
  border-right: 8px solid rgba(255, 255, 255, 0.9);
  border-top: 8px solid rgba(255, 255, 255, 0.9);
}
.ui-timeline .tl-item .tl-body .tl-content h4 {
  font-size: 18px;
  line-height: 1.5rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .theme-gray .ui-timeline .tl-item.alt .tl-body .tl-content:after,
.theme-dark .ui-timeline .tl-item.alt .tl-body .tl-content:after {
    border-left: 8px solid rgba(255, 255, 255, 0.05);
    border-top: 8px solid rgba(255, 255, 255, 0.05);
  }
}
.theme-gray .ui-timeline .tl-item .tl-body .tl-content,
.theme-dark .ui-timeline .tl-item .tl-body .tl-content {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-gray .ui-timeline .tl-item .tl-body .tl-content:after,
.theme-dark .ui-timeline .tl-item .tl-body .tl-content:after {
  border-right: 8px solid rgba(255, 255, 255, 0.05);
  border-top: 8px solid rgba(255, 255, 255, 0.05);
}

.filter-navs {
  background-color: #ffffff !important;
  flex: 1 1 auto;
}
.filter-navs .nav-subheader {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 0px;
  font-weight: 600;
  padding-left: 24px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}
.filter-navs .p-category {
  border-bottom: 1px solid #eeeeee;
}
.filter-navs .p-category .p-category-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--gray-darker);
  height: 40px;
  line-height: 40px;
  padding-left: 24px;
}
.filter-navs .p-category .p-category-options {
  font-size: 13px;
  color: var(--gray-darker);
  line-height: 24px;
  padding-left: 30px;
  cursor: pointer;
  padding-bottom: 10px;
}
.filter-navs .p-category .p-category-options span.selected {
  color: var(--blue);
}
.filter-navs .mat-expansion-panel {
  box-shadow: none !important;
  border-bottom: 1px solid #eee;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}
.filter-navs .mat-expansion-panel-header-title {
  margin-right: 0;
}
.filter-navs .mat-expansion-panel-body {
  padding: 0 24px 10px;
  border-bottom: 1px solid #eee;
}
.filter-navs .mat-checkbox-layout {
  width: 100%;
  align-items: center;
}
.filter-navs .mat-checkbox-layout .mat-checkbox-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.filter-navs .mat-checkbox-layout {
  display: -webkit-box !important;
}
.filter-navs .mat-checkbox-layout .mat-checkbox-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  max-width: calc(100% - 28px);
}