.page-layout {
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
  min-height: calc(100vh - var(--headerHeight));
  flex: 1 1 auto;
  flex-direction: row !important;

  .top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
  }

  .accent {
    background-color: #41444a !important;
    color: #ffffff !important;
  }
}

// Page Sidebar CSS
.page-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  z-index: 1000;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  .main-sidebar {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    // min-height: calc(100vh - var(--headerHeight));
    // max-height: calc(100vh - var(--headerHeight));
    background-color: #f5f5f5;
  }

  .sidebar-header {
    height: 200px;
    min-height: 200px;
    max-height: 200px;
  }

  &.animations-enabled {
    transition-property: transform, width, min-width, max-width,
      -webkit-transform;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }

  &.locked-open {
    left: 0;
    background: 0 0;
    box-shadow: none;
    position: relative !important;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }

  &.open {
    z-index: 9991;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    flex-direction: row-reverse;
    overflow-y: auto;
    max-height: calc(100vh - var(--headerHeight));
  }
}

// Page Sidebar Overlay CSS
.page-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

// Page Container CSS
.page-container {
  z-index: 1001;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  padding: 35px 15px 15px 0;
  width: 100%;
  min-width: 0;
  .page-header {
    display: flex;
    margin-bottom: 35px;
  }

  .page-body {
    border-radius: 12px;
    overflow: hidden;
    flex: 1 1 auto;
    border-top: 0;
    border-bottom: 0;

    .card {
      border-radius: 12px;
    }
  }

  .search-section-heading {
    font-size: 22px;
    font-weight: 500;
    margin: 1px 0px 2px 4px;
  }

  .box {
    border-radius: 4px !important;
    margin-bottom: 0 !important;

    .box-body {
      // padding: 0;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }

  .search-section {
    .box {
      .box-body {
        padding: 1rem;
      }
    }
  }

  .mat-table {
    overflow: auto;
    max-height: 500px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 0;
  }

  .mat-tab-label {
    opacity: 0.8 !important;
  }

  .mat-ink-bar {
    background-color: #0d0d0d !important;
  }

  .mat-tab-label {
    height: 64px !important;
  }

  // Media CSS
  @media (max-width: 1279px) {
    .page-container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media (max-width: 375px) {
    .mat-paginator-range-label {
      margin: 0 10px !important;
    }
  }
}

.page-container.team-page{
  padding: 29px 15px 15px 15px !important;
}
.content-card {
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.content-full-height {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

@media (max-width: 1279px) {
  .page-container {
    margin-left: 15px;
  }
}
