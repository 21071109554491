@font-face {
  font-family: 'Helvetica Neue OrderAxe';
  font-style: normal;
  font-weight: 400;
  src: url(Helvetica-Neue.eot); /* For IE6-8 */
  src:  local('Helvetica Neue OrderAxe'),
        local('Helvetica-Neue'),
        url(Helvetica-Neue.eot?#iefix) format("embedded-opentype"),
        url(Helvetica-Neue.woff2) format("woff2"),
        url(Helvetica-Neue.woff) format("woff"),
        url(Helvetica-Neue.ttf) format("truetype"),
        url(Helvetica-Neue.svg#NeueHelveticaeText) format("svg");
}
@font-face {
  font-family: 'Helvetica-Neue-Bold';
  font-style: normal;
  font-weight: 400;
  src:  local('Helvetica-Neue-Bold'),
        url(HelveticaNeueBd.ttf) format("truetype");
}

