
.page-header-section {
  padding-top: 14px;
  padding-bottom: 14px;
  gap: 10px;
  .page-heading{
    font-family: roboto;
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 1.68px;
    font-weight: 600 !important;
    overflow: hidden;
    margin: 0;
  }
  .back-icon {
    width: 25px;
    min-width: 25px;
    height: 25px;
    svg {
      fill: currentColor;
    }
  }
  .page-title {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: inherit;
  }


  @media (max-width: 425px) {
    .page-heading {
      font-size: 25px;
      width: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: 375px) {
    .page-heading {
      font-size: 20px;
    }
  }

}
