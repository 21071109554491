// @import "../assets/fonts/fontstyle.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


* {
  font-family: Roboto;
}

$colors: (
  "blue": $themeColorCode,
  "delete": #df2121,
  "processing": #ffc107
);

:root {
  --black: #000;
}

$theme-colors: (
  "primary": $themeColorCode,
);

$selectionBackgroundColor: rgba(0, 150, 136, 1);
$selectionTextColor: var(--white);

html {
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  height: 100%;

  &.cdk-global-scrollblock {
    top: 0 !important;
    position: relative;
    overflow: hidden;
  }
}

.mat-checkbox {
  display: initial !important;
}

/**
 * Firefox specific rule
 */
//  @-moz-document url-prefix() {
//   body {
//     font-weight: lighter !important;
//   }
// }
body {
  background-color: #f5f5f5 !important;
  color: var(--gray-darker) !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;

  .bodyOverlay,
  .ox-tooltip-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1002;
    top: 0;
    left: 0;
    display: none;
  }

  &.sidebarIsOpen,
  &.productSidebarOpen,
  &.sidebar-overlay {
    overflow: hidden;
  }

  &.sidebar-overlay,
  &.productSidebarOpen {
    .bodyOverlay {
      display: block;
    }
  }

  &.ox-tooltip-open {
    overflow: hidden;

    .ox-tooltip-overlay {
      z-index: 9999;
      display: block;
    }
  }
}

.form-model h1,
.form-model h2,
.form-model h3 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400 !important;
}

::-moz-selection {
  /* Code for Firefox */
  background: $selectionBackgroundColor;
  color: $selectionTextColor;
}

::selection {
  background: $selectionBackgroundColor;
  color: $selectionTextColor;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a {
  font-weight: 400;
}

#loader-container,
#load-overlay {
  background: rgba(237, 240, 241, 0.6) url("../assets/images/audio.svg") no-repeat center center !important;
}

.show-loader {
  display: block;
}

.hide-loader {
  display: none;
}

.hide {
  display: none;
}

.mat-menu-item:hover:not([disabled]) {
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: #f7f8fa;
  color: #000;
}

.brand-menu-dropdown {
  width: 500px;
  max-width: 100% !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.fixed-header .app-page-container .app-content-wrapper .app-content {
  padding-top: 0px !important;
  // background-color: var(--alice_blue);
}

.p-24 {
  padding: 24px !important;
}

.btn.btn-min-width {
  min-width: auto;
}

// Form css start -----------------

.form-control {
  padding: 0.75rem 1.5rem 0.75rem 0.8rem !important;
}

.form-control:focus {
  border-color: #009688 !important;
  box-shadow: none !important;
}

.form-error {
  color: var(--red);
  top: -15px;
  font-size: 12px;
  position: relative;
  float: right;
  height: 0px !important;
}

.form-error.error2 {
  top: 0;
}

.from-error-message {
  top: -61px !important;
}

.form-control:focus {
  border-color: $themeColorCode;
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.11);
}

.mat-select,
.mat-button,
.mat-toolbar {
  font-family: inherit !important;
}

// Form css end  -----------------

// button css start -----------------
.btn-cancel {
  background-color: rgba(158, 158, 158, 0.4) !important;
}

.mat-button-wrapper {
  font-family: roboto, bold;
}

a.btn-w-sm,
button.btn-w-sm {
  min-width: 88px !important;
}

// button css end -----------------

// Model width css start -----------------

.overlay.transparent {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.large-model-size.nsm-dialog {
  max-width: 900px !important;
  z-index: 1400 !important;
}

.medium-model-size.nsm-dialog {
  max-width: 55vw !important;
}

.small-model-size.nsm-dialog {
  max-width: 330px !important;
  z-index: 9999;
}

.mat-dialog-container {
  min-width: 350px !important;
}

// Model width css end -----------------

// Toast css start ------------------

.mat-snack-bar-container {
  background-color: transparent !important;
  padding: 0px;
}

.success {
  padding: 14px 24px;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.error {
  padding: 14px 24px;
  background-color: var(--red) !important;
  border-color: var(--red) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.warning {
  padding: 14px 24px;
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification {
  padding: 14px 24px;
  background-color: #548cf1 !important;
  border-color: #548cf1 !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/**/

.error-input-class {
  background-color: var(--red);
}

.table thead th {
  vertical-align: middle;
  // font-weight: bold;
}

.table {
  margin-bottom: 0;
}

tr.mat-row {
  height: 65px !important;
}


.ng-value-container {
  height: 44px;
}

/*material form css*/
.mat-form-field,
.mat-raised-button {
  line-height: inherit;
}

.mat-raised-button {
  letter-spacing: 0.14px !important;
}

button[mat-raised-button]:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {
  .mat-drawer-opened.mat-drawer-container {
    height: var(--windowInnerHeight, 100vh) !important;
  }

  .mat-dialog-container {
    min-width: 100% !important;
  }

  .cropper-dialog,
  .search-dialog,
  .retailer-dialog,
  .add-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: auto !important;
  }

  .addOrg-dialog {
    min-width: auto !important;
    width: 100% !important;
    margin: 10px auto !important;
    height: 100% !important;
    min-height: auto !important;
  }
}

.mat-drawer-container .mat-drawer {
  background: #262b33;
}

.mat-drawer-container .mat-nav-list .mat-list-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

.mat-drawer-container .mat-nav-list .mat-list-item .mat-icon {
  margin-right: 10px;
}

// side menu link active
.mat-nav-list .mat-list-item:hover {
  color: var(--white);
  background-color: rgba(158, 158, 158, 0.2);
}

/*Global css responsive*/
label {
  text-align: left !important;
}

/* Cursor setting */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.search-dialog,
.add-dialog,
.retailer-dialog,
.addOrg-dialog {
  width: 600px;
  height: auto;
  margin: 30px auto !important;
}

.cropper-dialog {
  min-width: 600px;
  width: auto;
  height: auto;
  margin: 30px auto !important;
}


.cate-actions {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 15px;

  li {
    width: 18px;
    margin-right: 10px;
    color: #009688;
    height: 18px;

    svg {
      fill: currentColor;
    }
  }
}

.brand-details-modal {
  &.mat-bottom-sheet-container {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    max-height: 90vh;
    animation: none !important;
  }

  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
    animation: none !important;
  }
}

.search-dialog .mat-dialog-container,
.add-dialog .mat-dialog-container,
.retailer-dialog .mat-dialog-container,
.cropper-dialog .mat-dialog-container,
.addOrg-dialog .mat-dialog-container,
.edit-organisation .mat-dialog-container,
.markDown-modal .mat-dialog-container,
.unsaved-dialog .mat-dialog-container,
.padding-0 .mat-dialog-container,
.image-cropper-modal .mat-dialog-container,
.brand-details-modal .mat-dialog-container {
  padding: 0 !important;
}

.padding-y-0 {
  .mat-dialog-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.share-catalogue-modal {
  .mat-dialog-container {
    background-color: transparent;

    overflow: visible;
  }
}

.search-dialog .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 240px;
}

.search-dialog {
  height: auto;
  min-height: 204px;
}

.retailer-dialog {
  height: auto;
  min-height: 119px;
}

.add-dialog {
  height: auto;
  min-height: 347px;
}

.addOrg-dialog {
  height: auto;
}

.vertical-center-modal {
  margin: auto !important;
}

// ----- Heading Style ----
.section-header {
  font-weight: 400 !important;
  font-size: 34px;
  line-height: 40px;
  color: var(--blue);
}

.filter-action-btn .mat-icon-button {
  width: auto;
}

.filter-div {
  border: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.download-btn {
  min-width: 35px !important;
  line-height: 31px !important;
}

.download-btn.mat-raised-button {
  padding: 0 9px;
}

.download-btn i {
  font-size: 18px;
}

// Table css START
.table-responsive {
  max-height: 430px !important;

  @media screen and (max-width: 767px) {
    overflow-y: initial !important;
  }
}

.table th {
  border: 0;
}

.mat-elevation-z8 {
  padding: 15px;
  background: var(--white);
}

.mat-dialog-actions {
  padding: 0 10px !important;
  margin-bottom: 0 !important;
}

.card-action {
  padding: 15px !important;
}

// table css END
#distributor {
  font-weight: 400;
}

// Mat Stepper
.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.icon-box.ibox-lg .ibox-icon {
  height: 60px !important;
  width: 60px !important;
}

.mat-dialog-container {
  color: var(--gray-darker);
}

.outerTemplate {
  padding: 0 35px;
}

#retailer .mat-form-field-label {
  color: rgba(0, 0, 0, 0.26);
  font-weight: 400 !important;
}

#retailer .mat-form-field-hide-placeholder {
  color: red;
  font-weight: 400 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $selectionBackgroundColor  !important;
}

#retailer .mat-sort-header-container {
  padding: 5px 0;
}

// #retailer th.mat-header-cell:first-of-type {
//   padding-left: 0;
// }

// #retailer .table thead th {
//   padding: 10px 20px;
//   text-transform: uppercase !important;
// }

.mat-column-logo .mat-sort-header-container {
  justify-content: center;
}

.avatar-img,
.retailer-avatar-img {
  height: 55px;
  width: 55px;
  font-weight: 600;
}

.avatar-img.notification-drop {
  height: 40px;
  width: 40px;
  font-weight: 600;
  margin: auto;
  border: solid 1px #4ca79c;
}

.avatar-img,
.retailer-avatar-img {
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  border: solid 1px #e4e4e4;
}

.avatar-img.color,
.retailer-avatar-img.color {
  background-color: var(--blue);
  margin: auto;
}

#retailer .mat-cell {
  font-weight: 500;
}

// add retailer dialog
.retailer-add-dialog {
  margin: auto !important;
}

.mat-dialog-content {
  padding: 15px !important;
  margin: 0 !important;
}

.retailer-add-dialog .mat-dialog-content {
  padding: 25px 40px !important;
}

// Edit menu
.mat-menu-panel.edit-menu {
  margin-top: 35px;
  min-width: 63px;
  margin-left: -31px;
}

.edit-menu .mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.edit-menu .mat-menu-item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.mat-toolbar-row {
  justify-content: center;
}

.mat-drawer-container {
  background-color: inherit !important;
}

// ***** Organisation model validation ***** //

.nsm-content {
  padding: 0 !important;
}

#frmAddOrganization .form-group {
  padding: 0 15px;
}

.add-org-btn {
  text-align: center;
}

.e-tooltip-wrap {
  padding: 5px;
  background-color: #262b33 !important;
  font-size: 16px;
}

/* Image cropper css */
.image-cropper-modal {
  min-width: 790px !important;
}

.edit-organisation {
  margin: 30px auto !important;
}

.cdk-global-overlay-wrapper {
  // pointer-events: auto !important;
  overflow: auto;
}

.cdk-global-overlay-wrapper::before {
  content: "";
  height: auto;
  max-height: 100%;
  white-space: nowrap;
}

.cdk-overlay-container {

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    // pointer-events: auto !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

/* ----- */

.mat-menu-panel .mat-menu-content {
  padding: 0px !important;
}

.mat-list[dense] .mat-list-item .mat-line {
  display: inline;
  margin-left: 14px;
}

.closeTop {
  min-width: 20px !important;
  width: 20px;
  height: 20px;
}

.header-profile-dropdown {
  margin-top: -1px;
}

// .cdk-overlay-connected-position-bounding-box:after {
//   content: "";
//   position: absolute;
//   right: 20px;
//   top: -8px;
//   border-bottom: 9px solid #f2f7f7;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
// }

.page-notification {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

.reject-dialog {
  .mat-button-wrapper {
    color: #ffffff !important;
  }

  .reject-title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #e40306;
    text-align: center;
  }

  .rejected-text {
    text-align: center;
    padding-bottom: 1rem;
  }

  .rject-btn.failure {
    background-color: #e40306 !important;
    .mat-button-wrapper {
      color: #ffffff !important;
    }
  }
}


.browse-btn:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.otpText span {
  font-weight: 500 !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-size: 14px;
  color: var(--gray-darker);
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mat-tree-node {
  color: var(--gray-darker);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  min-height: 30px !important;
  // padding-left: 0 !important;
}

.mat-tree-node .mat-checkbox-layout {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-darker);
  text-transform: capitalize;
}

.tree-outer-div,
.tree-inner-div {
  padding: 0;
}

.filter-field {
  border-top: 1px solid #eee;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background: transparent;
  border-bottom: 1px #eeeeee solid;
}

.mat-expansion-panel-body {
  border-bottom: 1px solid #eee;
}

.mat-checkbox-layout {
  width: 100%;
  display: flex !important;
  align-items: center !important;

  .mat-checkbox-inner-container {
    margin: 0;
    margin-right: 8px;
  }
}

// search ALL button option
.search-options .mat-menu-item {
  height: 30px;
  line-height: 30px;
}

// Common : round circle for name/ image display for organisation
.product-header .mat-fab,
.org-drop .mat-fab {
  height: 26px !important;
  width: 26px !important;
}

.mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
}

.brandList {
  .mat-select-value {
    color: #fff;
    overflow: visible;
  }
  .mat-select-trigger {
    height: auto;
  }
  mat-select-trigger {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
}

textarea {
  resize: none !important;
}

.page-layout {
  table {
    width: 100%;
  }

  tr.isDeleting {
    pointer-events: none;
    // position: relative;
    background-color: rgb(223 33 33 / 30%) !important;
    * {
      pointer-events: none;
    }
  }
  tr.isDeleting:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(223 33 33 / 30%);
    z-index: 2;
    font-size: 18px;
    color: #df2121;
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
  }

  .mat-table {
    overflow: auto;
    max-height: 500px;
    border-radius: 12px;
    border: 0px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: 0;
    border-collapse: separate;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: transparent;
  }

  .table thead th,
  table thead th .mat-sort-header-button {
    background: #fff;
    font-family: roboto, medium;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    color: #505050;
  }

  .table:not(.tab-group-table) thead th:first-child {
    border-top-left-radius: 12px;
  }

  .table:not(.tab-group-table) thead th:last-child {
    border-top-right-radius: 12px;
  }

  .mat-table thead {
    background: transparent;
  }

  .mat-elevation-z8 {
    padding: 0px;
  }

  td.mat-cell {
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1e1e1e;
    text-decoration: none;
    vertical-align: middle;
    border: 0;
  }

  tr.mat-header-row {
    height: 64px !important;
    min-height: 64px;
    max-height: 64px;
  }

  th.mat-header-cell {
    padding: 10px;
  }

  .mat-cell {
    font-weight: 500;
  }

  .mat-table>thead>tr {
    border-bottom: none;
  }

  .mat-column-image .mat-sort-header-container {
    justify-content: center;
  }

  .mat-column-noData {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }

  .table thead th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-form-field-infix {
    border-bottom: 0.1em solid #bfbbb8;
  }

  .mat-select-arrow {
    color: #bfbbb8;
  }

  .mat-paginator {
    border-radius: 12px;
    border: none;
  }

  tr.mat-row {
    cursor: pointer;
  }
}

.view-status {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  background-color: #e5f4f1;
  color: var(--blue) !important;
  border-radius: 3px;
  width: 75px;
  justify-content: center;

  &.view-status-inactive {
    filter: grayscale(1);
  }

  .check-icon {
    min-width: 10px;
    width: 10px;
    height: 10px;
    display: inline-block;
    line-height: 10px;
    margin-right: 5px;
    font-size: 12px !important;
    color: inherit !important;

    svg {
      fill: currentColor;
    }
  }
}

.product-listing,
.product-catlogue-listing {

  // product details display css
  .color-pallete-table {
    width: auto;
  }

  .color-pallete-table td {
    padding: 4px 1px 0px 0px;
    border-top: 0;
  }

  .color-palette {
    float: left;
    width: 20px !important;
    height: 20px !important;
    margin: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0 !important;
    margin-left: 0px;
    position: relative;
  }

  .color-palette span {
    font-size: 10px;
    top: -0.7rem;
    position: relative;
  }

  .plt-image-container {
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 0;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
  }

  th.mat-column-image,
  th.mat-column-status {
    border-radius: 0;
  }

  .mat-column-detail {
    padding: 10px 20px !important;
    text-align: left !important;
    width: auto;
    min-width: 180px;
  }

  .mat-column-type {
    width: 140px;
    padding: 0 20px !important;
  }

  .mat-column-check {
    width: 60px;
    padding: 0 20px !important;
  }

  .mat-column-image {
    width: 80px;
    padding: 10px 0 !important;

    &:first-of-type {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  .mat-column-category {
    padding: 20px !important;
    width: 140px;
  }

  td.mat-column-category {
    font-size: 16px !important;
  }

  .mat-column-feature {
    width: 140px;
    padding: 20px !important;
  }

  .mat-column-label {
    width: 140px;
    padding: 20px !important;
  }

  .mat-column-action {
    width: 140px;
    padding: 20px !important;
  }

  .mat-column-status {
    width: 140px;
    padding: 20px !important;
  }
}

.fixed-header {
  .app-header {
    z-index: 1003;

    .mat-sidenav-container {
      position: absolute;
      min-height: auto !important;
      height: auto !important;

      &.mat-drawer-opened,
      &.mat-drawer-container-has-open {
        height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px)) !important;
      }
    }
  }
}

body .cdk-overlay-container {
  z-index: 1003;
}

// Product detail dialog
.product-detail-dialog {
  .mat-dialog-title {
    border-bottom: 1px solid #e5e5e5;
    padding: 18px 15px;
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--gray-darker);
    font-weight: 500 !important;
  }
}

.p-detail-dialog .mat-dialog-container {
  padding: 0px;
}

.p-detail-dialog .mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 0 10px !important;
  margin: 0 !important;
}

.p-detail-dialog .mat-dialog-content {
  padding: 15px;
  margin: 0;
}

.p-detail-dialog {
  .table th {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr.mat-row {
    height: 48px !important;
  }

  tr.mat-header-row {
    height: 45px;
    background: #504b51 !important;
  }

  .mat-header-cell {
    color: #fff !important;
  }

  .mat-cell,
  .mat-header-cell {
    padding: 10px !important;
    text-align: center;
    vertical-align: middle;
  }

  .mat-column-size {
    min-width: 100px;
  }

  .mat-column-color {
    min-width: 167px;
  }

  .mat-column-stock {
    min-width: 50px;
  }

  .mat-column-status {
    min-width: 150px;
  }

  .mat-column-mrp {
    min-width: 50px;
  }
}

.mat-paginator-container {
  border-left: none;
  border-right: none;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mat-expansion-panel-body {
  position: relative !important;
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .search-section {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
      border-top: 0 !important;
    }

    .mat-form-field-underline {
      bottom: 0 !important;
    }
  }
}

// Page Header css common for all

/* new page design */
.top-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
}

.accent {
  background-color: #504b51 !important;
  color: #fff !important;
}

.left-sidebar>.sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
  position: fixed !important;
  top: var(--headerHeight, 0px);
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.left-sidebar>.sidebar,
.right-sidebar>.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 267px;
  min-width: 267px;
  max-width: 267px;
  height: auto;
  overflow: hidden;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.fuse-sidebar.animations-enabled {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.fuse-sidebar.locked-open {
  // position: relative !important;
  transform: translateX(0) !important;
}

.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1003;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}

.main-sidebar {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  border-right: solid 5px #35a093;
}

.left-sidebar>.sidebar .header,
.right-sidebar>.sidebar .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.p-24 {
  padding: 24px !important;
}

.header .brandList {
  width: 100%;
}

.left-sidebar>.sidebar .content,
.right-sidebar>.sidebar .content {
  background: #f5f5f5;
  flex: 1 1 auto;
  position: fixed !important;
  top: 0 !important;
  width: calc(100% - 5px) !important;
}

.ps {
  position: relative;
}

/* nav header */
.navs .nav-subheader {
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 600;
  padding-left: 24px;
  margin-top: 8px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}

/* listing display section */
.left-sidebar>.sidebar.locked-open.left-positioned+.center,
.right-sidebar>.sidebar.locked-open.left-positioned+.center {
  margin-left: 0;
  padding-left: 15px !important;
  margin-left: 267px !important;
  // background: #42454a !important;
}

.left-sidebar>.sidebar.locked-open+.center,
.right-sidebar>.sidebar.locked-open+.center {
  z-index: 1001;
}

.left-sidebar.inner-scroll>.center {
  flex: 1 1 auto;
}

.left-sidebar>.center>.header,
.right-sidebar>.center>.header {
  display: -webkit-box;
  display: flex;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  margin: 0px;
  margin-bottom: 10px;
}

.page-layout.carded.left-sidebar.inner-scroll,
.page-layout.carded.right-sidebar.inner-scroll {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-layout.carded.left-sidebar,
.page-layout.carded.right-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.page-layout {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}

.content-card {
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 10px;
  box-shadow: none !important;
}

.fuse-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
}

.fuse-sidebar.open {
  z-index: 1000;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  // -webkit-flex-direction: row-reverse;
  // -ms-flex-direction: row-reverse;
  // flex-direction: row-reverse;
}

.resolution-hint {
  font-size: 14px;
  padding: 15px;
  background: #f3f3f3;
  color: #949393;
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;

  i.fa-info-circle {
    color: currentColor;
  }
}

.mobile-filter-icon {
  cursor: pointer;
  align-self: center;
  padding-right: 10px;
}

.hiddenMatField {
  visibility: hidden;
  padding-bottom: 1.6em;
}

.clear-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.clear-section-button {
  color: var(--blue);
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  padding-right: 10px;
}

.material-icons.icon-setting {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000;
  line-height: 22px;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.filter-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  svg {
    width: 14px;
  }
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.mat-checkbox-background {
  background-color: #f4f5f9;
}

.mat-expansion-panel-spacing {
  margin: 0;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  margin: 0 !important;
}

.mat-expansion-panel-header-title {
  margin-right: 0 !important;
}

.orderStatus-menu {
  .mat-menu-item {
    height: 30px;
    line-height: 30px;

    .status-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ddd;
      vertical-align: middle;
      margin-right: 5px;
      opacity: 0;
    }

    &.status-selected {
      .status-icon {
        opacity: 1;
      }
    }

    &.status-pending {
      .status-icon {
        background-color: #e67a57;
      }
    }

    &.status-completed {
      .status-icon {
        background-color: #15224c;
      }
    }

    &.status-partially-delivered {
      .status-icon {
        background-color: #a5a5a5;
      }
    }

    &.status-new {
      .status-icon {
        background-color: #00bcd4;
      }
    }

    &.status-delivered {
      .status-icon {
        background-color: #4caf50;
      }
    }

    &.status-processing {
      .status-icon {
        background-color: #2b98e0;
      }
    }

    &.status-removed {
      .status-icon {
        background-color: #f1261e;
      }
    }

    &.status-cancelled {
      .status-icon {
        background-color: #db3e38;
      }
    }

    &.status-draft {
      .status-icon {
        background-color: #ada14b;
      }
    }
  }
}


.required-warn {
  color: var(--red);
}

#organisationName {
  text-transform: capitalize;
}

.errorMsg {
  color: var(--red);
}

.errorBorder {
  // border: 1px solid var(--red);
  border-bottom: 1px solid var(--red);
  padding: 10px;
}

// Search section
.search-section-heading {
  font-size: 22px;
  font-weight: 500;
  margin: 1px 0px 2px 4px;
}

table {
  .noDataAvailable {
    min-height: 300px;
  }
}

.mat-menu-item {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
}

.owl-carousel .nav-btn {
  height: 60px;
  position: absolute;
  width: 20px;
  cursor: pointer;
  top: 0 !important;
  bottom: 0;
  margin: auto 0;
  padding-top: 20px;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background-color: #d8d8d8;
  left: -160px;
  border-radius: 2px;

  i {
    color: black !important;
    font-weight: bold;
  }
}

.owl-carousel .next-slide {
  background-color: #d8d8d8;
  right: -28px;
  border-radius: 2px;

  i {
    color: black !important;
    font-weight: bold;
  }
}

.owl-theme.row {
  margin-left: 0px !important;
  margin-right: opx !important;
}

// .croppie-container .cr-boundary{
//     height: 87% !important;
// }

// .croppie-container .cr-slider-wrap{
//   margin: 0px auto !important;
//   padding: 15px 0px;
//   background-color: #7f7f7f61;
//   width: 100% !important;
// }

@media (max-width: 770px) {
  .owl-carousel .prev-slide {
    left: -145px;
  }
}

.demo {
  background-color: red !important;
  color: gray !important;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}

.brandList.order-brand-disabled {
  .mat-select-disabled .mat-select-value {
    color: #fff !important;
  }
}

.filenameStyle {
  word-break: break-word;
}

// .cdk-global-overlay-wrapper, .cdk-overlay-container{
//   pointer-events: all !important;
// }
.feature-icon {
  color: #26756a !important;
}

.mat-tree-node.mat-tree-title {
  min-height: 30px !important;
  cursor: pointer;
  text-transform: capitalize;
}

.mat-tree-node.tree-child-item {
  padding-left: 45px;
}

.mat-icon-rtl-mirror.category.mat-icon.material-icons {
  line-height: 19px !important;
}

.expension-panel-product.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.expension-panel-product.mat-expanded:hover,
.expension-panel-product.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel-header.mat-expanded {
  background: #f9f9f9;
  height: 48px !important;
}

.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
  background: #f9f9f9 !important;
}

.mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  padding-top: 15px;
}

.listing-tags-blocks {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .mat-expansion-panel-content.mat-expanded {
    padding: 0 !important;
    padding-top: 0 !important;
  }
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.scrollbar-new::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-new::-webkit-scrollbar-thumb {
  background-color: #298377;
  border-radius: 10px;
}

.scrollbar-new::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.p-price {
  .ngx-slider {
    .ngx-slider-pointer {
      width: 15px;
      height: 15px;
      top: -5px;

      &:after {
        width: 9px;
        height: 9px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .ngx-slider-bubble {
      &.ngx-slider-limit {
        transition: opacity 0.3s linear;
        bottom: -30px;
        opacity: 1 !important;
        visibility: visible !important;
        font-size: 14px;
      }

      &[class*="ngx-slider-model"],
      &.ngx-slider-combined {
        font-size: 12px;
      }
    }

    .ngx-slider-pointer {
      background-color: #00a093;
      cursor: grab;

      &.ngx-slider-active {
        cursor: grabbing;

        &:after {
          background-color: #00a093;
        }
      }
    }

    .ngx-slider-selection {
      background-color: #00a093;
    }
  }
}

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.mat-menu-panel {
  min-height: initial !important;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #298377;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c4c4c4;
  }
}

.btn-w-svg {
  .mat-button-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    gap: 10px;
    height: 100%;
  }

  app-svg-icon {
    line-height: 15px;
    display: inline-flex;
    align-items: center;
  }

  svg {
    width: 15px !important;
    height: 15px !important;
    fill: currentColor;
  }
}

.mat-error {
  width: 100%;;
  font-size: 12px;
  color: #ef5350;
}

.more-menu-buttons {
  &.detailed-preview-menu {
    width: 180px;
  }
  .action-buttons {
    flex-direction: column;

    button {
      width: 100%;
      background-color: transparent;
      color: #000;
      text-align: left;
      box-shadow: none;
      margin-right: 0 !important;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  &.create-options {
    .create-icons {
      color: var(--blue);
    }
  }
}

.quick-search-filter {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .mat-expansion-panel-content.mat-expanded {
    padding-top: 0 !important;
  }

  .quick-search-btn.btn {
    border: #f9f9f9 2px solid;
    background-color: white;
    color: black;
    margin-bottom: 0px !important;
    border-radius: 5px;
    padding: 10px;

    &.disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.26);
    }

    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }

    .searchForm {
      .cancle-btn-new {
        width: 15px;
        height: 15px;
        top: 0;
        bottom: 0;
        margin: auto 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #ccc;
    }

    &.active {
      svg {
        fill: #f5b855;
      }
    }

    .quick-search-content {
      display: flex;
      align-items: center;

      span {
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}

.no-records-found {
  padding: 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 0;
  font-family: inherit;

  &.is-sticky {
    position: sticky;

    &.sticky-left {
      left: 0;
    }

    &.sticky-right {
      right: 0;
    }
  }
}

.searchForm {
  .search-btn-style {
    color: #ffffff !important;
    background-color: #00a093 !important;
    border-color: #00a093 !important;
    margin-right: -2px;
    height: 38px;
    border: 1px solid transparent;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    white-space: nowrap;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
  }

  .search-input {
    height: 38px;
    padding: 8px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cbd5dd;
    border-radius: 0;
    z-index: 11;
  }

  .cancle-btn {
    position: absolute;
    right: 60px;
    top: 7px;
    z-index: 13;
    cursor: pointer;
  }

  .cancle-btn-new {
    position: absolute;
    right: 60px;
    top: 7px;
    z-index: 13;
    cursor: pointer;
    width: 15px;
    height: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.mat-dialog-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  color: var(--gray-darker);
  font-weight: 500 !important;
}

.mat-dialog-actions {
  border-top: 1px solid #e5e5e5;
  padding: 12px 10px;
}

.right-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
  width: 100%;
  min-width: 0;
  padding: 0 15px 15px;
  .header {
    .box {
      margin-bottom: 0;
    }

    .box-body {
      padding: 1rem;
    }
  }
}

.tab-with-table {
  flex: 1 1 auto;

  .mat-tab-body-wrapper {
    flex: 1 1 auto;
  }
}

.otp-new-input {
  border-bottom: 3px solid #319688 !important;
  text-align: center !important;
  font-size: 24px !important;
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 0px !important;
}

.otp-new-input::placeholder {
  color: #dadada;
  opacity: 1;
}

.button-with-loader {
  .mat-button-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}

.generate-invoice-modal {
  .mat-dialog-container {
    padding: 0;
  }
}

b,
strong {
  font-weight: 500 !important;
}

.country-code-control {
  width: 90px;
}

.header-sub-menu {
  .mat-menu-content {
    a {
      .menu-icon {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        margin-right: 10px;

        svg {
          fill: currentColor;
          width: 24px;
          height: 24px;
        }
      }

      &.active-route {
        background-color: #f2f2f2;
      }
    }
  }
}

// Product Details Sidebar
#product-details-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: var(--headerHeight, 0px);
  z-index: 1003;
  width: 450px;
  max-width: 100%;
  height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    height: calc(var(--windowInnerHeight, 100vh) - var(--headerHeight, 0px));
  }
}

// Activity Feed Sidebar
#activity-feed-sidebar {
  position: fixed;
  background-color: #ffffff;
  right: 0;
  top: 0;
  z-index: 1003;
  width: 500px;
  max-width: 100%;
  height: 100%;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  transform: translateX(110%);
  overflow: hidden;
  min-height: 250px;
  overflow-y: auto;
  scrollbar-width: 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  // @media (max-width: 767px) {
  //   height: calc(100vh - 130px);
  // }
}

//Trips module add-trip-sidebar
.add-trips-sidebar {
  width: 365px !important;
}

.product-size-sheet-container {
  border-radius: 15px 15px 0 0;
  padding: 0 !important;
  width: 450px;
  max-width: 100%;
  min-width: initial !important;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
  position: relative;
  top: 4px;
  left: 3px;
  background: #fff !important;
}

.mat-slide-toggle-bar {
  position: relative;
  width: 40px !important;
  height: 20px !important;
  flex-shrink: 0;
  border-radius: 10px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #009688 !important;
}

.right-container .mat-tab-label,
.detail-modal .mat-tab-label {
  height: 64px !important;
}

.page-container .mat-ink-bar {
  background: #319688 !important;
}

.mat-ink-bar {
  height: 5px !important;
  border-radius: 5px;
}

.product-tab-group {
  .mat-tab-label {
    text-transform: uppercase;
    font-family: roboto, bold;
  }

  .mat-tab-label-active {
    background: #00968826;
  }
}

.header-menu-list {
  .mat-button-wrapper {
    font-size: 14px;
  }
}

.page-layout {

  .main-sidebar.products,
  .main-sidebar.orders {
    .brand-select-box {
      .mat-form-field-infix {
        padding: 10px 20px !important;
        border: none !important;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }

      .brand-logo {
        width: 40px;
        min-width: 40px;
        height: 40px;
        display: inline-block;
        margin-right: 8px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: #d5d5d5;
        text-transform: uppercase;
        border-radius: 100%;
        box-shadow: 0 0 5px 0px rgba($color: #000000, $alpha: 0.5);
      }

      .mat-option {
        height: 4rem !important;
      }

      &.mat-form-field-appearance-legacy {

        .mat-form-field-underline,
        .mat-form-field-ripple {
          height: 0 !important;
        }
      }
    }
  }
}

.enable-toggle.mat-slide-toggle {
  position: relative;

  &.mat-checked {
    .mat-slide-toggle-content::after {
      content: "ON";
      font-size: 12px;
      color: black;
      position: absolute;
    }
  }

  &:not(.mat-checked) {
    .mat-slide-toggle-content::after {
      content: "OFF";
      font-size: 12px;
      color: white;
      position: absolute;
    }
  }

  .mat-slide-toggle-bar {
    width: 120px;
  }
}

.navs {
  .nav-item {
    height: calc(var(--windowInnerHeight, 100vh) - (var(--headerHeight, 0px) + 114px));
    overflow-y: auto;
  }
}

.list-product-image {
  border-radius: 5px;
}

app-no-records-available {
  display: flex;
  flex-direction: column;
  flex: 1;
}

ngx-skeleton-loader {
  line-height: 0;
  display: block;
}

.internal-load {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  background-color: rgba($color: white, $alpha: 0.5);
  z-index: 2;
}

.mat-expansion-panel-header[aria-disabled="true"] .filter-action-btn {
  color: rgba($color: #000000, $alpha: 0.26);
}

.content-card.card {
  border: solid 1px #e4e4e4;
  border-radius: 12px;
  flex: 1;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 96px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 14px;
  text-transform: capitalize;
  background: #dedede;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #8a8888;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked~.switch-label {
  background: #319688;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: #bbbbbbf0;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: left 0.5s ease-out;
  -moz-transition: left 0.5s ease-out;
  -o-transition: left 0.5s ease-out;
  transition: left 0.5s ease-out;
}

// .switch-handle:before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin: -6px 0 0 -6px;
//   width: 12px;
//   height: 12px;
//   background: #f9f9f9;
//   border-radius: 6px;
//   box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
//   background-image: -webkit-linear-gradient(top, #eeeeee, white);
//   background-image: -moz-linear-gradient(top, #eeeeee, white);
//   background-image: -o-linear-gradient(top, #eeeeee, white);
//   background-image: linear-gradient(to bottom, #eeeeee, white);
// }
.switch-input:checked~.switch-handle {
  left: 67px !important;
  background: #fff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green>.switch-input:checked~.switch-label {
  background: #4fb845;
}

.lightbox {
  width: 100%;
  height: 100%;
  display: flex !important;
  top: 0 !important;
  position: fixed !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .lb-container {
    .lb-nav {
      position: static;

      .lb-prev,
      .lb-next {
        position: fixed;
        height: 30px;
        background-size: 100%;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-position: center;
        width: 30px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

      .lb-prev {
        left: 0;
      }

      .lb-next {
        right: 0;
      }
    }
  }

  .lb-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;

    .lb-caption {
      font-size: 16px;
    }
  }

  .lb-outerContainer {
    overflow: auto;
    max-width: 100%;
    @extend .scrollable;
  }

  .lb-dataContainer {
    margin-top: -40px;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    padding-top: 0;
    border-radius: 0;

    .lb-details {
      float: none;
    }

    .lb-controlContainer {
      float: none;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .lb-downloadContainer {
        .lb-download {
          height: 20px;
          cursor: pointer;
        }
      }

      .lb-closeContainer {
        float: none;

        .lb-close {
          width: 20px;
          height: 20px;
          background-size: 100%;
        }
      }

      .lb-zoomContainer {

        .lb-zoomOut,
        .lb-zoomIn {
          width: 25px;
          height: 25px;
          background-size: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.input-group.search-bar-fixed-size {
  width: 350px !important;
}

.table-sticky thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 9999 !important;
  display: block !important;
}

/* nav header */
.navs .nav-subheader {
  display: flex !important;
  align-items: center !important;
  height: 54px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  text-transform: uppercase;
  padding: 15px 24px;
  background: #35a093;
  color: #fff;
  font-family: "Roboto", bold;
  margin-top: 0;
  text-align: left;
  border-bottom: none !important;

  .clear-button-pro {
    color: #fff;
    margin-left: auto;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
  }
}

.product-size-backdrop+.cdk-global-overlay-wrapper {
  justify-content: flex-end !important;
}

.product-tab-group,
.create-org-tab-group,
.retailers-tab-group,
.order-tab-group,
.performance-tab-group,
.report-tab-group,
.challan-tab-group,
.product-create-group,
.orderDetailsTabGroup,
.product-details-tab {
  .mat-tab-label {
    text-transform: uppercase !important;
    font-family: roboto, bold !important;
    border-right: 1px solid #e4e4e4;
  }

  .mat-tab-label-active {
    background: #00968826 !important;
  }

  .mat-tab-label-content {
    svg {
      width: 16px;
    }
  }
}

.selection-custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;

  &.checked {
    background-color: var(--blue);
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: calc(100% - 4px);

    svg {
      fill: currentColor;
    }

    &.intermediate {
      height: 2px;
      background-color: #fff;
    }
  }
}

.ox-tooltip {
  position: fixed;
  left: 0;
  z-index: 9999;
  padding: 12px;

  // top: calc(100% - 12px);
  .inner-tooltip {
    background-color: white;
    box-shadow: 0px 6px 15px #00000014;
  }

  &:before {
    content: "";
    position: absolute;
    width: 17px;
    height: 12px;
    background-color: white;
    border-top: 0;
    z-index: -1;
    left: 60px;
    box-shadow: 0px 6px 15px #00000014;
    // top: 0px;
    // clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.show-bottom {
    &:before {
      top: 0px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }

  &.show-top {
    &:before {
      bottom: 0px;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
}

.tab-nav-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 0;
  }

  .mat-tab-link {
    text-decoration: none;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-right: 1px solid #e4e4e4;

    svg {
      width: 14px;
    }

    &:hover {
      text-decoration: none;
    }

    &.mat-tab-label-active {
      background-color: #00968826 !important;
    }
  }
}

.not-applicable-text {
  font-size: 12px !important;
  color: #bdbdbd !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding: 0 20px !important;
  padding-right: 0;
}

.brand-name {
  color: #3ba094;
  font-size: 16px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: "Roboto", medium;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.mat-cell span.ui-highlight {
  color: #fff;
  font-size: 14px;
  padding: 5px;
  width: 96px;
  display: block;
  margin: auto;
  border-radius: 20px;
}

.ui-highlight.colored.textcolor-red {
  background-color: #ec3737;
}

.ui-highlight.colored.textcolor-dark-pending {
  background-color: #f7b900;
}

.ui-highlight.colored.textcolor-light {
  background-color: #8bc34a;
}

.ui-highlight.colored.textcolor-dark {
  background-color: #009688;
}

thead tr:first-child {
  border-bottom: none !important;
}

.brand-select-box {
  .brand-name {
    color: #3ba094;
    font-size: 16px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-family: "Roboto", medium;
  }

  .brand-logo {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #d5d5d5;
    text-transform: uppercase;
    border-radius: 100%;
  }

  .mat-option {
    height: 4rem !important;
  }

  &.mat-form-field-appearance-legacy {

    .mat-form-field-underline,
    .mat-form-field-ripple {
      height: 0 !important;
    }
  }
}

.sort-by-label {
  color: #aaaaaa;
}

// .export-btn-section {
//   position: absolute;
//   bottom: 10px;
//   z-index: 9;
//   left: 10px;
// }

.alert-danger {
  background-color: #fbe9e6;
  border-color: #f7d0d1;
  color: #a1513c;
}

.btn-outline-secondary {
  color: #949393;
  background: #fff;
  border-color: #949393;
  padding: 0.5rem 1.75rem;
}

.btn-outline-secondary:hover {
  background-color: #949393;
  border-color: #949393;
  color: #fff;
}

#main-section {
  padding-top: var(--headerHeight, 0px);
}

.fixed-paginator {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: calc(100% - 30px);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 15px;
  background-color: #fff;

  mat-paginator {
    border: 1px solid #e4e4e4 !important;
    border-radius: 0 0 12px 12px !important;
  }
}

@media (max-width: 1199px) {
  .product-detail-modal {
    width: 100% !important;
    max-width: 100vw !important;
  }
}

/* Add Product Image Model */
@media only screen and (max-device-width: 480px) {
  .p-detail-dialog {
    width: 100% !important;
    max-width: 900px !important;

    .mat-dialog-title {
      padding: 10px;
      margin: 0 0 10px;
    }

    .add-image-dialog {
      .mat-dialog-content {
        max-height: calc(var(--windowInnerHeight, 100vh) - 122px) !important;
        padding: 5px !important;

        .heading-section {
          float: left !important;
          flex: 1 0 100%;
          max-width: 50%;
        }

        .uploaded-image-section {
          .image {
            height: 150px !important;
            padding: 10px !important;
          }
        }
      }
    }

    .mat-dialog-actions {
      .card-action {
        padding: 10px !important;
      }
    }
  }
}

/* Create Product */
@media only screen and (max-device-width: 480px) {
  .imageblock.add-image-plus {
    height: 170px !important;
  }
}

.mat-tooltip {
  color: #fff;
  border-radius: 4px !important;
  background: #3a3939;
  margin: 10px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border: solid 1px #5a5959;
}

.attribute-icon {
  width: 18px;
  height: 18px;
  margin: 4px;
  margin-right: 6px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.mat-raised-button.mat-accent[disabled],
.mat-raised-button[disabled][disabled] {
  cursor: not-allowed;
}

.select-retailer-modal {
  height: 90vh;

  @media (max-width: 767px) {
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
  }
}

perfect-scrollbar {
  >.ps {
    min-height: inherit;
  }
}

.sort-action-btn {
  min-width: 45px !important;

  span {
    width: 15px;
    display: inline-block;
  }
}

.page-auth {
  .phone-form-group {
    .phone-form-field {
      .mat-form-field-infix {
        width: auto;
      }
    }
  }
}

.add-org-logo-modal {
  .mat-dialog-content {
    padding: 0 !important;

    .add-org-logo-content {
      max-height: calc(var(--windowInnerHeight, 100vh) - 200px);

      @media (max-width: 767px) {
        max-height: calc(var(--windowInnerHeight, 100vh) - 135px);
      }
    }
  }

  @media (max-width: 375px) {
    .croppie-container {
      .cr-viewport {
        width: 240px !important;
        height: 240px !important;
      }
    }
  }
}

.ox-custom-radio {
  &.status-radio {
    ul {
      gap: 5px;
      margin: 0;
    }
  }

  label {
    margin: 0;

    input[type="radio"] {
      display: none;
    }

    .radio-box {
      display: flex;
      padding: 3px 5px;
      border: 1px solid var(--blue);
      font-size: 11px;
      line-height: 1.2;
      color: var(--blue);
      cursor: pointer;
      gap: 5px;
      align-items: center;
      justify-content: center;

      .icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        line-height: 10px;

        svg {
          fill: currentColor;
        }
      }
    }

    input[type="radio"]:checked {
      &+.radio-box {
        background-color: var(--blue);
        color: #fff;
      }
    }
  }
}

.table-actions-list {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;

  li {
    a {
      width: 20px;
      height: 20px;
      display: block;
      line-height: 20px;
      margin: 0;

      app-svg-icon {
        line-height: inherit;
      }

      &.delete-icon {
        color: #df2121;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
        fill: currentColor;
      }
    }
  }
}

//---------------------Retailer and distributor details modal-----------------------//
.enterprise-detail-modal {
  padding: 5px;
  position: relative;
  overflow-x: hidden;

  p {
    margin: 0;
    line-height: 20px;
  }

  h5 {
    margin: 0;
  }

  .close-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 5px;
    background-color: #009688;
    border: 0;
    color: #fff;
    border-radius: 50%;
    // position: absolute;

    margin: auto 0;
    z-index: 2;
    cursor: pointer;

    svg {
      fill: #fff;
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  .modal-heading {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding: 0px 0px 20px;
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--gray-darker);
    font-weight: 500 !important;

    .menu-icon {
      margin-right: 6px;
      width: 21px;
      height: 19px;
      color: var(--gray-darker);
      app-svg-icon {
        fill: var(--gray-darker);
        display: flex;
        height: 19px;
        width: 21px;
      }
    }
  }

  .enterprise-details {
    padding: 17px 20px 15px 18px;
    margin: 0;
    max-height: calc(96vh - 64px);
    overflow-y: auto;
    @extend .scrollable;

    .plane {
      display: inline-block;

      &.fly-plane {
        color: #319688;
        font-size: 18px;
        animation-name: fly;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.735, 0.43, 0.275, 0.76);
      }
    }

    .enterprise-logo {
      width: 80px;
      height: 80px;

      .ng-star-inserted {
        width: 100%;
        height: 100%;
        font-size: 24px;
      }

      img {
        min-width: 100%;
        min-height: 100%;
      }
    }

    .enterprise-name {
      margin: 0 !important;
      font-weight: 600 !important;
      color: #009688;
      font-size: 16px;
    }

    .enterprise-active {
      background-color: rgba($color: #009688, $alpha: 0.19);
      margin: 0 0 0px 7px;
      border-radius: 5px;
      padding: 3px 4px;
      font-size: 10px;
      font-weight: 500;
      color: #000000;
      text-transform: uppercase;

      &.inactive-status {
        filter: grayscale(1);
      }
    }

    .distributor-marked {
      font-size: 10px;
      background-color: #46495B;
      color: white;
      padding: 3px 5px;
      border-radius: 5px;
      line-height: 1;
      font-weight: 500;
      box-shadow: 0px 3px 6px #4446584D;
      margin-left: 7px;
    }

    .enterprise-contact {
      margin: 0;
      color: #545454;
      font-size: 14px;
      font-weight: 400 !important;
      line-height: 20px;
    }
    .transproter-GST {
      margin: 0;
      color: #787878;
      font-size: 14px;
      span {
        color: #525252;
        font-weight: 500 !important;
      }
    }

    .enterprise-details-item {
      .details-item-title {
        font-size: 12px;
        line-height: 1.3;
        color: #787878;
        margin: 0 0 3px;

        span {
          text-transform: none;

          svg {
            height: 10px;
            width: 10px;
          }

          .help-info {
            height: 11px !important;
            width: 11px !important;
            margin-left: 3px;
            fill: #787878;
          }
        }
      }
      .transport-detail-section {
        .trasport-detail-label{
          font-size: 12px;
          color: #787878;
          margin: 10px 0 5px;
          text-transform: uppercase;
        }
        p {
          line-height: 1.5;
        }
      }
      .status {
        width: 120px;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        border-radius: 20px;
        color: white;

        &.status-accepted {
          background-color: #00bcd4;
        }

        &.status-rejected {
          background-color: #ff0000;
        }

        &.status-pending {
          background-color: #ffc107;
        }
      }
    }

    // .invite-section {
    //   position: absolute;
    //   top: 100px;
    //   right: 30px;
    // }

    .address-tag {
      font-size: 12px;
      color: #787878;
      line-height: normal;
    }

    .enterprise-address {
      color: #2c2c2c;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .more-information-section {
      font-size: 13px;
      color: #787878;
      text-transform: uppercase;
      line-height: 20px;

      span {
        color: #525252;
        font-weight: 500;
        line-height: 20px;
      }
    }

    // .more-info-tag {
    //   font-size: 12px;
    //   color: #787878;
    //   line-height: normal;
    //   margin: 16px 0 6px;
    // }
    .owner-detail-tag {
      color: #b8b8b8;
      margin-bottom: 5px;
      line-height: normal;
      font-size: 12px;

      span {
        text-transform: none;

        svg {
          height: 10px;
          width: 10px;
        }

        .help-info {
          height: 11px !important;
          width: 11px !important;
          margin-left: 3px;
          fill: #787878;
        }
      }
    }

    .owner-name {
      color: #525252;

      font-size: 15px;
      font-weight: 600 !important;
      margin-bottom: 0px;
    }

    .owner-email {
      line-height: normal;
      color: #787878;
    }

    .enterprise-edit {
      background-color: #009688;
      color: #ffffff;
      border-radius: 3px;
      line-height: normal;
      margin-left: 5px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      .mat-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 21px !important;
        width: 21px !important;
        font-size: 16px;
      }
    }

    .ui-highlight {
      color: #fff;
      font-size: 14px;
      padding: 5px;
      width: 96px;
      display: block;
      text-align: center;
      border-radius: 20px;
      // background: #f7b902;
    }

    .invite-btn {
      background: #66bb6a;
      color: #fff;

      &.disabled {
        filter: grayscale(1);
      }
    }

    .btn-retail-new {
      width: 120px;
      font-size: 14px;
      border-radius: 20px;
      text-align: center;
      color: #ffffff;

      .button-text {
        padding-right: 3px;
      }
    }

    .btn-warning.btn-retail-new {
      background: #f7b902;
    }

    @media (max-width: 375px) {
      .enterprise-logo {
        min-width: 65px;
        min-height: 65px;
        height: 65px;
        width: 65px;
      }
    }

    @media (max-width: 425px) {
      .btn-retail-new {
        width: auto;

        .button-text {
          display: none;
        }
      }

      .ui-highlight {
        font-size: 11px;
        padding: 3px 5px;
        width: 80px;
      }

      .resend-invite-btn {
        font-size: 11px;
      }
    }
  }
}

.desktop-banner-dialog {
  @media (max-width: 479px) {
    .croppie-container {
      .cr-viewport {
        width: 350px !important;
        height: 214px !important;
      }
    }
  }

  @media (max-width: 425px) {
    .croppie-container {
      .cr-viewport {
        width: 320px !important;
        height: 180px !important;
      }
    }
  }

  @media (max-width: 375px) {
    .croppie-container {
      .cr-viewport {
        width: 265px !important;
        height: 149px !important;
      }
    }

    .page-auth {
      .otp-form-container {
        .otp-input {
          width: 20px !important;
          font-size: 22px !important;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .unsaved-modal-box .mat-dialog-container {
    padding: 14px;
  }
}

.as-split-gutter {
  background: transparent !important;
}

.billing-history-plan-panel {
  @media only screen and (max-width: 768px) {
    max-width: 100vw !important;
    // width: 70% !important;
    width: 100% !important;
  }
}

.mat-slide-toggle {
  margin-bottom: 0;

  .mat-slide-toggle-bar {
    height: 21px !important;
  }

  .mat-slide-toggle-thumb {
    width: 15px !important;
    height: 15px !important;
    top: 6px;
    left: 3px;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(19px, 0, 0) !important;
    }
  }
}

.createMI-menu-dropdown {
  min-width: 100px;

  .mat-menu-content a {
    display: block;
    padding: 7px 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.5;

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.custom-multi-select {
  .mat-option-multiple {
    .mat-checkbox-layout {
      margin: 0;
    }
    .mat-pseudo-checkbox-checked {
      &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.attr-search-box {
  position: relative;

  .clear-search-box {
    position: absolute;
    width: 12px;
    height: 12px;
    display: block;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 7px;
    margin: auto;
    line-height: 1;
    cursor: pointer;
  }

  input {
    width: 100%;
    padding: 6px 9px;
    border: 1px solid rgba(120, 120, 120, 0.52);
    border-radius: 2px;
    color: #707070;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
  }

  .attr-search-menu {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 3px 3px;
    top: 100%;
    max-height: 205px;
    overflow-y: auto;

    span {
      display: block;
      padding: 10px 9px;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.custom-table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 7px;
}

.paginator-section {
  .custom-table-scrollbar {
    position: absolute;
    top: -7px;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.more-inventory {
	width: 215px;
	a {
		color: #2E2E2E;
		text-transform: uppercase;
		padding: 10px;
		font-size: 12px;
		display: block;
		text-decoration: none;
    height: auto;
    line-height: inherit;
		.material-icons {
			width: 20px;
			height: 20px;
			font-size: 20px;
		}
	}
	.mat-expansion-panel {
		.mat-expansion-panel-header {
			padding: 0;
			height: auto !important;
			background-color: transparent;
			.mat-expansion-panel-header-title {
				font-size: 12px;
				text-transform: uppercase;
				color: #2E2E2E;
			}
		}
		.mat-expansion-panel-content {
			padding: 0;
		}
		.mat-expansion-panel-body {
			padding: 0;
			background-color: #F2F2F2;
			a {
				padding: 4px 10px;
			}
		}
	}
}

.create-dispatch-menu {
	min-width: 140px !important;
	border-radius: 4px 0 4px 4px !important;
	a {
		display: block;
		padding: 5px 10px;
		color: #222222;
		text-transform: uppercase;
    text-decoration: none;
		&:not(:last-child) {
			border-bottom: 1px solid #dddddd;
		}
	}
}

.dispatch-filters-dropdown {
	border-radius: 0 0 4px 4px !important;
	max-height: 185px !important;
	overflow-y: auto;
  min-width: var(--selectDropdownWidth) !important;
  max-width: var(--selectDropdownWidth) !important;

  .mat-menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .dispatch-menu-content {
    flex: 1;
    overflow-y: auto;
  }

	ul {
		margin: 0;
		li {
			font-size: 12px;
			line-height: 1.2;
      padding: 8px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
			&:not(:last-child) {
				border-bottom: 1px solid #dddddd;
			}

      &.selected {
        background-color: rgba(0, 150, 136, 0.20);
        font-weight: bold;
      }
		}
	}

  .search-input {
    position: sticky;
    top: 0;
    z-index: 22;
    border-bottom: 2px solid #dddddd;
    input {
      border: 0;
      padding: 5px 8px;
      width: 100%;
      font-size: 12px;
      padding-right: 32px;
    }
    .search-action {
      position: absolute;
      z-index: 23;
      width: auto;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
      padding: 0 10px;
      .icon {
        min-width: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        cursor: pointer;
        app-svg-icon {
          display: block;
        }
        svg {
          fill: currentColor;
        }
      }
    }
  }
}

.common-mat-dialog {
  .mat-dialog-title {
    position: relative;
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--gray-darker);
    font-weight: 500 !important;
    .close-button {
      width: 20px;
      height: 20px;
      line-height: 20px;
      padding: 5px;
      background-color: var(--blue);
      border: 0;
      color: #fff;
      border-radius: 50%;
      // position: absolute;

      margin: auto 0;
      z-index: 2;
      cursor: pointer;

      svg {
        fill: #fff;
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }
}

.product-copy-content-order {
  background: #319688;
  width: calc(100% - 70px);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - (70px + 45px));
    cursor: pointer;
  }
  span.clear-copy {
    float: right;
    border-left: 1px white solid;
    padding-left: 10px;
    // width: 12%;
    margin-left: auto;
    flex: 1 1 45px;
    max-width: 45px;
    cursor: pointer;
  }
  span.copied {
    flex: 1 1 70px;
    max-width: 70px;
    cursor: pointer;
    i.copied {
      color: #f7c326;
      text-transform: uppercase;
    }
  }
}


.all-org-menu {
  min-width: 170px !important;
  border-radius: 0 0 4px 4px !important;
	ul {
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 385px;
    overflow: hidden;
    overflow-y: auto;
    a {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      color: black;
      gap: 8px;
      height: 100%;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        background-color: #e2e2e2;
        color: black;
      }
      figure {
        min-width: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        span {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          line-height: 26px;
          text-transform: capitalize;
          height: 100%;
          background-color: #808080;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .selected-org-detail {
        flex: 1;
        overflow: hidden;
        .org-name {
          margin: 0;
          font-size: 14px;
          line-height: 1.2;
          text-transform: uppercase;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .org-type {
          margin: 0;
          font-size: 10px;
          color: #858585;
          line-height: 1.2;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &.selected {
        background-color: var(--blue);
        color: white;
        .selected-org-detail {
          .org-type {
            color: currentColor;
          }
        }
      }
    }
    .create-org-button {
      background-color: white;
      box-shadow: 0 5px 10px rgba(0,0,0,0.5);
      position: sticky;
      bottom: 0;
      a {
        text-transform: uppercase;
        font-weight: 500;
        position: sticky;
        bottom: 0;
        .icon {
          min-width: 30px;
          width: 30px;
          height: 30px;
          display: inline-block;
          padding: 6px;
          app-svg-icon {
            display: block;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1280px) {
  .right-container.with-filter {
    margin-left: 267px;
  }
}

.edit-variation-modal {
  .edit-variation-form {
    .product-name {
      font-size: 20px;
      font-weight: 500 !important;
      margin: 0;
    }
    .product-sku {
      font-size: 13px;
      margin: 0;
      line-height: 1.5;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 5px 0 10px 0;
      li {
        background-color: #eeeeee;
        padding: 3px 5px;
        line-height: 1;
        font-size: 12px;
        border-radius: 3px;
      }
    }
  }

}

.overflow-hidden {
  overflow: hidden !important;
}


.profile-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  display: block !important;
  position: relative !important;
  border-radius: 2px !important;
  padding: 0px !important;
  width: 280px;
}

.profile-card-header {
  display: flex;
  align-items: center;
  background-size: cover;
  padding: 1rem 1rem;
  background-color: #319688;
  overflow-wrap: anywhere;
  .profile-avatar {
    float: left;
    background: white;
    border-radius: 100%;
    border: solid 2px #dad7d7;
    img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
    }
  }
  .proifle-title {
    float: left;
    flex-grow: 1;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 500;
    padding-left: 1rem;
  }

  .user-phone {
    font-size: 13px;
    color: whitesmoke;
  }
}

.profile-content {
  .pro-menu {
    align-items: center;
    border-bottom: 1px solid #f7f8fa;
    font-size: 16px !important;
    transition: background-color 0.3s ease;
  }
  .material-icons {
    font-size: 16px;
    color: #319688;
    display: block;
    float: left;
    margin-top: 15px;
    margin-right: 10px;
  }

  .profile-list {
    list-style: none;
    padding-left: 0;
  }
}

.header-profile-dropdown .mat-menu-item {
  font-family: inherit !important;
  line-height: none !important;
  height: auto !important;
  border-bottom: solid 1px #eaeaea;
  .icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    color: #319688;
    line-height: 1;
    margin-right: 10px;
    svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }
}

img {
  user-drag: none !important;
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.ox-tab-nav {
  // position: sticky;
  // top: var(--headerHeight, 0px);
  // z-index: 9;
  background-color: #efefef;
  a {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    width: 200px !important;
  }
  a:hover {
    text-decoration: none;
  }
}

.pac-container {
  z-index: 9999;
}

#barcode-scanning-section {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.retailer-group-list {
  .selected-retailer {
    background-color: #00968826 !important
  }
}


.challan-update-party {
  h3,
  p {
    text-align: left !important;
  }

  .actions-button {
    justify-content: flex-end !important;
  }
}
