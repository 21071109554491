.app-page-container {
  width: 100%;
  height: 100%;

  .app-content-wrapper{
    min-height: 100%;
    position: relative;
    background-color: $l_white; // important! otherwise trasparent background-color won't cover the sidebar, when scroll down
    .app-content {
      z-index: 10;
      padding-bottom: $sidebar_footer_height;
      min-height: 100%;
      transition: all 0.3s ease;
      &.full-width{
        width: 100%;
      }
    }
  }
}

// for fixed-header
.fixed-header {
  .app-page-container {
    .app-content-wrapper {
      .app-content {
        padding-top: $l_header_height;
      }
    }
  }
}

// for md-sidenav-right
.app-page-container {
  &.scroll-disabled {
    overflow: hidden;
  }
}

// mobile
@media only screen and (max-width: $l_screen_sm_max) {
  .app-page-container {
    position: relative;
    // z-index: $l_zindex_page_container_mobile;
    padding-left: 0;
    transition: transform .25s ease;
    background-color: $l_white; // important! otherwise trasparent background-color won't cover the sidebar
    .app-content-wrapper {
      .app-content  {
        overflow-x: hidden;
        // height: auto; // minimizes address bar on scroll
      }
    }
  }

  // off-canvas sidebar
  .sidebar-mobile-open {
    .app-page-container{
      overflow: hidden;
      position: fixed;
      transform: translateX($l_sidebar_width);
    }
  }
}
// desktop
@media only screen and (min-width: $l_screen_md_min) {
  .app-page-container {
    .app-content-wrapper {
      .app-content {
        padding-left: $l_sidebar_width;
      }
      .app-footer{
        left: $l_sidebar_width;
      }
    }
  }

  .nav-collapsed {
    .app-page-container {
      .app-content-wrapper {
        .app-content {
          padding-left: $l_sidebar_width_collapsed;
        }
        .app-footer {
          left: $l_sidebar_width_collapsed;
        }
      }
    }
  }
}
