.filter-navs{
  background-color: #ffffff !important;
  flex: 1 1 auto;
  .nav-subheader{
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 0px;
    font-weight: 600;
    padding-left: 24px;
    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    border-bottom: 1px solid #eee;
  }

  .p-category{
    border-bottom: 1px solid #eeeeee;
    .p-category-title{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: var(--gray-darker);
      height: 40px;
      line-height: 40px;
      padding-left: 24px;
    }
    .p-category-options{
      font-size: 13px;
      color: var(--gray-darker);
      line-height: 24px;
      padding-left: 30px;
      cursor: pointer;
      padding-bottom: 10px;
      span{
        &.selected{
          color: var(--blue);
        }
      }
    }
  }

  .mat-expansion-panel{
    box-shadow: none !important;
    border-bottom: 1px solid #eee;
    background: #fff;
    color: rgba(0,0,0,.87);
    margin: 0;
  }
  .mat-expansion-panel-header-title{
    margin-right: 0;
  }
  .mat-expansion-panel-body{
    padding: 0 24px 10px;
    border-bottom: 1px solid #eee;
  }

  .mat-checkbox-layout{
    width: 100%;
    align-items: center;
    .mat-checkbox-label{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  .mat-checkbox-layout{
    display: -webkit-box !important;
    .mat-checkbox-label{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      max-width: calc(100% - 28px);
    }
  }
}


// .page-sidebar{
//   position: sticky !important;
//   top: 0;
//   max-height: calc(100vh - var(--headerHeight));
// }
// .filter-navs{
//   max-height: calc(100% - 200px);
//   display: flex;
//   flex-direction: column;
// }
// .order-filter-listing{
//   display: flex;
//   flex-direction: column;
//   max-height: 100%;
//   overflow: hidden;
// }
// .nav-item{
//   flex: 1 1 auto;
//   height: calc(100% - 40px);
// }
